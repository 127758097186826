import React, { useState } from 'react';
import { Menu, MenuList, MenuItem, Icon } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { useApp } from '../../AppContext';
import { useDigest } from '../features/digests/context';
import { useAccount } from '../features/account/context';
import Button from '../UI/atoms/Button';
import StyledContainer from '../UI/atoms/StyledContainer';
import Text from '../UI/atoms/Text';
import Card from '../UI/molecules/Card';

const StyledStepsWrapper = styled('div')(({theme}) => ({
    border: 'none'
}))

const StepsSlist = ({currentStep, handleStep, steps}) => {
    return (
        <MenuList sx={{padding: 0, backgroundColor: '#ECEBEB'}}>
            {steps.filter(step => step.icon !== 'none').map((step, index) => (
                <MenuItem
                    key={index}
                    className={currentStep === step.path && 'active'}
                    onClick={() => handleStep(step.path, index)}
                    sx={{
                        padding: '15px',
                        borderBottom: '1px solid #D9D9D9',
                        justifyContent: 'space-between',
                        '&:hover, &.active': {
                            backgroundColor: '#D9D9D9',
                            cursor: 'pointer'
                        },
                        '&:last-of-type': {
                            borderBottom: 'none'
                        }
                    }}>
                    <Text type='step' step={{text: step.text, number: index + 1}} />
                    <Icon baseClassName='fa-light' className={step.icon} sx={{fontSize: '1rem', width: '20px'}} />
                </MenuItem>
            ))}
        </MenuList>
    )
}

export default function Steps() {
    const initialSteps = [
        { icon: 'fa-envelopes-bulk', text: 'Digests', path: '/digests' },
        { icon: 'fa-paper-plane', text: 'Newsletters', path: '/newsletters' },
        { icon: 'fa-table', text: 'Data', path: '/data' },
        { icon: 'none', text: 'Finish', path: '/finish' },
    ]

    const membership = 'news';
    const { handlePostDigestWizard, handlePostNewsletterWizard } = useDigest();
    const location = useLocation();
    const navigate = useNavigate();
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ steps ] = useState(membership === 'free' ? initialSteps.filter(step => step.text === 'News') : initialSteps);
    const [ currentStep, setCurrentStep ] = useState(location.pathname);
    const [ currentStepIndex, setCurrentStepIndex ] = useState(steps.findIndex(step => step.path === location.pathname));
    const open = Boolean(anchorEl);

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleChangeStep = (step, index) => {
        setCurrentStep(step);
        setCurrentStepIndex(index);
        navigate(step);
    }

    const handlePrevStep = () => {
        const newIndex = currentStepIndex - 1;
        const newPath = steps[newIndex].path;
        setCurrentStepIndex(newIndex);
        setCurrentStep(newPath);
        navigate(newPath);
    }
    const nextStepFunc = () => {
        const newIndex = currentStepIndex + 1;
        const newPath = steps[newIndex].path;
        setCurrentStepIndex(newIndex);
        setCurrentStep(newPath);
        navigate(newPath);
    }
    const handleNextStep = () => {
        if (currentStep === '/digests' || currentStep === '/newsletters') {
            if (currentStep === '/digests') {
                handlePostDigestWizard(nextStepFunc);
            } else {
                handlePostNewsletterWizard(nextStepFunc);
            }
        } else {
            nextStepFunc();
        }
    }

  return (
    <StyledContainer sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'column', md: 'row'}, flex: 1}}>
        <StyledStepsWrapper sx={{display: {xs: 'block', sm: 'block', md: 'none'}, mb: '20px'}}>
            <Button
                onClick={handleClick}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className='step-btn'
            >
                <Text type='step' step={{text: currentStep, number: (steps.findIndex(step => step.path === currentStep) + 1)}} />
                <Icon baseClassName='fa-solid' className='fa-caret-down' sx={{fontSize: '1rem', transform: open && 'rotate(180deg)'}} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                      left: '20px',
                      right: '38px'
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                      padding: 0
                    }
                }}
            >
                <StepsSlist steps={steps} currentStep={currentStep} handleStep={handleChangeStep} />
            </Menu>
            
        </StyledStepsWrapper>
        <StyledStepsWrapper sx={{display: {xs: 'none', sm: 'none', md: 'block'}, width: '100%', maxWidth: '360px'}}>
            <Card type='description paddless' sx={{margin: 0, marginRight: '20px'}}>
                <StepsSlist steps={steps} currentStep={currentStep} handleStep={handleChangeStep} />
            </Card>
        </StyledStepsWrapper>
        <StyledContainer sx={{flexGrow: 1, maxWidth: '1000px', display: 'flex', flexDirection: 'column'}}>
            <div className='step-content'>
                <Outlet />
            </div>
            <div style={{display: 'flex', justifyContent: currentStepIndex === 0 ? 'flex-end' : 'space-between'}}>
                {currentStepIndex !== 0 && 
                    <Button className='step-nav' icon={{start: true, base: 'fa-solid', type: 'fa-angle-left'}} onClick={() => handlePrevStep()}>
                        <Text>{steps[currentStepIndex - 1].text}</Text>
                    </Button>
                }
                <Button className='step-nav' icon={{end: true, base: 'fa-solid', type: 'fa-angle-right'}} onClick={() => handleNextStep()}>
                    <Text>{steps[currentStepIndex + 1].text}</Text>
                </Button>
            </div>
        </StyledContainer>
    </StyledContainer>
  )
}