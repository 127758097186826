import React, { useState } from 'react';
import { Grid, ListItem as MuiListItem, Menu, MenuItem } from '@mui/material'
import Button from './Button';
import Text from './Text';
import StyledContainer from './StyledContainer';

export default function ListItem({data, handleRemove, isAdmin}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <MuiListItem
                className='group-member'
                disablePadding
                secondaryAction={ !isAdmin &&
                    <Button onClick={(e) => handleClick(e)} type='icon' baseClassName='fa-light' className='fa-ellipsis' placement={anchorEl ? 'active nav' : 'nav'} color='#413F3F' />
                }
            >
                {isAdmin && (
                    <Text className='admin-chip'>Admin</Text>
                )}
                <StyledContainer sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row', md: 'column', lg: 'row'}}}>
                    <StyledContainer className='wrapper'>
                        <Text className='user-name'>{data.firstName + " " + data.lastName}</Text>
                        <Text className='subtext'>{data.email}</Text>
                    </StyledContainer>
                    {data.lastLogin && 
                        <StyledContainer className='wrapper'>
                            <Text className='label'>Last Login</Text>
                            <Text className='subtext'>{data.lastLogin}</Text>
                        </StyledContainer>
                    }
                </StyledContainer>
            </MuiListItem>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                className='user-menu'
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
            >
                <MenuItem onClick={() => {handleRemove(data.userId); handleClose()}}>Unassign</MenuItem>
            </Menu>
        </React.Fragment>
    )
}
