import React from 'react'
import { styled } from '@mui/material'

const StyledTrialMessage = styled('div')(() => ({
    backgroundColor: '#A5D0F5',
    border: '1px solid #2D70DA',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '900px',
    textAlign: 'center',
    marginBottom: '20px'
}))

export default function InfoMessage({children}) {
  return (
    <StyledTrialMessage>{children}</StyledTrialMessage>
  )
}

