import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormGroup, Icon } from '@mui/material';
import Card from './Card';
import Text from '../atoms/Text';
import Checkbox from '../atoms/Checkbox';
import { useApp } from '../../../AppContext';
import Divider from '../atoms/Divider';
import Button from '../atoms/Button';
import StyledContainer from '../atoms/StyledContainer';

const freeDigests = [ "Northern Miner - weekly", "MNG-Daily Digest", "Marco Polo"];

export default function CollapseBoxes({saved, subscriptionTag, lists, expandAll, handleCheckbox, handleSave}) {
    const { handleMessage, user } = useApp();
    
    const handleCheck = isDisabled => {
        if (isDisabled) {
            handleMessage('info', 'To access this digest, please upgrade your subscription');
        }
    }

  return (
    <Card type='paddless' sx={{mx: 0}}>
        {lists.map((list, i) => (
            <Accordion defaultExpanded={expandAll || i === 0} key={`digests-${list.type}`} sx={{margin: '0 !important', boxShadow: 'none', borderTop: '1px solid #ECEBEB', '&::before': {backgroundColor: 'transparent'}}}>
                <AccordionSummary
                    id={`digests-${list.type}`}
                    aria-controls={`digests-${list.type}-content`}
                    expandIcon={
                        <Icon baseClassName='fa-solid' className='fa-caret-down'
                        sx={{color: '#413F3F', fontSize: '16px'}} />
                    }
                    sx={{
                        minHeight: 'min-content',
                        px: '10px',
                        '&.Mui-expanded': {
                            minHeight: 'fit-content',
                            borderBottom: '1px solid #ECEBEB'
                        },
                        '& .Mui-expanded, & .MuiAccordionSummary-content': {
                            my: '10px !important',
                        }
                    }}
                >
                    <Text sx={{fontWeight: 700, fontSize: '0.875rem', lineHeight: '17px', textTransform: 'uppercase'}}>{list.title}</Text>
                </AccordionSummary>
                <AccordionDetails sx={{p: '20px'}}>
                    <FormGroup>
                        {list.digests.map((dg, index) => {
                            const isDisabled = subscriptionTag === 'Free' && !user.isMigrated && !freeDigests.some(digest => digest === dg.name);
                            const id = Object.keys(dg).find(key => key.includes('Id'));
                            return (
                                <Checkbox labelProps={{onClick: () => handleCheck(isDisabled)}} key={index} disabled={isDisabled} checked={dg.selected} id={dg[id]} name={dg.name} shape='circle' onChange={e => handleCheckbox(e, list.type)} placement='start' label={
                                    <span>
                                        <Text sx={{fontSize: '0.875rem'}}>{dg.text}</Text>
                                        <Text sx={{fontSize: '0.75rem', display: {xs: 'none', sm: 'block'}}}>{dg.description}</Text>
                                    </span>
                                } />
                            )
                        })}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        ))}
        {saved && 
            <React.Fragment>
                <Divider sansMargin />
                <StyledContainer sx={{p: '20px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={handleSave} sx={{maxWidth: '250px', textAlign: 'right'}}>Update</Button>
                </StyledContainer>
            </React.Fragment>
        }
    </Card>
  )
}
