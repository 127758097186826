import React from 'react';
import Text from '../atoms/Text';
import CollapseBoxes from '../molecules/CollapseBoxes';
import { useDigest } from '../../features/digests/context';
import { useApp } from '../../../AppContext';

export default function Digest({saved = false}) {
  const { digests, handleSetDigest, handlePostDigestSubs } = useDigest();
  const { user } = useApp();
  return (
    <React.Fragment>
      {!digests.length ? 
        <Text>Loading...</Text>
      : 
        <CollapseBoxes saved={saved} handleSave={handlePostDigestSubs} subscriptionTag={user.mappedProductName} lists={digests} expandAll handleCheckbox={handleSetDigest} />
      }
    </React.Fragment>
  )
}
