import React, { useEffect } from 'react'
import { Grid, Icon, styled } from '@mui/material'
import { useAccount } from '../../components/features/account/context'
import Link from '../../components/UI/atoms/Link'
import StyledContainer from '../../components/UI/atoms/StyledContainer'
import Text from '../../components/UI/atoms/Text'
import Card from '../../components/UI/molecules/Card';
import Feed from '../../components/UI/molecules/Feed'

const StyledLogo = styled('img')(() => ({
    maxWidth: '300px',
    width: '100%',
    margin: 'auto'
}))

export default function FinishRegistration() {
    const { handleFinishWizard } = useAccount();

    useEffect(() => {
        handleFinishWizard();
    }, []);

    // TODO replace current RSS url for TNM and MDC top stories and TNM videos
    return (
        <StyledContainer>
            <Text type='h1'>Get Started</Text>
            <Card type='description' sx={{margin: '20px 0'}}>
                <Text type='h1' sx={{marginBottom: '20px'}}>You're all set!</Text>
                <Text >You've completed account setup. To edit any of these selections or manage your account, please go to <Link type='inline' link='/account'>Account Settings</Link>.</Text>
            </Card>
            <StyledContainer>
                <Text type='h1' sx={{mb: '10px', textAlign: 'center'}}>Top Stories from TNM Group</Text>
                <Feed rssUrl={'https://www.northernminer.com/big-rss/?post_type=post'} pubName='The Northern Miner' />
            </StyledContainer>
            
            <StyledContainer sx={{maxWidth: '1075px', margin: '0 auto 60px'}}>
                <Text type='h1' sx={{marginBottom: '20px', textAlign: 'center'}}>Read more stories on</Text>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} sx={{textAlign: 'center'}}>
                        <Link external openTab link='https://www.northernminer.com/'>
                            <StyledLogo alt='Northern Miner logo as external link' src='https://mediakit.northernminer.com/wp-content/uploads/2020/11/tnm-logo.png' />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} sx={{textAlign: 'center'}}>
                        <Link external openTab link='https://www.mining.com/'>
                            <StyledLogo alt='Mining.com logo as external link' src='/images/MDC-Logo.png' />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} sx={{textAlign: 'center'}}>
                        <Link external openTab link='https://www.canadianminingjournal.com/'>
                            <StyledLogo alt='Canadian Mining Journal logo as external link' src='https://mediakit.northernminer.com/wp-content/uploads/2021/02/CMJ-Logo.png' />
                        </Link>
                    </Grid>
                </Grid>
            </StyledContainer>
            <StyledContainer>
                <Text type='h1' sx={{mb: '10px', textAlign: 'center'}}>Latest Videos from TNM Group</Text>
                <Feed rssUrl={'https://www.northernminer.com/big-rss/?category_name=video'} pubName='The Northern Miner' />
            </StyledContainer>
            <StyledContainer sx={{maxWidth: '1075px', margin: 'auto', mb: '40px'}}>
                <Text type='h1' sx={{marginBottom: '20px', textAlign: 'center'}}>Follow us on Social Media</Text>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{textAlign: 'center'}}>
                        <Text sx={{marginBottom: '10px', textTransform: 'uppercase'}}>The Northern Miner</Text>
                        <StyledContainer sx={{display: 'flex', justifyContent: 'center'}}>
                            <Link external openTab type='icon-wrapper' link='https://twitter.com/northernminer'><Icon baseClassName='fa-brands' className='fa-twitter' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.linkedin.com/company/the-northern-miner/'><Icon baseClassName='fa-brands' className='fa-linkedin' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.facebook.com/NorthernMiner'><Icon baseClassName='fa-brands' className='fa-square-facebook' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.youtube.com/c/NorthernMiner'><Icon baseClassName='fa-brands' className='fa-youtube' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://soundcloud.com/northern-miner'><Icon baseClassName='fa-brands' className='fa-soundcloud' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://podcasts.apple.com/ca/podcast/the-northern-miner-podcast/id1099281201?mt=2'><Icon baseClassName='fa-solid' className='fa-podcast' /></Link>
                        </StyledContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{textAlign: 'center'}}> 
                        <Text sx={{marginBottom: '10px', textTransform: 'uppercase'}}>Mining.com</Text>
                        <StyledContainer sx={{display: 'flex', justifyContent: 'center'}}>
                            <Link external openTab type='icon-wrapper' link='https://twitter.com/mining'><Icon baseClassName='fa-brands' className='fa-twitter' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.linkedin.com/company/mining.com-infomine-inc.-/'><Icon baseClassName='fa-brands' className='fa-linkedin' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.facebook.com/miningdotcom/'><Icon baseClassName='fa-brands' className='fa-square-facebook' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.youtube.com/user/miningdotcom'><Icon baseClassName='fa-brands' className='fa-youtube' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.instagram.com/miningdotcom/'><Icon baseClassName='fa-brands' className='fa-instagram' /></Link>
                        </StyledContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{textAlign: 'center'}}> 
                        <Text sx={{marginBottom: '10px', textTransform: 'uppercase'}}>Canadian Mining Journal</Text>
                        <StyledContainer sx={{display: 'flex', justifyContent: 'center'}}>
                            <Link external openTab type='icon-wrapper' link='https://twitter.com/Cdn_Mining_Jrnl'><Icon baseClassName='fa-brands' className='fa-twitter' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.linkedin.com/company/canadian-mining-journal/'><Icon baseClassName='fa-brands' className='fa-linkedin' /></Link>
                        </StyledContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{textAlign: 'center'}}> 
                        <Text sx={{marginBottom: '10px', textTransform: 'uppercase', whiteSpace: 'nowrap'}}>The Northern Miner Group</Text>
                        <StyledContainer sx={{display: 'flex', justifyContent: 'center'}}>
                            <Link external openTab type='icon-wrapper' link='#'><Icon baseClassName='fa-brands' className='fa-twitter' /></Link>
                            <Link external openTab type='icon-wrapper' link='https://www.linkedin.com/company/tnmgroup/mycompany/'><Icon baseClassName='fa-brands' className='fa-linkedin' /></Link>
                            <Link external openTab type='icon-wrapper' link='#'><Icon baseClassName='fa-brands' className='fa-square-facebook' /></Link>
                        </StyledContainer>
                    </Grid>
                </Grid>
            </StyledContainer>
        </StyledContainer>
    )
}
