import React, { useEffect, useState, useRef } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, MenuItem } from '@mui/material'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAccount } from '../../components/features/account/context'
import Input from '../../components/UI/atoms/Input'
import StyledContainer from '../../components/UI/atoms/StyledContainer'
import Text from '../../components/UI/atoms/Text'
import Card from '../../components/UI/molecules/Card'
import Button from '../../components/UI/atoms/Button'
import Checkbox from '../../components/UI/atoms/Checkbox'
import { validateIndFilled } from '../../lib/validateInputsFilled'
import data from '../../data/industries.json';
import Reaptcha from 'reaptcha';
import helperText from '../../data/validationMessage.json'

export default function ActivateAccount({code, response, isUser}) {
    const { plans, handleAccountForm, form, formValidation, handleVerifyCaptcha, handleActivateUser, handleActivateAccount, handleError } = useAccount();
    // const { plans, handleSetMembership, handleAccountForm, form, formValidation, handleCreateAccount, handleVerifyCaptcha, handleExpiryCaptcha, handlePlanFrequency } = useAccount();
    const [disableSave, setDisableSave] = useState(true);
    const [ token, setToken ] = useState('');
    // const [ selected, setSelected ] = useState({});
    // const [ membershipType, setMembershipType ] = useState('');
    // const { type } = useParams();
    // const [ searchParams ] = useSearchParams();
    const captchaRef = useRef(null);

    // useEffect(() => {
    //     if (plans.length && emptyObject(selected)) {
    //         const tag = type;
    //         handleSetMembership(tag);

    //         let plan = plans.find(plan => plan.subscriptionTag === tag);
    //         setSelected(plan);

    //         const membership = searchParams.get('subscription').replace('_', ' ');
            
    //         setMembershipType(membership);
    //     }
    //     // eslint-disable-next-line
    // }, [plans, selected]);

    useEffect(() => {
        setDisableSave(validateIndFilled(form, formValidation));
    }, [form, token]);

    const handleSubmit = () => {
        if (disableSave) {
            handleError();
        } else {
            if (response.firm) {
                handleActivateAccount(code, response.firm)
            } else {
                handleActivateUser(code);
            }
        }
    }

    const handleVerify = () => {
        captchaRef.current.getResponse().then(res => {
            setToken(res);
            handleVerifyCaptcha();
        })
    }
    const handleExpire = () => {
        setTimeout(() => captchaRef.current.reset(), 0);
    }

  return (
    <StyledContainer sx={{maxWidth: '450px', margin: 'auto'}} className='activate-account'>
        <Text type='h1'>Activate your {response.firm === 'group-admin' && 'Group Admin '}Account</Text>
        {(!plans.length) ?
        // {(!plans.length || emptyObject(selected)) ?
            <Text>Loading...</Text>
        :
            <React.Fragment>
                <Card type='description' sx={{margin: '20px 0'}}>
                    <Text sx={{ fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: '700', lineHeight: '28px'}}>{response.firm ? 'Group Admin' : 'Group Membership'}</Text>
                    {/* <Text sx={{ fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: '700', lineHeight: '28px'}}>{selected.productName}</Text> */}
                    {/* {membershipType !== 'group-admin' && 
                        <Accordion>
                            <AccordionSummary expandIcon={<Icon baseClassName='fa-solid' className='fa-caret-down' sx={{fontSize: '1rem', color: '#413F3F'}} />} id='features-header' aria-controls='features-content'>
                                <Text sx={{fontSize: '1.125rem', fontWeight: '700', textTransform: 'uppercase'}}>Features</Text>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul>
                                    {selected.features.map((feature, index) => (
                                        <li key={index}>{feature.feature}</li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    } */}
                </Card>
                <Card sx={{margin: '20px 0'}}>
                    <Text type='h1'>Activate your account</Text>
                    <Text><span style={{color: '#d32f2f', fontWeight: '600'}}>* </span>Required fields</Text>
                    <Box component='form' sx={{marginTop: '20px'}}>
                        <Input label='email' required disabled type='email' id='email' value={form.email}/>
                        <Input label='password' required passwordInput error={!formValidation.password} helperText={ !formValidation.password && helperText.data.password} id='password' value={form.password} onChange={(e) => handleAccountForm(e)}/>
                        <Input label='confirm password' required passwordInput error={!formValidation.confirmPassword} helperText={ !formValidation.confirmPassword && helperText.data.confirmPassword} id='confirmPassword' value={form.confirmPassword} onChange={(e) => handleAccountForm(e)}/>
                        <Input label='first name' required error={!formValidation.firstName} helperText={!formValidation.firstName && helperText.data.firsName} id='firstName' value={form.firstName} onChange={(e) => handleAccountForm(e)}/>
                        <Input label='last name' required error={!formValidation.lastName} helperText={ !formValidation.lastName && helperText.data.lastName} id='lastName' value={form.lastName} onChange={(e) => handleAccountForm(e)}/>
                        <Input label='job title' required error={!formValidation.jobTitle} helperText={ !formValidation.jobTitle && helperText.data.jobTitle} id='jobTitle' value={form.jobTitle} onChange={(e) => handleAccountForm(e)}/>
                        {isUser ?
                            <Input label='company name' required disabled id='companyName' value={form.companyName}/>
                        :
                            <Input label='company name' required error={!formValidation.companyName} helperText={ !formValidation.companyName && helperText.data.company} id='companyName' value={form.companyName} onChange={(e) => handleAccountForm(e)}/>
                        }
                        <Input label='industry' required error={!formValidation.industry} id='industry' helperText={ !formValidation.industry && helperText.data.industry} name='industry' select value={form.industry} onChange={(e) => handleAccountForm(e, 'select')}>
                            {data.industries.map((industry, key) => (
                                <MenuItem key={key} name='industry' value={industry}>{industry}</MenuItem>
                            ))}
                        </Input>
                        <Text sx={{fontSize: '0.875rem'}}>Consent <span style={{color: '#d32f2f', fontWeight: '600'}}>*</span></Text>
                        <Checkbox helperText={!formValidation.CASLoptin && helperText.data.CASLoptin} id='CASLoptin' checked={form.CASLoptin} onChange={(e) => handleAccountForm(e, 'checkbox')} label={
                              <Text sx={{ fontSize: '0.5rem' }}>By submitting this form I consent to The Northern Miner Group's Policies & Terms and agree to receive commercial electronic messages from The Northern Miner Group and affiliated divisions. You may withdraw your consent at any time.</Text>
                        } />
                        <Reaptcha
                            sitekey={process.env.REACT_APP_MEMBERSHIP_CAPTCHA_SITE_KEY}
                            className='captcha'
                            onVerify={handleVerify}
                            ref={captchaRef}
                            onExpire={handleExpire}
                        />
                        <Button onClick={() => handleSubmit()} className={disableSave ? 'submit-disable' : ''}>Activate Account</Button>
                    </Box>
                </Card>
            </React.Fragment>
        }
    </StyledContainer>
  )
}
