import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useApp } from '../../AppContext';
import useToken from '../features/token/useToken';
import Loading from '../UI/atoms/Loading';

export default function SignedOutWrapper() {
    const { handleToken, signedIn } = useToken();
    const { user, isLoadingAuth } = useApp();
    const location = useLocation();
    const [ searchParams ] = useSearchParams();

    useEffect(() => {
        handleToken();
    }, []);

    if (isLoadingAuth) {
        return (
            <Loading />
        )
    }
    if (signedIn) {
        const route = user.finishedWizard ? 'subscriptions' : 'news';
        const src = searchParams.get('src');
        // render login page when it has query params
        if (location.pathname.includes('login') && src) {
            return (
            <Outlet />
        )
        } else {
            return (
                <Navigate to={route} replace />
            )
        }
    } else if (signedIn === false) {
        return (
          <Outlet />
        )
    }
}