import { isDateAhead } from "../dateDifference";

export const displayFlags = (currentSubscription, flag, user) => {
    switch (flag) {
        case "trialMessage":
            return currentSubscription &&
                currentSubscription.subscription &&
                currentSubscription.subscription.trialEndDate &&
                isDateAhead(currentSubscription.subscription.trialEndDate) && 
                !currentSubscription.subscription.userCancelled;
        case "toCancelMessage":
            return currentSubscription &&
                currentSubscription.subscription &&
                currentSubscription.subscription.userCancelled &&
                isDateAhead(currentSubscription.subscription.renewalDate);
        case "cancelledMessage":
            return currentSubscription &&
                currentSubscription.subscription &&
                !currentSubscription.subscription.active &&
                !isDateAhead(currentSubscription.subscription.renewalDate);
        case "faliedPayment":
            return currentSubscription &&
                currentSubscription.subscription &&
                user.lastPaymentFailed &&
                !currentSubscription.subscription.trialEndDate && 
                currentSubscription.subscription.userCancelled;
    }
}