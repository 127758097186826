import React from 'react';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(() => ({
    boxSizing: 'border-box'
}))

export default function StyledContainer(props) {
  return (
    <StyledDiv {...props}>{props.children}</StyledDiv>
  )
}
