import React from 'react';

import StyledContainer from '../../components/UI/atoms/StyledContainer';
import Text from '../../components/UI/atoms/Text';
import Card from '../../components/UI/molecules/Card';

export default function ConfirmAccount() {
  return (
    <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
        <Card sx={{maxWidth: '400px'}}>
            <Text type='h1' sx={{textAlign: 'center'}}>Confirm your account</Text>
            <Text sx={{marginTop: '20px', textAlign: 'center'}}>Your account has been created successfully.</Text>
            <Text sx={{marginTop: '20px', textAlign: 'center'}}>Please check your email for a confirmation link to complete your registration.</Text>
        </Card>
    </StyledContainer>
  )
}
