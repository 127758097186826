const inputs = {
    account: ['firstName', 'lastName', 'jobTitle', 'companyName', 'industry'],
    email: ['previousEmail', 'email'],
    password: ['previousPassword', 'password'],
    create: ['email', 'password', 'confirmPassword', 'firstName', 'lastName', 'jobTitle', 'companyName', 'industry', 'CASLoptin', 'captcha']
}

export const validateIndFilled = (form, validate) => {
    let filledForm = inputs.create.every((value) =>  form[value] !== '' || form[value]);
    const validForm =  inputs.create.every((k) =>  validate[k]);

    const validateArray = [filledForm, validForm];
    var ret= !validateArray.every(x => x);
    return ret;
}
export const validateInputsFilled = (formInputs, validate, originalForm) => {
    let filledForm = Object.keys(validate)
        .every((value) =>  formInputs[value] !== '' || formInputs[value]);
    const validForm =  Object.values(validate).every((k) =>  k);

    const validateArray = [filledForm, validForm];
    if (!validateArray.every(x => x)) { // if form is not valid, return disable true
        return true;
    } else if (originalForm) { // if form is valid, return depending on changes in form
        // return formInputs === originalForm;
        return false;
    } else {
        return false;
    }
}
export const validateSegmentedInputsFilled = (form, validate) => {
    let filledIndForm = {};
    Object.entries(inputs).map(([formName, values]) => {
        const formFilled = values.every((value) =>  form[value] !== '');
        const formValidated = values.every((value) => validate[value]);

        const validateArray = [formFilled, formValidated];
        filledIndForm[formName] = !validateArray.every(x => x);;
        return true;
    })
    
    return filledIndForm;
}
export const highlightEmptyInputs = (form, validateForm) => {
    let validated = {... validateForm};
    Object.keys(validated).map((formName) => {
        if (!form[formName]) {
            validated[formName] = false;
        }
        return true;
    })
    return validated;
}