import { useState } from 'react'
import Cookies from 'universal-cookie';
import { useApp } from '../../../AppContext';
import { emptyObject } from '../../../lib/emptyObject';
import { createNewSessionCookies, generateValidCookie } from '../../../lib/validCookie';
import { postGerenateNewToken, verifyToken } from '../../../services/authMiddleware';

const cookies = new Cookies();

export default function useToken() {
    const token = cookies.get('token');
    const valid = cookies.get('valid');
    const { user, handleUser, handleStopLoading } = useApp();
    const [ signedIn, setSignedIn ] = useState(null);

    const doesTokenExist = token && (token !== '' && token !== 'undefined');

    const handleToken = () => {
        if (doesTokenExist && emptyObject(user)) {
            if (valid === undefined) {
                verifyToken(token).then(data => {
                    if (!data.data) {
                        postGerenateNewToken().then(res => {
                            createNewSessionCookies(res.data);
                            handleUser();
                            setSignedIn(true);
                        }).catch(() => {
                            setSignedIn(false);
                            handleStopLoading();
                        })
                    } else {
                        generateValidCookie();
                        handleUser();
                        setSignedIn(true);
                    }
                })
            } else {
                handleUser();
                setSignedIn(true);
                handleStopLoading();
            }
        } else {
            setSignedIn(false);
            handleStopLoading();
        }
    }

    return {
        token,
        signedIn,
        setSignedIn,
        handleToken,
        doesTokenExist,
    }
}
