import React from 'react';
import { Snackbar as MUISnackbar, Alert } from '@mui/material';
import { useApp } from '../../../AppContext';

export default function Snackbar() {
  const { message } = useApp();
    
  return (
    <MUISnackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} open={Boolean(message.content)} autoHideDuration={5000}>
        <Alert severity={message.status}>{message.content}</Alert>
    </MUISnackbar>
  )
}

