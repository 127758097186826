import { Icon } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useApp } from '../../AppContext';
import { useAccount } from '../../components/features/account/context';
import { getFailedPayment, postSubscriptionStatus } from '../../components/features/account/middleware';
import Button from '../../components/UI/atoms/Button';
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import Text from '../../components/UI/atoms/Text';
import Card from '../../components/UI/molecules/Card';

const cookies = new Cookies();

export default function Payment() {
    const [searchParams] = useSearchParams();
    const { handleLoading, handleMessage, user } = useApp();
    const { handleNewCheckoutSession } = useAccount();
    const [ status, setStatus ] = useState(undefined);
    const [ checkout, setCheckout ] = useState(undefined);
    const prev = cookies.get('prev');

    useEffect(() => {
        let session = {};
        if (searchParams.get('success')) {
            session.success = true;
            session.sessionId = searchParams.get('session_id');
        }
        else {
            session.success = false;
        }
        postSubscriptionStatus(session).then(data => {
            handleLoading(false);
            if (session.success) {
                
                cookies.set('success', true, {path: '/'});
                if (prev && prev !== 'undefined') {
                    cookies.set('prev', undefined);
                    window.location.href = prev;
                } else {
                    if (user.finishedWizard) {
                        window.location.href = '/subscriptions';
                    } else {
                        window.location.href = '/digests';
                    }
                 }
            } else {
                setStatus(false);
                return;
            }
        }).then(() => {
            getFailedPayment().then(data => {
                setCheckout(data.data);
            })
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
            setStatus(false);
        });
    }, []);

    const handlePayment = () => {
        handleNewCheckoutSession(checkout);
    }
    const goBack = () => {
       
        if (prev && prev !== 'undefined') {
            cookies.set('prev', undefined);
            window.location.href = prev;
        } else {
            window.location.href = '/subscriptions';
        }
    }
    
    return (
        <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
            <Card sx={{maxWidth: '400px'}}>
                {status === undefined ?
                    <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Saving payment, please don't reload the page.</Text>
                : status ?
                    <React.Fragment>
                        <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Payment Complete!</Text>
                        <Text>Redirecting you to {(prev && prev !== 'undefined') ? 'the previous page' : 'continue the registration'}</Text>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Button onClick={() => goBack()} className='text-link' sx={{mb: '10px', display: 'flex', alignItems: 'baseline'}}><Icon baseClassName='fa-regular' className='fa-angle-left' fontSize='0.875rem' /> Back to previous page</Button>
                        <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Payment Failed</Text>
                        <Text sx={{textAlign: 'center', mb: '10px'}}>There was an issue with your payment. Please try again below.</Text>
                        <Button onClick={() => handlePayment()}>Complete Payment</Button>
                    </React.Fragment>
                }
            </Card>
        </StyledContainer>
    )
}
