import axios from "axios";
import Cookies from "universal-cookie";
import { postGerenateNewToken, verifyToken } from "./authMiddleware";
import { createNewSessionCookies, generateValidCookie } from "../lib/validCookie";

const cookies = new Cookies();

const validateCookie = async () => {
    const token = cookies.get('token');
    const valid = cookies.get('valid');

    if (valid !== 'true') {
        await verifyToken(token).then(data => {
            if (!data.data) {
                postGerenateNewToken().then(res => {
                    createNewSessionCookies(res.data);
                    return true;
                }).catch(error => {
                    console.error(error);
                })
            } else {
                generateValidCookie();
                return true;
            }
        });
    } else {
        return true;
    }
}

// service requests that require a valid token
export const authGet = async url => {
    const token = cookies.get('token');

    const validated = validateCookie();
    if (validated) {
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
    }
}
export const authPost = async (url, params) => {
    const token = cookies.get('token');

    const validated = validateCookie();
    if (validated) {
        return await axios.post(url, params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
    }

}
export const authPut = async (url, params) => {
    const token = cookies.get('token');

    const validated = validateCookie();
    if (validated) {
        return await axios.put(url, params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
    }
}

// service requests for validating the token
export const authGetToken = async (url) => {
    const token = cookies.get('token');
    return await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}
export const authPostToken = async (url, params) => {
    const token = cookies.get('token');
    return await axios.post(url, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}