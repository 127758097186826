import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';

const theme = createTheme({
    palette: {
        primary: {
            main: '#AB9F60',
            light: '#CDC384',
            dark: '#7E6F30'
        },
        success: {
            main: '#6B9D17',
            light: '#A5C846',
            dark: '#50820D'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif'
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '3px'
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '.MuiFilledInput-root': {
                        '&::before, &::after': {
                            content: 'none'
                        }
                    }
                }
            }
        }
      },
});

export default function Theme({children}) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}