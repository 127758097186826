export const formatSignup = (state, selected) => {
    
    const formatted = {
        email: state.form.email.trim(),
        password: state.form.password,
        firstName: state.form.firstName,
        lastName: state.form.lastName,
        jobTitle: state.form.jobTitle,
        Industry: state.form.industry,
        companyName: state.form.companyName,
        CASLoptin: new Date().toLocaleDateString('en-us', { year:"numeric", month:"numeric", day:"numeric"}),
        productId: selected.productId,
        priceId: selected.priceId,
        phoneNo: state.form.phoneNo,
    }

    return formatted;
}