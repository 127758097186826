import { authGet, authPost } from "../../../services/authCalls"

export const getDigestNewsletter = async () => {
    return authGet('/api/start/digest-newsletter');
}
export const getWizardDigNews = async () => {
    return authGet('/api/wizard/digest-newsletter');
}
export const postWizardDigest = async digests => {
    return authPost('/api/wizard/digest', digests);
}
export const postWizardNewsletter = async newsletters => {
    return authPost('/api/wizard/newsletter', newsletters);
}