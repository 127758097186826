import React, { useEffect, useState } from 'react';
import StyledContainer from '../../components/UI/atoms/StyledContainer'
import Text from '../../components/UI/atoms/Text'
import Card from '../../components/UI/molecules/Card'
import { useApp } from '../../AppContext';
import Button from '../../components/UI/atoms/Button';
import Input from '../../components/UI/atoms/Input';
import { validateInputsFilled } from '../../lib/validateInputsFilled';
import ForgotPassword from './ForgotPassword';
import { useSearchParams } from 'react-router-dom';
import { getPasswordCode } from '../../components/features/account/middleware';
import { useAccount } from '../../components/features/account/context';
import helperText from '../../data/validationMessage.json';
import { passwordRegex } from '../../data/values';

export default function NewPassword() {
    const { handleLoading } = useApp();
    const { handleNewPassword } = useAccount();
    const [searchParams] = useSearchParams();
    const [ form, setForm ] = useState({password: '', confirmPassword: ''});
    const [ formValidate, setFormValidate ] = useState({password: true, confirmPassword: true});
    const [ disableSave, setDisableSave ] = useState(true);
    const [ user, setUser ] = useState({});
    const [ valid, setValid ] = useState(undefined);

    useEffect(() => {
        const code = searchParams.get('code');
        getPasswordCode(code).then(data => {
            setValid(true);
            setUser(data.data);
            handleLoading(false);
        }).catch(() => {
            setValid(false);
        })
    }, []);

    useEffect(() => {
        setDisableSave(validateInputsFilled(form, formValidate));
    }, [form]);
    
    const handleInput = e => {
        const id = e.target.id;
        const value = e.target.value;
        let valid;
        if (id === 'password') {
            const validRegex = passwordRegex;
            valid = value.match(validRegex) ? true : false;
        } else {
            valid = value === form.password;;
        }
        setForm({...form, [id]: value});
        setFormValidate({...formValidate, [id]: valid});
    }
    
    const handleSubmit = () => {
        const code = searchParams.get('code');
        const body = {
            password: form.password,
            activationCode: code,
            activationUser: user 
        }
        handleNewPassword(body);
    }

    if (valid === undefined) {
        return (
            <div></div>
        )
    }
    else if (valid === false) {
        return (
            <ForgotPassword />
        )
    }
    else {
        return (
            <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
                <Card sx={{maxWidth: '400px'}}>
                    <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>New Password</Text>
                    <Input error={!formValidate.password} helperText={!formValidate.password && helperText.data.password} label='new password' passwordInput id='password' value={form.password} onChange={(e) => handleInput(e)}/>
                    <Input error={!formValidate.confirmPassword} helperText={!formValidate.confirmPassword && helperText.data.confirmPassword} label='Confirm Password' passwordInput id='confirmPassword' value={form.confirmPassword} onChange={(e) => handleInput(e)}/>
                    <Button disabled={disableSave} onClick={() => handleSubmit()}>Save</Button>
                </Card>
            </StyledContainer>
        )
    }
    
}
