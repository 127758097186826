import React, { useEffect } from 'react';

import { useDigest } from '../../components/features/digests/context';
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import Text from '../../components/UI/atoms/Text';
import Card from '../../components/UI/molecules/Card';
import Digest from '../../components/UI/organisms/Digest';

export default function Digests() {
    const { handleWizardDigNews, digests } = useDigest();

    useEffect(() => {
      if (!digests.length) {
        handleWizardDigNews();
      }
    }, [digests]);
  return (
    <StyledContainer>
        <Text sx={{fontWeight: '700', fontSize: '1.125rem', lineHeight: '22px', textAlign: {xs: 'center', sm: 'center', md: 'left'}, textTransform: 'uppercase'}}>Get Started</Text>
        <Card type='description' sx={{margin: '20px 0'}}>
            <Text sx={{fontWeight: '700', fontSize: '1.125rem', lineHeight: '22px', textAlign: {xs: 'center', sm: 'center', md: 'left'}, textTransform: 'uppercase', marginBottom: '20px'}}>Never Miss a Story</Text>
            <Text sx={{mb: '20px'}}>Get the latest news delivered directly to your inbox with email digests from our publications.</Text>
            <Text>Choose from a variety of topics from across The Northern Miner and MINING.COM</Text>
        </Card>
        <Digest />
    </StyledContainer>
  )
}
