export const accountReducer = (state, action) => {
    switch (action.type) {
        case "SET_TEXT_INPUT":
            return {
                ...state,
                [action.payload.form]: {
                    ...state[action.payload.form],
                    [action.payload.value.id]: action.payload.value.value
                }
            }
        case "SET_VALIDATION":
            return {
                ...state,
                formValidation: {
                    ...state.formValidation,
                    [action.payload.id]: action.payload.value
                }
            }
        case "SET_ALL_VALIDATION": 
            return {
                ...state,
                formValidation: {...action.payload}
            }
        case "SET_ADDRESS_VALIDATION":
            return {
                ...state,
                addressFormValidation: {
                    ...state.addressFormValidation,
                    [action.payload.id]: action.payload.value
                }
            }
        case "SET_ALL_VALIDATION_ADDRESS": 
            return {
                ...state,
                addressFormValidation: {...action.payload}
            }
        case "SET_SELECT_INPUT":
            return {
                ...state,
                [action.payload.form]: {
                    ...state[action.payload.form],
                    [action.payload.value.name]: action.payload.value.value
                }
            }
        case "SET_CHECKBOX_INPUT":
            return {
                ...state,
                [action.payload.form]: {
                    ...state[action.payload.form],
                    [action.payload.value.id]: action.payload.value.checked
                }
            }
        case "SET_MEMBERSHIP": 
            return {
                ...state,
                membership: action.payload
            }
        case "SET_ACCOUNT": 
            return {
                ...action.payload
            }
        case "SET_PLANS": 
            return {
                ...state,
                plans: action.payload
            } 
        case "SET_PLANS_RENEW": 
            return {
                ...state,
                plans_to_renew: action.payload
            }
        case "SET_DIGITAL":
            return {
                ...state,
                digital: action.payload
            }
        case "SET_SHIPPINGFEE":
            return {
               ...state,
              shippingFee: action.payload
                }
        case "SET_STRIPE": 
            return {
                ...state,
                stripeSubs: action.payload
            }
        case "SET_COUNTRIES": 
            return {
                ...state,
                countries: action.payload
            }
        case "SET_STATES": 
            return {
                ...state,
                states: action.payload
            }
        case "SET_SELECTED_PLAN":
            return {
                ...state,
                planInfo: action.payload
            }
        case "SET_USER_FORM":
            Object.keys(state.form).map(input => {
                if (input.includes('mail')) {
                    state.form[input] = '';
                }
                else {
                    state.form[input] = action.payload[input] || '';
                }
                return input;
            });
            Object.keys(state.formValidation).map(input => {
                state.formValidation[input] = true;
                return true;
            })
            return {
                ...state,
                form: {...state.form, password: "", confirmPassword: ""},
            }
        case "SET_ACTIVATION_USER_FORM":
            return {
                ...state,
                form: {
                    ...state.form,
                    email: action.payload.email,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    companyName: action.payload.firmName
                }
            }
        case "SET_SUBSCRIPTION_PAGE":
            return {
                ...state,
                currentSubscription: action.payload,
            }
        case "SET_ADDRESS_FORM": 
            Object.keys(state.addressForm).map(input => {
                state.addressForm[input] = action.payload[input] || '';
                return input;
            });
            return {
                ...state
            }
        case "SET_FREQUENCY": 
            return {
                ...state,
                frequency: action.payload
            }
        case "SET_GROUP":
            return {
                ...state,
                group: action.payload
            }
        case "SET_PAST_RENEWAL_DATE":
            return {
                ...state,
                currentSubscription: {
                    ...state.currentSubscription,
                    subscription: {
                        ...state.currentSubscription.subscription,
                        renewalDate: action.payload
                    }
                }
            }
        case "SET_IMAGE_URI":
            return {
                ...state, imgURI: action.payload
            }
        default:
            return state
    }
}