import React from 'react';
import { Checkbox as MUICheckBox, Icon, FormControlLabel, FormControl, FormHelperText } from '@mui/material';

export default function Checkbox(props) {
    const { labelProps, label, placement, shape, helperText, ...other } = props;
    if (shape === 'circle') {
        return (
            <FormControlLabel
                {...labelProps}
                label={label}
                labelPlacement={placement}
                sx={{
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                    ml: 0,
                    '&:last-of-type': {
                        mb: 0
                    }
                }}
                control={
                    <MUICheckBox
                        {...other}
                        color='success'
                        sx={{ padding: '7px' }}
                        icon={
                            <Icon baseClassName='fa-regular' sx={{fontSize: '20px', height: '21px', pt: '1px'}} className='fa-circle' />
                        }
                        checkedIcon={
                            <Icon baseClassName='fa-solid' sx={{fontSize: '20px', height: '21px', pt: '1px'}} className='fa-circle-check' />
                        }
                    />
                }
            />
        )
    }
    else {
        return (
            <FormControl sx={{mb: '20px'}}>
                <FormControlLabel
                    label={props.label}
                    labelPlacement={props.placement}
                    sx={{margin: '0'}}
                    control={
                        <MUICheckBox
                            {...other}
                            sx={{padding: 0, marginRight: '10px'}}
                            color='success'
                        />
                    }
              />
              {helperText && 
                <FormHelperText sx={{color: '#d32f2f'}}>{helperText}</FormHelperText>
              }
            </FormControl>
        )
    }
}