import React, { useEffect, useState } from 'react';
import StyledContainer from '../../components/UI/atoms/StyledContainer'
import Text from '../../components/UI/atoms/Text'
import Card from '../../components/UI/molecules/Card'
import { useApp } from '../../AppContext';
import Button from '../../components/UI/atoms/Button';
import Input from '../../components/UI/atoms/Input';
import { validateInputsFilled } from '../../lib/validateInputsFilled';
import { postForgotPassword } from '../../components/features/account/middleware';
import Link from '../../components/UI/atoms/Link';
import { Icon } from '@mui/material';

export default function ForgotPassword() {
    const { handleLoading, handleMessage } = useApp();
    const [ form, setForm ] = useState({email: ''});
    const [ emailSent, setEmailSent ] = useState(false);
    const [ formValidate, setFormValidate ] = useState({email: true});
    const [ disableSave, setDisableSave ] = useState(true);

    useEffect(() => {
        handleLoading(false);
    }, []);

    useEffect(() => {
        setDisableSave(validateInputsFilled(form, formValidate));
    }, [form]);
    
    const handleInput = e => {
        const id = e.target.id;
        const value = e.target.value;
        
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const valid = value.match(validRegex) ? true : false;
        
        setForm({email: value});
        setFormValidate({email: valid});
    }
    
    const handleResetPassword = () => {
        handleLoading(true);
        postForgotPassword(form.email.trim()).then(() => {
            handleLoading(false, true);
            setEmailSent(true);
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        });
    }
    
    return (
        <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
            <Card sx={{maxWidth: '400px'}}>
                {emailSent ? 
                    <React.Fragment>
                        <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Email Sent</Text>
                        <Text sx={{textAlign: 'center', mb: '10px'}}>A password reset link will be sent to your email address if it is associated with an active account.</Text>
                        <Text sx={{textAlign: 'center'}}>Please click the link in the message to reset your password.</Text>
                    </React.Fragment>    
                : 
                    <React.Fragment>
                        <Link link='/login' type='text' sx={{mb: '10px', display: 'flex', alignItems: 'baseline'}}><Icon baseClassName='fa-regular' className='fa-angle-left' fontSize='0.875rem' /> Back</Link>
                        <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Password Reset</Text>
                        <Input error={!formValidate.email} helperText={!formValidate.email && 'Enter a valid email'} label='email' type='email' id='email' value={form.email} onChange={(e) => handleInput(e)}/>
                        <Button sx={{mt: '4px'}} disabled={disableSave} onClick={handleResetPassword}>Send Reset Link</Button>
                    </React.Fragment>    
                }
            </Card>
        </StyledContainer>
    )
}
