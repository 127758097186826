export const displayPrint = user => {
    if (user.isRootUser) { // display for subscription admin
        if (user.isComplimentaryUser || (!user.currentSubscriptionId || user.currentSubscriptionId.includes('manual'))) { // don't display for complimentary users or manual subscriptions
            return false;
        } else if (user.isMigrated && (!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing'))) { // is migrated user with previous subscription, needs one more validation
            if (user.subscriptionType.includes('Print')) { // migrated user who doesn't have new subscription, only show if they alreade have print enabled
                return true;
            } else { // migrated user who doesn't have new subscription and no print enabled
                return false;
            }
        } else { // display also if is not migrated
            return true;
        }
    } else { // don't display for group members
        return false;
    }
}