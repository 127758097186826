import axios from 'axios';
import { authGetToken, authPost, authPostToken } from './authCalls';

export const postLogout = async (returnUrl = null) => {
    return await authPost('/api/sso-logout', {return_to: returnUrl}); // null implies it will be set to Auth0:LogoutUrl
}

export const verifyEmail = async code => {
    return await axios.get(`/api/email/validate-authentication-code?code=${code}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export const getNewUserValidate = async code => {
    return await axios.get(`/api/new-user/validate-authentication-code?code=${code}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export const verifyAccount = async code => {
    return await axios.get(`/api/new-account/validate-authentication-code?code=${code}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export const verifyToken = async token => {
    return await authPostToken('/api/validate-token', {accessToken: token});
}
export const postGerenateNewToken = async () => {
    return await authPostToken('/api/auth0/new-token-generation');
}
export const getRedisKey = async () => {
    return await authGetToken('/api/get-key');
}
export const getCachedToken = async token => {
    const url = 'api/get-cached-token?redis_key=' + token;
    return await axios.get(url, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
} 