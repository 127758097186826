export const digestReducer = (state, action) => {
    switch (action.type) {
        case "GET_DIGESTS": 
            return {
                ...state,
                digests: action.payload
            }
        case "GET_NEWSLETTERS": 
            return {
                ...state,
                newsletters: action.payload
            }
        case "SET_INPUT_CHECKED":
            return {
                ...state,
                [action.payload.list]: state[action.payload.list].map(publication => {
                    if (publication.type === action.payload.type) {
                        return {
                            ...publication,
                            digests: publication.digests.map(digest => {
                                if (digest.name === action.payload.input.name) {
                                    return {...digest, selected: action.payload.input.checked}
                                }
                                else return digest
                            })
                        }
                    }
                    else {
                        return publication
                    }
                })  
            }
            case "SET_DIGEST_PREFERENCE":
                return {
                    ...state,
                    digestPreferences: state.digestPreferences.map(preference => {
                        if (preference.customFieldAPIName === action.payload.id) {
                            return {
                                ...preference,
                                customFieldValue: action.payload.checked ? 'on' : 'off'
                            }
                        }
                        else {
                            return preference
                        }
                    })  
                }
            case "SET_NEWS_PREFERENCE":
                return {
                    ...state,
                    newsletterPreferences: state.newsletterPreferences.map(preference => {
                        if (preference.listId === action.payload.id) {
                            return {
                                ...preference,
                                associate: action.payload.checked
                            }
                        }
                        else {
                            return preference
                        }
                    })   
                }
        case "SET_CONTROLLED_LISTS":
            return {
                ...state,
                digestPreferences: action.payload.digestPreferences,
                newsletterPreferences: action.payload.newsletterPreferences,
                prevDigestPreferences: action.payload.digestPreferences,
                prevNewsletterPreferences: action.payload.newsletterPreferences
            }
        case "SET_PREV_CONTROLLED_LISTS":
            return {
                ...state,
                prevDigestPreferences: action.payload.digestPreferences,
                prevNewsletterPreferences: action.payload.newsletterPreferences,
                changed: false
            }
        case "DIGEST_CHANGED": 
            return {
                ...state,
                changed: action.payload
            }
        default:
            return state
    }
}