import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { accountReducer } from './reducer';
import { useNavigate }  from 'react-router-dom';
import { getCountries, postUploadPhotoS3, postS3Link, getUploadPresignedUrl, getFinishWizard, getPlans, getStates,getShippingFee,
     postAccountUpdate, updateShippingPreference, postEmailUpdate, postPasswordUpdate, postSignUp, putSubscription, getCheckoutSessionNew,
      postResetPassword, postCancelSubscription, getPortalSession,  getGroup,
       postSeatUpdate, postCreateUser, postDeleteUser,  getPhotoPresignedUrl, postActivateUser,
        postActivateAccount, getGroupPrices, updateShippingPreferenceCache, getPlansToUpgrade } from './middleware';
import { formatSignup } from '../../../lib/formatSignup';
import { useApp } from '../../../AppContext';
import Cookies from 'universal-cookie';
import { addLabelPlans } from '../../../lib/formatPlans';
import { passwordRegex } from '../../../data/values';
import { highlightEmptyInputs, validateInputsFilled } from '../../../lib/validateInputsFilled';
import { createNewSessionCookies } from '../../../lib/validCookie';

const AccountContext = createContext();

const inputs = {
    account: ['firstName', 'lastName', 'jobTitle', 'companyName', 'industry'],
    email: ['previousEmail', 'email'],
    password: ['previousPassword', 'password']
}

export const AccountProvider = ({children}) => {
    const initialState = {
        form: {
            email: '',
            previousEmail: '',
            password: '',
            confirmPassword: '',
            previousPassword: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            companyName: '',
            industry: '',
            phoneNo:'',
            CASLoptin: false
        },
        formValidation: {
            firstName:  true,
            lastName: true,
            email: true,
            previousEmail: true,
            password: true,
            confirmPassword: true,
            previousPassword: true,
            companyName: true,
            jobTitle: true,
            industry: true,
            CASLoptin: true,
            captcha: false,
            phoneNo: true
        },
        addressForm: {
            fullName: '',
            companyName: '',
            address_Line_1: "",
            address_Line_2: "",
            address_Country: "",
            address_State: "",
            address_City: "",
            address_Zip: "",
            phoneNo: "",
            copies: 1
        },
        addressFormValidation: {
            fullName: true,
            address_Line_1: true,
            address_Country: true,
            address_State: true,
            address_City: true,
            address_Zip: true,
            phoneNo: true,
            copies: true
        },
        digital: true,
        membership: '',
        plans: [],
        plans_to_renew:[],
        stripeSubs: null,
        countries: [],
        states: [],
        planInfo: null,
        currentSubscription: '',
        frequency: {},
        group: null,
        imgURI: null,
        shippingFee: null
    }

    const cookies = new Cookies();  

    const navigate = useNavigate();
    const { handleLoading, handleMessage, user, handleUpdateUserData, handleClearUser, handleUser } = useApp();
    const [ state, dispatch ] = useReducer(accountReducer, initialState);

    const handleGetAccount = () => {
        const data = {...initialState}
        dispatch({type: 'SET_ACCOUNT', payload: data})
    }
    const setPlanInfo = () => {
        const userPlan = state.plans.find(plan => plan.productName === user.mappedProductName);
        dispatch({type: 'SET_SELECTED_PLAN', payload: userPlan});
    }
    const handleValidateInput = (value, input) => {
        if (input === 'email') {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return value.match(validRegex) ? true : false;
        }
        if (input.includes('previousEmail')) {
            return value === user.email;
        }
        else if (input ==='password' || input === 'previousPassword') {
            const validRegex = passwordRegex;
            return value.match(validRegex) ? true : false;
        }
        else if (input === 'confirmPassword') {
            return value === state.form.password;
        }
        else if (input === 'CASLoptin') {
            return Boolean(value);
        }
        else {
            return value !== '';
        }
    }
    const handleAccountForm = (input, type = 'text') => {
        if (type === 'checkbox') {
            dispatch({type: 'SET_CHECKBOX_INPUT', payload: {form: 'form', value: input.target}});
        }
        else if (type === 'select') {
            dispatch({type: 'SET_SELECT_INPUT', payload: {form: 'form', value: input.target}});
        }
        else {
            dispatch({type: 'SET_TEXT_INPUT', payload: {form: 'form', value: input.target}});
        }
        const name = input.target.id || input.target.name;
        const validate = handleValidateInput(input.target.value, name);
        dispatch({type: 'SET_VALIDATION', payload: {id: name, value: validate}});
    }
    const handleError = () => {
        dispatch({type: 'SET_ALL_VALIDATION', payload: highlightEmptyInputs(state.form, state.formValidation)});
    }
    const handleCreateAccount = (token, selected, promo, type) => {
        handleLoading(true);
        const formatted = formatSignup(state, selected);
        if (formatted.priceId !== '') {
            if (state.frequency.trial) {
                formatted.freeTrialSelected = true;
            }
            if (promo) {
                formatted.promoCode = promo;
            }
        }

        postSignUp(formatted).then(data => {
            createNewSessionCookies(data.data.accessToken);
            if (formatted.priceId !== '') {
                navigate(`shipping-preference/${type}`);
            }
            else {
                navigate('/subscriptions');
            }

        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }

    const handleCheckoutSession = planTag => {
        const selected = state.plans.find(plan => plan.subscriptionTag === planTag && plan.renewalType === state.frequency.type);
        const checkout = {priceId: selected.priceId, productId: selected.productId};
        getCheckoutSessionNew(checkout).then(data => {
            window.location.replace(data.data);
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleNewCheckoutSession = checkout => {
        getCheckoutSessionNew(checkout).then(data => {
            window.location.replace(data.data);
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleNewPassword = body => {
        handleLoading(true);
        postResetPassword(body).then(() => {
            handleLoading(false, true);
            handleMessage('success', 'Password has been changed! Redirecting to login page');
            setTimeout(() => {
                window.location.href = '/login';
            }, 500);
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        });
    }
    const handleSubscription = paymentIntent => {
        const body = {...state.stripeSubs, paymentId: paymentIntent.id, message: paymentIntent.status, successful: paymentIntent.status === 'succeeded'};
        putSubscription(body).then(res => {
            handleLoading(false);
            handleMessage('success', 'Payment accepted. Redirecting you to continue the registration');
            
            if (user.finishedWizard) {
                setTimeout(() => {
                    navigate('/subscriptions');
                }, 500);
            } else {
                setTimeout(() => {
                    navigate('/digests');
                }, 500);
            }
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleUpgradeMembership = () => {
        if (user.mappedProductName === 'Free') {
            cookies.set('prev', window.location.href);
            navigate('/upgrade-subscription');
        } else {
            portalSession();
        }
    }
    const handleUpgradeSubscription = () => {
        cookies.set('prev', window.location.href);
        navigate('/upgrade-subscription');
    }
    // ACTIVATE ACCOUNT AND ACTIVATE USER
    const handleSetResponseForm = form => {
        dispatch({type: 'SET_ACTIVATION_USER_FORM', payload: form});
    }
    const handleActivateUser = (code) => {
        handleLoading(true);
        const form = {...state.form, activationCode: code, CASLoptin: 'true'};
        postActivateUser(form).then(data => {
            handleMessage('success', 'Account activated!');
            createNewSessionCookies(data.data);
            handleLoading(false, true);
           
            if (user.finishedWizard) {
                setTimeout(() => {
                    navigate('/subscriptions');
                }, 500);
            } else {
                setTimeout(() => {
                    navigate('/digests');
                }, 500);
            }
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleActivateAccount = (code, firm) => {
        handleLoading(true);
        const form = {...state.form, activationCode: code, CASLoptin: 'true', firmName: firm};
            postActivateAccount(form).then(data => {
                handleMessage('success', 'Account activated!');
                createNewSessionCookies(data.data);
                handleLoading(false, true);
               
                if (user.finishedWizard) {
                    setTimeout(() => {
                        navigate('/subscriptions');
                    }, 500);
                } else {
                    setTimeout(() => {
                        navigate('/digests');
                    }, 500);
                }
            }).catch(err => {
                handleLoading(false, true);
                handleMessage('error', err.response.headers.errormessage);
            })
    }
    // --- ACCOUNT PAGE ---
    const handleSetUserInfo = () => {
        dispatch({type: "SET_USER_FORM", payload: user});
        handleLoading(false);
    }
    const handleUpdateAccount = () => {
        let filteredAccount = {};
        inputs.account.map(input => {
            filteredAccount[input] = state.form[input];
            return true;
        })
        handleLoading(true);
        postAccountUpdate(filteredAccount).then(data => {
            handleUser();
        }).then(() => {
            handleLoading(false, true);
            handleMessage('success', 'Account settings updated!');
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleUpdateEmail = () => {
        let filteredAccount = {};
        inputs.email.map(input => {
            filteredAccount[input] = state.form[input];
            return true;
        })
        postEmailUpdate(filteredAccount).then(data => {
            handleMessage('success', 'Account settings updated!');
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleUpdatePassword = () => {
        let filteredAccount = {};
        inputs.password.map(input => {
            filteredAccount[input] = state.form[input];
            return true;
        })
        postPasswordUpdate(filteredAccount).then(data => {
            handleMessage('success', 'Account settings updated!');
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleGetImage = () => {
        getPhotoPresignedUrl(user.pictureURL).then(data => {
            dispatch({type: 'SET_IMAGE_URI', payload: data.data});
        })
    }
    const handleUploadImage = (file, fileName, inputImgURI) => {
        const extension = fileName.split('.');
        const ext = extension[extension.length - 1];
        handleLoading(true);
        getUploadPresignedUrl(ext).then(data => {
            const uploadLink = data.data.upload_link;
            postUploadPhotoS3(uploadLink, file).then(res => {
                postS3Link(data.data.s3_link).then(response => {
                    dispatch({type: 'SET_IMAGE_URI', payload: inputImgURI});
                    handleLoading(false, true);
                    handleMessage('success', 'Profile picture uploaded!');
                }).catch(err => {
                    handleLoading(false, true);
                    handleMessage('error', err.response.headers.errormessage);
                })
            }).catch(err => {
                handleLoading(false);
                handleMessage('error', err.response.headers.errormessage);
            })
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    // --- WIZARD AND CHAPTCHA
    const handleFinishWizard = () => {
        getFinishWizard().then(() => {
            handleUpdateUserData('finishedWizard', true);
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleSetMembership = e => {
        handleLoading(false);
        dispatch({type: 'SET_MEMBERSHIP', payload: e})
    }
    const handleGetPlans = () => {
        getPlans().then(data => {
            const plans = data.data;
            // formatPlans(data.data);
            const editedplans = addLabelPlans(plans);
            dispatch({type: 'SET_PLANS', payload: editedplans});
        }).catch(err => {
            handleMessage('error', err.response.headers.errormessage);
        })
    }

    const handleGetUpgradePlans = () => {
        getPlansToUpgrade().then(data => {
            const plans = data.data;
            const editedplans = addLabelPlans(plans);
            dispatch({type: 'SET_PLANS_RENEW', payload: editedplans});
        }).catch(err => {
            handleMessage('error', err.response.headers.errormessage);
        })
    }

    const handlePlanFrequency = frequency => {
        dispatch({type: 'SET_FREQUENCY', payload: frequency});
    }

    const handlePlanEdition = edition => {
        return;
        //dispatch({type: 'SET_EDITION', payload: edition}); //Temporary
    }

    const handleVerifyCaptcha = () => {
        dispatch({type: 'SET_VALIDATION', payload: {id: 'captcha', value: true}})
    }
    const handleExpiryCaptcha = () => {
        dispatch({type: 'SET_VALIDATION', payload: {id: 'captcha', value: false}});
    }
    
    const handleGetCountries = (continues) => {
        getCountries().then(data => {
            dispatch({type: "SET_COUNTRIES", payload: data.data});
            if (!continues) {
                handleLoading(false);
            }
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleGetStates = country => {
        if (country.target.value.toLowerCase() !== "canada") {
            let countryName = country.target.value.toLowerCase();
            
            if (country.target.value.toLowerCase() !== "united states" &&
             country.target.value.toLowerCase() !== "australia") {
              countryName = "other";
            }
          
            getShippingFee().then(list => {
              let ShippingFee = 0;
              let data = list.data.filter(item => item.countryName == countryName && item.priceName == state.frequency.type);
           
              if (data.length > 0) {
                ShippingFee = data[0].fee_CAD;
              }
              
              dispatch({ type: "SET_SHIPPINGFEE", payload: ShippingFee });
            });
          }
          else{
            dispatch({ type: "SET_SHIPPINGFEE", payload: 0 });
          }

        handleAddressForm(country, 'select');
        getStates(country.target.value).then(data => {
            dispatch({type: "SET_STATES", payload: data.data});
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleAddressForm = (input, type = 'text') => {
       
        if (type === 'checkbox') {
            dispatch({type: 'SET_DIGITAL', payload: input.target.id.toLowerCase() === 'digital'});
        }
        else if (type === 'select') {
            dispatch({type: 'SET_SELECT_INPUT', payload: {form: 'addressForm', value: input.target}});
        }
        else {
            // dispatch({type: 'SET_DIGITAL', payload: input.target.id.toLowerCase() === 'digital'});
            dispatch({type: 'SET_TEXT_INPUT', payload: {form: 'addressForm', value: input.target}});
        }
        const name = input.target.id || input.target.name;
        const validate = handleValidateInput(input.target.value, name);
        if (input.target.value) {
            dispatch({type: 'SET_ADDRESS_VALIDATION', payload: {id: name, value: validate}});
        }
    }
    const handleShippingPreference = () => {
       
          handleLoading(true);
        const body = {
            Address: state.addressForm,
            Copies: state.addressForm.copies,
            Type: state.digital == true? 1: 2
        }
        updateShippingPreference(body).then(data => {
            handleLoading(false, true);
                handleMessage('success', 'Shipping Preference saved!');
            
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })

    }

    const handleShippingPreferenceCache = () => {
        handleLoading(true);
        const body = {
            Address: state.addressForm,
            Copies: state.addressForm.copies,
            Type: 2,
            Interval: state.frequency.type
        }
        updateShippingPreferenceCache(body).then(res => {
           
                getCheckoutSessionNew({priceId: null, productId: null}).then(data => {
                    window.location.replace(data.data);
                }).catch(err => {
                    handleLoading(false, true);
                        handleMessage('error', err.response.headers.errormessage);
                 
                })

                handleLoading(false, true);
                handleMessage('success', 'Shipping address saved!');
            
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })

    }
  
    // --- SUBSCRIPTION PAGE ---
    const handleGetCurrentSubscription = data => {
     
        dispatch({type: 'SET_SUBSCRIPTION_PAGE', payload: data});
        if (user.mappedProductName !== 'Free') {
            dispatch({type: "SET_DIGITAL", payload: user.subscriptionType === 'Digital'});
            
            handleGetCountries(true);
            if(data.selectedProduct != null) handlePlanFrequency({type: data.selectedProduct.renewalType.trim()});
            if(data.shippingFee != null) dispatch({type: "SET_SHIPPINGFEE", payload: data.shippingFee});
            if(data.circulationAddress !=null) dispatch({type: 'SET_ADDRESS_FORM', payload: data.circulationAddress});

            if (data.circulationAddress.address_Country !== "" && user.isRootUser) {
                getStates(data.circulationAddress.address_Country).then(res => { 
                    dispatch({type: "SET_STATES", payload: res.data});
                    handleLoading(false);
                }).catch(err => {
                    handleLoading(false);
                    handleMessage('error', err.response.headers.errormessage);
                });
            } else {
                handleLoading(false);
            }
        } else {
            handleLoading(false);
        }
    }
    const handleClearCurrentSubs = () => {
        handleClearUser();
        dispatch({type: 'SET_SELECTED_PLAN', payload: 'cancel'});
        dispatch({type: 'SET_SUBSCRIPTION_PAGE', payload: ''});
    }
    const portalSession = () => {
     
        getPortalSession().then(data => {
            let a= document.createElement('a');
            a.target= '_blank';
            a.href= data.data;
            a.click();
        })
    }
    // --- GROUP PAGE ---
    const handleGetGroup = () => {
        getGroup().then(data => {
            dispatch({type: 'SET_GROUP', payload: data.data});
            getGroupPrices(data.data.subscription.priceId).then(res => {
                dispatch({type: 'SET_GROUP', payload: {...data.data, groupPrices: res.data}});
            }).then(() => {
                handleLoading(false, true);
            }).catch(err => {
                handleLoading(false, true);
                handleMessage('error', err.response.headers.errormessage);
            })
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleAddLicenses = (amount, close) => {
        handleLoading(true);
        postSeatUpdate(amount).then(() => {
            close();
            dispatch({type: 'SET_GROUP', payload: null});
            handleMessage('success', 'Licenses have been added!');
        }).catch(err => {
            handleLoading(false, true);
            close();
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleAddUser = (user, close) => {
        handleLoading(true);
        user.email = user.email.trim();
        postCreateUser(user).then(() => {
            close();
            dispatch({type: 'SET_GROUP', payload: null});
            handleMessage('success', 'User invitation sent!');
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleDeleteUser = (user, close) => {
        handleLoading(true);
        postDeleteUser(user).then(() => {
            close();
            dispatch({type: 'SET_GROUP', payload: null});
            handleMessage('success', 'Seat has been unassigned. User has been removed from your group.');
        }).catch(err => {
            handleLoading(false, true);
            close();
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleClearCurrentGroup = () => {
        handleClearUser();
        dispatch({type: 'SET_SELECTED_PLAN', payload: 'cancel'});
        dispatch({type: 'SET_SUBSCRIPTION_PAGE', payload: ''});
        dispatch({type: 'SET_GROUP', payload: null});
    }
    const handleCancelGroup = close => {
        close();
        handleLoading(true);
        postCancelSubscription().then(() => {
            handleLoading(false, true);
            handleMessage('success', 'Subscription has been cancelled');
        }).then(() => {
            handleClearCurrentGroup();
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }

    useEffect(() => {
        if (state.plans.length === 0) {
            handleGetPlans();
            handleGetUpgradePlans();
        }
    }, [state]);


    const providerValue = {
        ...state,
        handleGetPlans,
        handleGetUpgradePlans,
        handleGetAccount,
        handleAccountForm,
        handleSetMembership,
        handleCreateAccount,
        handleUpdateAccount,
        handleUpdateEmail,
        handleUpdatePassword,
        handleVerifyCaptcha,
        handleExpiryCaptcha,
        handleSubscription,
        handleGetCountries,
        handleGetStates,
        handleAddressForm,
        handleShippingPreference,
        setPlanInfo,
        handleUpgradeMembership,
        handleFinishWizard,
        handleSetUserInfo,
        handleGetCurrentSubscription,
        handleUpgradeSubscription,
        handleUploadImage,
        handleGetImage,
        handlePlanFrequency,
        handlePlanEdition,
        handleNewPassword,
        portalSession,
        handleCheckoutSession,
        handleGetGroup,
        handleAddLicenses,
        handleAddUser,
        handleDeleteUser,
        handleCancelGroup,
        handleClearCurrentSubs,
        handleSetResponseForm,
        handleActivateUser,
        handleActivateAccount,
        handleError,
        handleNewCheckoutSession,
        handleShippingPreferenceCache
    }

    return (
        <AccountContext.Provider value={providerValue}>
            {!!state.plans.length && 
                <React.Fragment>
                    {children}
                </React.Fragment>
            }
        </AccountContext.Provider>
    )
}

export const useAccount = () => useContext(AccountContext);