import { styled } from '@mui/material'
import React from 'react'
import { useAccount } from '../../features/account/context'
import Checkbox from '../atoms/Checkbox'
import StyledContainer from '../atoms/StyledContainer'
import Text from '../atoms/Text'

const StyledHighlight = styled('span')(() => ({
    fontWeight: 700
}))

export default function PrintDigitalForm({ disablePrint, singleOption, isCheckbox }) {
    const { digital, handleAddressForm, planInfo } = useAccount();
  
    return (
    <StyledContainer>
        {isCheckbox ?
            <React.Fragment>
                {singleOption.includes('Print') ?
                    <Text sx={{color: '#413F3F'}}>Your current <StyledHighlight sx={{textTransform: 'uppercase'}}>{planInfo.readableName}</StyledHighlight> membership includes the <StyledHighlight>Print Edition</StyledHighlight> of <StyledHighlight>The Northern Miner</StyledHighlight> sent to your home or office.</Text>   
                :
                    <Text>Get the <StyledHighlight>Digital Edition</StyledHighlight> of <StyledHighlight>The Northern Miner</StyledHighlight> sent directly to your inbox.</Text>  
                }
            </React.Fragment>
        :
        <React.Fragment>
              <Checkbox checked={digital} onChange={(e) => handleAddressForm(e, 'checkbox')} id='digital' shape='circle' placement='end' label={
                <span>
                    <Text>Get the <StyledHighlight>Digital Edition</StyledHighlight> of <StyledHighlight>The Northern Miner</StyledHighlight> sent directly to your inbox.</Text>
                </span>
            } />
            {!disablePrint &&
                <Checkbox checked={!digital} onChange={(e) => handleAddressForm(e, 'checkbox')} id='print-digital' shape='circle' placement='end' label={
                    <span>
                        <Text sx={{color: disablePrint ? '#817F7F' : '#413F3F'}}>Get the <StyledHighlight>Print Edition</StyledHighlight> of <StyledHighlight>The Northern Miner</StyledHighlight> sent to your home or office at no additional cost (For International subscribers, pay shipping). It's included with your <StyledHighlight sx={{textTransform: 'uppercase'}}>{planInfo.readableName}</StyledHighlight> membership!</Text>
                    </span>
                } />
            }
          
        </React.Fragment>
        }
    </StyledContainer>
  )
}
