import Cookies from "universal-cookie";

const cookies = new Cookies();

export const generateValidCookie = () => {
    const expires = new Date();
    const h = 1;
    expires.setTime(expires.getTime() + (h*60*60*1000));
    cookies.set('valid', 'true', {expires: expires});
}
export const generateTokenCookie = token => {
    cookies.set('token', token);
}
export const createNewSessionCookies = token => {
    generateValidCookie();
    generateTokenCookie(token);
}
export const clearCookies = () => {
    cookies.set('token', undefined);
    cookies.set('valid', undefined);
}