import axios from "axios"
import { authGet, authPost, authPut } from "../../../services/authCalls"

export const getPlans = async () => {
    return await axios.get('/api/start/listed-plan', {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const getPlansToUpgrade = async () => {
    return authGet('/api/start/listed-plan-to-upgrade')
}


export const postSignUp = async account => {
    return await axios.post('/api/sign-up', account, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const postCreateSubs = subs => {
    return authPost('/api/wizard/create-subscription', subs);
}
export const putSubscription = async sub => {
    return authPut('/api/wizard/subscription-tags', sub);
}

export const getShippingFee = async () => {
    return authGet('/api/subscription/shipping-fee');
}

export const getCountries = async () => {
    return authGet('/api/auth/country')
}
export const getStates = async country => {
    return authGet(`/api/auth/province?countryCode=${country}`);
}

export const updateShippingPreferenceCache = async form => {
    return authPost('/api/subscription/shipping-preference-cache', form);
}
export const updateShippingPreference = async form => {
    return authPost('/api/subscription/shipping-preference', form);
}
export const getFinishWizard = async () => {
    return authGet('/api/wizard/finish');
}
export const postAccountUpdate = async form => {
    return authPost('/api/auth/profile', form);
}
export const postEmailUpdate = async form => {
    return authPost('/api/auth/email', form);
}
export const postPasswordUpdate = async form => {
    return authPost('/api/auth/password', form);
}
export const getUploadPresignedUrl = async (ext) => {
    return authGet('/api/auth/upload_photo/presigned_url?ext=' + ext);
}
export const postUploadPhotoS3 = async (url, file) => {
    return await axios.put(url, file, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'crossorigin': true,
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type,Authorization'
        }
    })
}
export const getCurrentSubscription = async () => {
    return authGet('/api/subscription/current');
}
export const postS3Link = async link => {
    return authPost('/api/auth/photo', link);
}
export const getPhotoPresignedUrl = async url => {
    return authGet(`/api/auth/get_photo/presigned_url?s3_link=${url}`);
}
export const getPhotoS3 = async url => {
    return await axios.get(url);
}
export const getCheckoutSessionNew = async body => {
    return authPost(`/api/create-checkout-session-new`, body);
}
export const postSubscriptionStatus = async session => {
    return authPost('/api/subscription-status', session);
}
export const postForgotPassword = async email => {
    return await axios.post(`/api/forgot-password?email=${email}`, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    });
}
export const getPasswordCode = async code => {
    return await axios.get(`/api/forgot-password/validate-authentication-code?code=${code}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export const postResetPassword = async body => {
    return await axios.post('/api/reset-password', body, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    });
}
export const postCancelSubscription = async () => {
    return authPost('/api/subscription/cancel');
}
export const getPortalSession = async () => {
    return authGet('/api/create-portal-session');
}
export const postReviveSubscription = async body => {
    return authPost('/api/subscription/revive-after-cancellation', body);
}
export const getGroup = async () => {
    return authGet('/api/subscription/group');
}
export const postSeatUpdate = async amount => {
    return authPost('/api/subscription/seat-update', amount);
}


export const postCreateUser = async user => {
    return await authPost('/api/auth/create-user', user);
}
export const postDeleteUser = async user => {
    return await authPost(`/api/auth/delete-user?userId=${user}`);
}
export const postActivateUser = async form => {
    return await axios.post('/api/activation-new-user', form, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    })
}
export const postActivateAccount = async form => {
    return await axios.post('/api/activation-new-account', form, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    })
}
export const getGroupPrices = async priceId => {
    return await authGet(`/api/start/listed-prices?priceId=${priceId}`);
}

export const getFailedPayment = async () => {
    return await authGet('/api/price/last-failed-payment');
}


export const getShippingPreferenceCache = async () => {
    return await authGet('/api/subscription/shipping-preference-cache');
}
