import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useApp } from '../AppContext'

export default function Home() {
    const { user } = useApp();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.finishedWizard) {
            navigate('/account');
        } else {
            navigate('/digests');
        }
    }, [])

    return (
        <div></div>
    )
}
