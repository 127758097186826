import { styled } from '@mui/material'
import React from 'react'
import { useApp } from '../../../AppContext'
import { emptyObject } from '../../../lib/emptyObject'
import { useAccount } from '../../features/account/context'
import Button from '../atoms/Button'
import Link from '../atoms/Link'
import StyledContainer from '../atoms/StyledContainer'
import Menu from '../molecules/Menu'

const StyledHeader = styled('header')(() => ({
    backgroundColor: '#201E1E',
    height: '46px',
    padding: '5px 10px',
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '6px',
    boxShadow: '0px 3px 5px 0px #00000026',
    '& .header-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& img': {
        height: '20px',
        marginRight: '9px'
    },
    '& .header-nav': {
        display: 'flex',
        alignItems: 'center'
    }
}))


export default function Header() {
    const { user, handleLogout } = useApp();
    const { portalSession } = useAccount();
    
    const simpleOptions = [
        {link: '/account', text: 'Account', icon: {base: 'fa-light', className: 'fa-user'}},
        {link: '/subscriptions', text: 'Subscriptions', icon: {base: 'fa-light', className: 'fa-arrows-rotate'}}
    ]
    const billingOption = {onClick: portalSession, text: 'Billing', icon: {base: 'fa-light', className: 'fa-wallet'}};
    const groupOption = {link: '/group-membership', text: 'Group', icon: {base: 'fa-light', className: 'fa-users'}, sx: {width: '20px'}};
    const logoutOption = {onClick: handleLogout, text: 'Sign Out', icon: {base: 'fa-light', className: 'fa-arrow-right-from-bracket'}};

    const options = {
        freeOptions : [...simpleOptions, logoutOption],
        migratedOptions : [...simpleOptions, groupOption, logoutOption],
        rootOptions : [...simpleOptions, billingOption, groupOption, logoutOption],
        defaultOptions : [...simpleOptions, billingOption, logoutOption]
    }

  return (
    <div>
        <StyledHeader>
            <div className='header-nav'>
                <Link exact link='/' type='img hd'>
                    <img
                        alt='Northern Miner Group logo'
                        src='/images/Membership-Logo.png'
                    />
                </Link>
            </div>
            <StyledContainer sx={{display: 'flex', alignItems: 'baseline'}}>
                <Menu
                    menuIcon={{base: 'fa-solid', className: 'fa-grid', color: '#FFFFFF', sx: {width: '23px', height: '24px', mt: '-1px'}}}
                    options={[
                        {link: 'https://www.northernminer.com/', external: true, text: 'The Northern Miner', icon: {base: 'fa-solid', className: 'fa-newspaper'}},
                        {link: 'https://www.mining.com/', external: true, text: 'MINING.COM', icon: {base: 'fa-solid', className: 'fa-map'}},
                        {link: 'https://www.canadianminingjournal.com/', external: true, text: 'Canadian Mining Journal', icon: {base: 'fa-solid', className: 'fa-table'}},
                        {link: 'https://marcopoloapp.tnm.global/', external: true, text: 'TNM Marco Polo', icon: {base: 'fa-solid', className: 'fa-newspaper'}},
                        {link: 'https://www.mining.com/markets/', external: true, text: 'MINING.COM Markets', icon: {base: 'fa-solid', className: 'fa-map'}},
                        {link: 'https://buyersguide.mining.com/', external: true, text: "MINING.COM Buyer's Guide", icon: {base: 'fa-solid', className: 'fa-table'}},
                        {link: 'https://events.northernminer.com/', external: true, text: 'TNM Symposiums', icon: {base: 'fa-solid', className: 'fa-newspaper'}},
                        {link: '/', text: 'TNM Membership', icon: {base: 'fa-solid', className: 'fa-table'}},
                    ]}
                    // type='grid'
                />
                {emptyObject(user) ? (
                    <Link link='/login' type='header' sx={{ml: '10px'}}>Login</Link>
                ) : (
                    <Menu
                        user={user}
                        menuIcon={{base: 'fa-solid', className: 'fa-circle-user header-nav', color: '#FFFFFF', sx: {fontSize: '22px', height: '23px', pt: '1px', width: '23px'}}}
                        options={
                            user.mappedProductName === 'Free' || user.isComplimentaryUser || !user.currentSubscriptionId ? options.freeOptions
                            : (!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing') || user.currentSubscriptionId.includes('manual')) ? options.migratedOptions
                            : user.isRootUser ? options.rootOptions
                            : options.freeOptions
                        }
                    />
                )}
            </StyledContainer>
        </StyledHeader>
    </div>
  )
}
