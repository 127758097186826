import React from 'react';
import { Card as MUICard } from '@mui/material';
import { styled } from '@mui/material/styles'

const StyledCard = styled(MUICard)(({theme}) => ({
  backgroundColor: '#FFF',
  boxShadow: '0px 3px 5px 0px #00000026',
  borderRadius: '10px',
  margin: '20px',
  padding: '20px',
  '&.paddless': {
    padding: 0
  },
  '&.description': {
    backgroundColor: '#ECEBEB'
  }
}))

export default function Card(props) {
  return (
    <StyledCard {...props} className={`${props.type} ${props.className}`}>
      {props.children}
    </StyledCard>
  )
}
