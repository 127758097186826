import React from 'react';
import { useApp } from '../../../AppContext';
import { useDigest } from '../../features/digests/context';
import Text from '../atoms/Text';
import CollapseBoxes from '../molecules/CollapseBoxes';

export default function Newsletter({saved = false}) {
    const { newsletters, handleSetNewsletter, handlePostNewsletterSubs } = useDigest();
    const { user } = useApp();
    return (
        <React.Fragment>
            {!newsletters.length ? 
                <Text>Loading...</Text>
            : 
                <CollapseBoxes saved={saved} handleSave={handlePostNewsletterSubs} subscriptionTag={user.subscriptionTag} lists={newsletters} handleCheckbox={handleSetNewsletter} />
            }
        </React.Fragment>
    )
}
