import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useApp } from '../../AppContext';
import { useAccount } from '../../components/features/account/context';
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import Text from '../../components/UI/atoms/Text';
import Card from '../../components/UI/molecules/Card';
import { verifyAccount } from '../../services/authMiddleware';
import ActivateAccount from '../GetStarted/ActivateAccount';

export default function VerifyAccount() {
    const [ searchParams ] = useSearchParams();
    const [ valid, setValid ] = useState(null);
    const [ response, setResponse ] = useState(null);
    const { handleLoading, handleMessage } = useApp();
    const { handleSetResponseForm } = useAccount();
    const code = searchParams.get('code');

    useEffect(() => {
        verifyAccount(code).then(data => {
            setValid(true);
            setResponse(data.data);
            handleSetResponseForm(data.data);
            handleLoading(false);
        }).catch(err => {
            setValid(false);
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }, []);

    if (valid) {
        return (
            <ActivateAccount code={code} response={response} />
        )
    } else if (valid === false){
        return (
            <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
                <Card sx={{maxWidth: '400px'}}>
                    <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Your activation code has failed. Please reach out to the Customer Service team for help.</Text>
                </Card>
            </StyledContainer>
        )
    } else {
        return (
            <div></div>
        )
    }
}
