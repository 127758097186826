import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useApp } from '../../AppContext'
import { useAccount } from '../features/account/context';
import useToken from '../features/token/useToken';
import Loading from '../UI/atoms/Loading';
import { emptyObject } from '../../lib/emptyObject';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function AuthWrapper() {

    const { user, isLoadingAuth, handleUser, handleMessage } = useApp();
    const { setPlanInfo, planInfo, handleGetImage } = useAccount();
    const { handleToken, doesTokenExist } = useToken();
    const location = useLocation();

    useEffect(() => {
        handleToken();
    }, []);

    useEffect(() => {
        if (!emptyObject(user) && (!planInfo || planInfo === 'cancel')) {
            setPlanInfo();
        }
        if (emptyObject(user) && planInfo === 'cancel') {
            handleUser();
        }
        if (!emptyObject(user) && user.pictureURL) {
            handleGetImage();
        }
        if (!emptyObject(user) && planInfo && doesTokenExist) {
            if (cookies.get('success')) {
                handleMessage('success', 'Payment Complete!');
                cookies.remove('success', {path: '/'});
            }
        }
    }, [user])
    
    if (!doesTokenExist) {
        return (
            <Navigate to='login' state={{from: location}} replace />
        )
    }
    if (isLoadingAuth) {
        return (
            <Loading />
        )
    }
    else if (!emptyObject(user) && planInfo && doesTokenExist) {
        return (
            <Outlet />
        )
    }
}
