import React from 'react'
import { useApp } from '../../AppContext'
import Snackbar from '../UI/atoms/Snackbar'
import Theme from '../UI/theme/customTheme'
import Footer from '../UI/organisms/Footer'
import Header from '../UI/organisms/Header'
import Loading from '../UI/atoms/Loading'

export default function Layout({children}) {
  const { isLoading } = useApp();
  return (
    <Theme>
        {isLoading && <Loading />}
        <Header />
        <div className='page-content'>{children}</div>
        <Footer />
        <Snackbar />
    </Theme>
  )
}
