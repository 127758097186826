import { Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../atoms/Button'
import Link from '../atoms/Link'
import Text from '../atoms/Text'

export default function ResetPasswordModal({open, handleClose}) {
    const navigate = useNavigate();

    const navigateToReset = () => {
        handleClose();
        navigate('/forgot-password');
      }

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{sx: {borderRadius: '10px'} }} >
            <DialogTitle sx={{textAlign: 'center'}}>Action Required</DialogTitle>
            <DialogContent>
            <Text>Please reset your password to update your account.</Text>
            <Link external link='#' openTab sx={{fontSize: '0.875rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>Learn More <Icon baseClassName='fa-light' className='fa-circle-info' sx={{fontSize: '0.75rem', marginLeft: '3px', width: '13px', height: '13px', pt: '1px'}} /></Link>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => navigateToReset()}>Reset password</Button>
            </DialogActions>
        </Dialog>
    )
}
