export const dateDifference = (date, when = 'ahead') => {
    if (when === 'ahead') {
        return Math.ceil(Math.abs(new Date(date) - new Date()) / (1000 * 60 * 60 * 24));
    } else if (when === 'refundPeriod') {
        const refundPeriodDays = 14;
        const refundEndDate = new Date(new Date().getTime()+(refundPeriodDays*24*60*60*1000));
        return new Date() <= (refundEndDate);
    }
}
export const isDateAhead = (date) => {
    return new Date(date) >= new Date();
}