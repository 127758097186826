import React from 'react';
import { Grid } from '@mui/material';
import Button from '../atoms/Button';
import Divider from '../atoms/Divider';
import { useAccount } from '../../features/account/context';

export default function SubscriptionActions({user, currentSubscription, handleClickOpen}) {
    const { portalSession } = useAccount();
    const today = new Date();
    
    // READABLE USER AND SUBSCRIPTION FLAGS
    const isFreeSubscription = user.mappedProductName === 'Free' && !user.isComplimentaryUser;

    const isPaidSubscription = !user.isComplimentaryUser && currentSubscription.subscription && user.mappedProductName !== 'Free' && user.currentSubscriptionId && !user.currentSubscriptionId.includes('manual');
    
    const isTrialUser = isPaidSubscription &&
        currentSubscription.subscription.trialStartDate && 
        currentSubscription.subscription.trialEndDate;
    
    const isAdmin = user.isRootUser;
    
    const isExistingUserPastSubs = user.isMigrated && (!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing'));

    const isUserExpired = isPaidSubscription ?
        today > new Date(currentSubscription.subscription.renewalDate) :
        false;

    const isExistingUserExpired = isExistingUserPastSubs && 
        isUserExpired;
    
    const isSubscriptionActive = isPaidSubscription &&
        currentSubscription.subscription.active;

    const isUserRefunded = isPaidSubscription &&
        currentSubscription.subscription.refundId;

    const isPaymentFalied = user.lastPaymentFailed;

    // FLAGS FOR SUBSCTIPTION ACTIONS

    const canCancel = () => {
        return isAdmin &&
            isPaidSubscription &&
            currentSubscription.subscription.active &&
            !isExistingUserExpired &&
            !isFreeSubscription;
    }
    const canUpgrade = () => {
        return isAdmin &&
            isPaidSubscription &&
            !currentSubscription.subscription.stripeCancelled &&
            !user.isComplimentaryUser &&
            !isExistingUserExpired &&
            !isTrialUser;
    }
    const canUpgradeFree = () => {
        return isFreeSubscription &&
            !isExistingUserPastSubs &&
            (!isPaidSubscription || !isTrialUser);
    }
    // for migrated users
    const canCreateSubssription = () => {
        return isExistingUserPastSubs;
    }
    const canRenew = () => {
        if (!isExistingUserExpired) {
            if (isPaidSubscription && currentSubscription.subscription.trialStartDate && !isSubscriptionActive) {
                if (isUserRefunded) {
                    return false;
                } else if (!currentSubscription.subscription.trialEndDate) {
                    return true;
                } else if (!isUserExpired) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const displayActions = canUpgrade() ||
        canCancel() ||
        canRenew() || 
        canUpgradeFree() ||
        canCreateSubssription();

    return (
        <React.Fragment>
            {(displayActions) && (
                <React.Fragment>
                    <Divider />
                    <Grid container spacing={2}>
                        {canUpgradeFree() &&
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                <Button onClick={() => handleClickOpen('upgrade')}>Upgrade Subscription</Button>
                            </Grid>
                        }
                        {canUpgrade() && 
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                <Button disabled={isExistingUserPastSubs} onClick={portalSession}>Update Subscription</Button>
                            </Grid>
                        }
                        {canCancel() &&
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                <Button disabled={isExistingUserPastSubs} onClick={() => handleClickOpen('cancel')} type='outlined'>Cancel Subscription</Button>
                            </Grid>
                        }
                        {canRenew() &&
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                <Button disabled={isExistingUserPastSubs} onClick={() => handleClickOpen('renew')} type='outlined'>Renew Subscription</Button>
                            </Grid>
                        }
                        {canCreateSubssription() &&
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                <Button onClick={() => handleClickOpen('upgrade')}>Create New Subscription</Button>
                            </Grid>
                        }
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
