import React from 'react';
import { Divider as MUIDivider, Icon } from '@mui/material';
import StyledContainer from './StyledContainer';

export default function Divider({sansMargin, plan}) {
  if (plan) {
    return (
      <StyledContainer sx={{position: 'relative', margin: '15px 0', display: 'flex', justifyContent: 'center'}}>
        <Icon sx={{fontSize: '20px', color: '#AB9F60', backgroundColor: '#fff', zIndex: '2', padding: '1px 10px', width: '43px', height: '21px'}} baseClassName='fa-regular' className='fa-circle-plus' />
        <MUIDivider sx={{position: 'absolute', left: 0, right: 0, bottom: '9px', borderColor: '#413F3F', maxWidth: '161px', margin: 'auto'}} />
      </StyledContainer>
    )

  } else {
    return (
      <MUIDivider sx={{my: sansMargin ? 0 : '20px', mx: '-20px'}} />
    )
  }
}
