import React from 'react';
import { Button as MUIButton, Icon, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(MUIButton)(({ theme }) => ({
    '&.MuiButton-contained': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        fontWeight: '400',
        lineHeight: '17px',
        fontSize: '0.875rem',
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
        width: '100%',
        padding: '13px 15px',
        '&.icon-btn': {
            justifyContent: 'space-between',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none'
        },
        '&:active': {
            backgroundColor: theme.palette.primary.dark
        },
        '&:disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: '0.4',
            color: '#FFF'
        },
        '&.step-nav': {
            fontSize: '0.75rem',
            lineHeight: '15px',
            maxWidth: '130px',
            padding: '9px 10px',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            '& .MuiIcon-root': {
                fontSize: '11px'
            },
            '& p': {
                flexGrow: 1,
                color: '#FFF',
                fontSize: '0.75rem',
                lineHeight: '11px'
            },
            '& .MuiButton-startIcon': {
                margin: 0,
                marginRight: '2px'
            },
            '& .MuiButton-endIcon': {
                margin: 0,
                marginLeft: '2px'
            }
        },
        '&.header': {
            fontSize: '0.75rem',
            lineHeight: '15px',
            maxWidth: '130px',
            height: 'fit-content',
            padding: '6px 15px',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    },
    '&.MuiButton-outlined': {
        borderColor: '#413F3F',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: '#413F3F',
        fontWeight: '400',
        width: '100%',
        padding: '9px 15px',
        fontSize: '0.875rem',
        '&.icon-btn': {
            justifyContent: 'space-between',
        },
        '&:hover': {
            borderWidth: '2px',
            padding: '8px 15px'
        },
        '&:active': {
            backgroundColor: theme.palette.primary.main
        },
        '&:disabled': {
            borderColor: '#413F3F',
            color: '#413F3F',
            opacity: '0.3'
        }
    },
    '&.step-btn': {
        backgroundColor: '#ECEBEB',
        border: '1px solid #B5B4B4',
        color: '#413F3F',
        padding: '8px',
        textShadow: 'none',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#D9D9D9'
        }
    },
    '&.MuiButton-text': {
        color: '#B5B4B4',
        padding: '10px 0',
        width: '100%'
    },
    '&.assign-user-btn': {
        backgroundColor: '#ECEBEB',
        color: '#413F3F'
    },
    '&.edit-seat': {
        padding: '0 8px',
        fontSize: '1.6875rem',
        width: '33px',
        minWidth: '33px',
        height: '33px',
        '&.plus': {
            borderRadius: '0'
        },
        '&.minus': {
            borderRadius: '0 3px 3px 0',
            paddingBottom: '4px'
        }
    },
    '&.user-icon': {
        backgroundColor: 'transparent',
        marginLeft: '5px',
        padding: '5.5px',
        minWidth: '0',
        width: '35px',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: '#5B5959'
        },
        '& img': {
            margin: 0,
            borderRadius: '50%',
            height: '24px'
        }
    },
    '&.submit-disable': {
        pointerEvents: 'initial',
        backgroundColor: theme.palette.primary.main,
        opacity: '0.4',
        color: '#FFF',
        cursor: 'default',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            
        }
    },
    '&.text-link': {
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        textTransform: 'initial',
        justifyContent: 'flex-start',
        padding: 0,
        width: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#736324'
        }
    },
    '&.text-message': {
        color: '#2D70DA',
        fontSize: '1rem',
        marginLeft: '5px',
        lineHeight: '1.5',
        marginTop: '-4px',
        backgroundColor: 'transparent',
        textTransform: 'initial',
        display: 'inline',
        padding: 0,
        width: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#1542BF'
        }
    }
}))

const StyledIcon = styled(IconButton, {
        shouldForwardProp: prop => prop !== 'tooltip',
    })(({ tooltip }) => ({
        color: '#FFF',
    ...(tooltip && {
        '&::before': {
            content: `'${tooltip}'`,
            position: 'absolute',
            left: -110,
            opacity: 0,
            color: '#000000',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
        },
        '&:hover::before': {
            opacity: 1
        }
    }),
    '&:hover .fa-trash-can': {
        color: '#D02906'
    },
    '&.nav': {
        borderRadius: '3px',
        padding: '6px',
        '&:last-of-type': {
            marginLeft: '5px'
        }
    },
    '&.nav:hover, &.nav.open-menu': {
        backgroundColor: '#5B5959'
    },
    '&.carousel': {
        padding: 0,
        '& .MuiIcon-root': {
            fontSize: '2rem',
            width: '33px',
            height: '33px',
            paddingTop: '1px'
        }
    }
}))

export default function Button(props) {
    const { type = 'contained', htmlType='button', children, onClick, icon, className, ...other } = props;

    if (type === 'icon') {
        return (
          <StyledIcon className={props.placement} size={props.size} tooltip={props.tooltip} onClick={(e) => onClick(e)}>
            <Icon {...other} className={className} sx={{ ...props.sx, color: props.color }} fontSize={props.size} />
          </StyledIcon>
        )
    }
    else {
        return (
            <StyledButton
                {...other}
                type={htmlType}
                className={`${className} ${icon && 'icon-btn'}`}
                onClick={(e) => onClick(e)}
                variant={type}
                startIcon={(icon && icon.start) &&
                    <Icon baseClassName={icon.base} className={icon.type} />
                }
                endIcon={(icon && icon.end) &&
                    <Icon baseClassName={icon.base} className={icon.type} />
                }
            >
                {children}
            </StyledButton>
        )
    } 
}

