import React, { useEffect } from 'react';

import { useDigest } from '../../components/features/digests/context';
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import Text from '../../components/UI/atoms/Text';
import Card from '../../components/UI/molecules/Card';
import Newsletter from '../../components/UI/organisms/Newsletter';

export default function Newsletters() {
    const { handleWizardDigNews, newsletters } = useDigest();

    useEffect(() => {
        if (!newsletters.length) {
            handleWizardDigNews();
        }
    }, [newsletters]);

    return (
        <StyledContainer>
            <Text type='h1' sx={{textAlign: 'left'}}>Get Started</Text>
            <Card  type='description' sx={{margin: '20px 0'}}>
                <Text sx={{fontWeight: '700', fontSize: '1.125rem', lineHeight: '22px', textAlign: {xs: 'center', sm: 'center', md: 'left'}, textTransform: 'uppercase', marginBottom: '20px'}}>We've got You Covered</Text>
                <Text>Every mining professional knows, you're only as good as your tools. Sign up for our product newsletters to learn about the latest releases across The Northern Miner Group.</Text>
            </Card>
            <Newsletter />
        </StyledContainer>
    )
}
