import digestsData from '../../data/digests.json';
import newslettersData from '../../data/newsletters.json';

export const mapDigestNewsletter = (lists, selectedLists) => {
    const newsletters = newslettersData.data;
    const selectedNews = selectedLists.newsletterPreferences;
    
    newsletters.map(dataNews => {
        dataNews.digests = dataNews.digests.map(list => {
            const newsletter = lists.newsletterLookup.find(ls => ls.newsletter === list.name);
            const id = Object.keys(newsletter).find(key => key.includes('Id'));
            list[id] = newsletter[id];
            list.selected = selectedNews.find(ns => ns.listId === list.listId).associate;
            return list;
        })
        return dataNews;
    })

    const digests = digestsData.data;
    const selectedDigests = selectedLists.digestPreferences;
    digests.map(dataDigest => {
        dataDigest.digests = dataDigest.digests.map(list => {
            const digest = lists.newsletterLookup.find(ls => ls.newsletter === list.name) || lists.digestLookup.find(ls => ls.digestName.split("\r")[0] === list.name);
            const id = Object.keys(digest).find(key => key.includes('Id'));
            list[id] = digest[id].split("\r")[0];
            if (list.listId) {
                list.selected = selectedNews.find(dg => dg.listId === list.listId).associate;
            } else {
                list.selected = selectedDigests.find(dg => dg.customFieldAPIName === list.digestId).customFieldValue === 'on';
            }
            return list;
        })
        return dataDigest;
    })

    return {newsletters, digests}
}