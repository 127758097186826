import React, { useEffect, useState } from 'react';
import { CardContent, CardMedia } from '@mui/material';
import StyledContainer from '../atoms/StyledContainer';
import Text from '../atoms/Text';
import Card from './Card';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Button from '../atoms/Button';
import Link from '../atoms/Link';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    975: { items: 3 },
    1270: { items: 4 }
};

const thumbItems = (items, publication) => {
    return items.map((item, i) => (
        <Card type='paddless' key={i} sx={{margin: '0 10px', height: '100%'}}>
            <CardMedia component='img' height='140' image={item.image} />
            <CardContent>
                <Text sx={{fontSize: '0.75rem', fontWeight: 700, textTransform: 'uppercase', color: '#817F7F', lineHeight: '14px', marginBottom: '5px'}}>{publication.title}</Text>
                <Link external openTab link={item.link}>
                    <Text sx={{fontSize: '0.875rem', fontWeight: 700, textTransform: 'uppercase', color: '#413F3F'}}>{item.title}</Text>
                </Link>
                <StyledContainer sx={{display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                    <Text sx={{fontSize: '0.75rem'}}>{item.pubDate}</Text>
                    <Text sx={{fontSize: '0.75rem'}}>{item['dc:creator']}</Text>
                </StyledContainer>
            </CardContent>
        </Card>
    ));
};

export default function Feed({rssUrl, pubName}) {
    const [ feed, setFeed ] = useState({publication: "", news: []});

    const [mainIndex, setMainIndex] = useState(0);
    const [mainAnimation, setMainAnimation] = useState(false);
    const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);
    const [thumbs, setThumbs] = useState([]);

    const getRss  = async () => {
        const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
        if (!urlRegex.test(rssUrl)) {
            return;
        }
        const res = await fetch(`https://api.allorigins.win/get?url=${rssUrl}`);
        const { contents } = await res.json();
        const feed = new window.DOMParser().parseFromString(contents, "text/xml");
        const publication = {title: pubName, link: rssUrl.split("/")[2]};
        const items = feed.querySelectorAll("item");
        const feedItems = [...items].filter((e, index) => index < 5).map((el) => {
            const tags = el.getElementsByTagName("*");
            let item = {};
            [...tags].map(tag => {
                if (tag.nodeName === 'pubDate') {
                    const date = new Date(tag.innerHTML);
                    item[tag.nodeName] = date.toLocaleDateString("en-US", {month: 'long', day: 'numeric', year: 'numeric'})
                }
                else {
                    item[tag.nodeName] = tag.innerHTML;
                }
                return;
            })
            return item;
        });
        setFeed({publication: publication, news: feedItems});
        setThumbs(thumbItems(feedItems, publication));
    }

    useEffect(() => {
        getRss(rssUrl);
    }, [rssUrl]);

    const slideNext = () => {
        if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex + 1);
        }
    };

    const slidePrev = () => {
        if (!thumbAnimation && thumbIndex > 0) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex - 1);
        }
    };

    const syncThumbs = (e) => {
        setThumbIndex(e.item);
        setThumbAnimation(false);

        if (!mainAnimation) {
            setMainIndex(e.item);
        }
    };

    return (
        <StyledContainer sx={{marginBottom: '60px'}}>
            {!feed.news.length ? 
                <Text>Loading...</Text>
            : (
                <div className="thumbs">
                    <AliceCarousel
                        responsive={responsive}
                        activeIndex={thumbIndex}
                        disableDotsControls
                        disableButtonsControls
                        items={thumbs}
                        mouseTracking={false}
                        onSlideChanged={syncThumbs}
                        touchTracking={!mainAnimation}
                        paddingLeft={32}
                        paddingRight={32}
                    />
                    <div className="carousel-btn btn-prev">
                        <Button
                            type='icon'
                            onClick={slidePrev}
                            placement='carousel'
                            baseClassName='fa-solid'
                            className='fa-circle-chevron-left'
                            color='#201E1E'
                        />
                    </div>
                    <div className="carousel-btn btn-next">
                        <Button
                            type='icon'
                            onClick={slideNext}
                            placement='carousel'
                            baseClassName='fa-solid'
                            className='fa-circle-chevron-right'
                            color='#201E1E'
                        />
                    </div>
                </div>
            )}
        </StyledContainer>
    )
}
