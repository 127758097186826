import { CardActions, CardContent, Grid, Icon, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useApp } from '../../AppContext'
import { useAccount } from '../../components/features/account/context'
import Button from '../../components/UI/atoms/Button'
import Link from '../../components/UI/atoms/Link'
import StyledContainer from '../../components/UI/atoms/StyledContainer'
import Text from '../../components/UI/atoms/Text'
import Card from '../../components/UI/molecules/Card'
import { getCurrentSubscription } from '../../components/features/account/middleware'

const StyledLogo = styled('img')(() => ({
    display: 'block',
    marginBottom: '10px',
    width: '100%'
}))

export default function Data() {
    const { user, handleLoading } = useApp();
    const { handleUpgradeMembership } = useAccount();
    const [ subscription, setSubscription ] = useState(null);
    const canUpgrade = user.isRootUser &&
        user.mappedProductName !== 'NewsData' &&
        !user.isComplimentaryUser &&
        subscription && subscription.subscription  &&
        // && !subscription.subscription.trialEndDate can trial users upgrade?
        (!user.currentSubscriptionId.includes('manual') || !user.currentSubscriptionId.includes('existing'));

    useEffect(() => {
        getCurrentSubscription().then(data => {
            setSubscription(data.data);
        }).then(() => {
            handleLoading(false);
        })
    }, []);

  return (
    <StyledContainer sx={{marginBottom: '20px'}}>
        <Text sx={{fontWeight: '700', fontSize: '1.125rem', lineHeight: '22px', textAlign: {xs: 'center', sm: 'center', md: 'left'}, textTransform: 'uppercase'}}>Get Started</Text>
        <Card type='description' sx={{margin: '20px 0'}}>
            <Text sx={{fontWeight: '700', fontSize: '1.125rem', lineHeight: '22px', textAlign: {xs: 'center', sm: 'center', md: 'left'}, textTransform: 'uppercase', marginBottom: '20px'}}>Get the Competitive Advantage</Text>
            <Text>The Norther Miner Group has several data products that can give you the edge over your competition with valuable insights into the industry.</Text>
        </Card>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <StyledContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <StyledLogo sx={{maxWidth: '195px'}} alt='Marco Polo logo' src='/images/marco-polo-logo.png' />
                    {canUpgrade && <Text sx={{fontSize: '1.125rem', fontWeight: 700, color: 'success.main'}}>+$500</Text>}
                </StyledContainer>
                <StyledLogo sx={{maxWidth: '350px', margin: 'auto'}} alt='Marco Polo demo in laptop' src='/images/marco-polo-desktop.png' />
                <Text sx={{marginBottom: '20px'}}>Upgrade your membership to get access to Marco Polo's global database of mining companies and properties, as well as early access and exclusive invitations to our events, and a 5% discount on select TNM Group products.</Text>
                {canUpgrade && <Button onClick={() => handleUpgradeMembership()} sx={{marginBottom: '10px'}}>Upgrade to NEWS + DATA</Button>}
                <Link external type='outlined' link ='https://marcopolo.tnm.global/' openTab>Learn More<Icon baseClassName='fa-light' className='fa-arrow-up-right-from-square' /></Link>
            </Grid>
            
        </Grid>
    </StyledContainer>
  )
}
