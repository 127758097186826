import React, { useEffect, useState } from 'react';
import { Box, Grid, Icon, MenuItem, styled } from '@mui/material';
import Text from '../components/UI/atoms/Text';
import Card from '../components/UI/molecules/Card';
import StyledContainer from '../components/UI/atoms/StyledContainer';
import Button from '../components/UI/atoms/Button';
import Input from '../components/UI/atoms/Input';
import { useAccount } from '../components/features/account/context';
import Divider from '../components/UI/atoms/Divider';
import { validateSegmentedInputsFilled } from '../lib/validateInputsFilled';
import data from '../data/industries.json';
import helperText from '../data/validationMessage.json';
import { useApp } from '../AppContext';
import { getPhotoPresignedUrl } from '../components/features/account/middleware';

const StyledHeader = ({children}) => {
    return (
        <Text sx={{fontSize: '0.875rem', fontWeight: 700, marginBottom: '20px', textTransform: 'uppercase'}}>{children}</Text>
    )
}

const StyledPhotoInput = styled('label', {shouldForwardProp: prop => prop !== 'filled',
})(({filled, theme}) => ({
    width: '100%',
    border: '1px solid #413F3F',
    borderRadius: '3px',
    padding: '9px 15px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.875rem',
    color: '#413F3F',
    fontFamily: 'Montserrat,sans-serif',
    marginBottom: '10px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '& input': {
        display: 'none'
    },
    ...(filled && {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderColor: 'transparent'
      }),
}))

export default function Account() {
    const { form, formValidation, imgURI, handleAccountForm, handleUpdateAccount, handleUpdatePassword, handleSetUserInfo, handleUploadImage } = useAccount();
    const { user } = useApp();
    const [ disableSave, setDisableSave ] = useState({account: false, email: true, pasword: true});
    const [selectedFile, setSelectedFile] = useState(null);
    const [ photoLabel, setPhotoLabel ] = useState('Choose image');
    const [ inputImgURI, setInputImgURI ] = useState(imgURI);

    const handleFileInput = (e) => {
        if (e.target.files.length !== 0) {
            setSelectedFile(e.target.files[0]);
            const fileName = e.target.value.split( "\\" );
            setPhotoLabel(fileName.pop());
            const reader = new FileReader();
            reader.onload = () => {
                setInputImgURI(reader.result);
            }
            reader.readAsDataURL(e.target.files[0]);
        } else {
            setSelectedFile(null);
            setPhotoLabel('Choose image');
        }
    }

    useEffect(() => {
        handleSetUserInfo();
    }, [user]);

    useEffect(() => {
        setInputImgURI(imgURI);
    }, [imgURI]);

    useEffect(() => {
        setDisableSave(validateSegmentedInputsFilled(form, formValidation))
    }, [form]);

  return (
    <Grid container spacing={2} sx={{maxWidth: '1000px'}}>
        <Grid item xs={12} sm={6} md={6}  >
            <Text type='h1'sx={{textAlign: 'left'}}>Profile</Text>
            <Card sx={{margin: 0, marginTop: '20px'}}>
                <StyledHeader>Profile Picture</StyledHeader>
                <StyledContainer sx={{display: 'flex', alignItems: 'flex-end', marginBottom: '20px'}}>
                    <StyledContainer sx={{minWidth: '100px', height: '100px', borderRadius: '50%', overflow: 'hidden'}}>
                        {inputImgURI ?
                            <img src={inputImgURI} height='100%' />
                        :
                            <Icon baseClassName='fa-solid' className='fa-circle-user' sx={{fontSize: '6.25rem'}} />
                        }
                    </StyledContainer>
                    <StyledContainer sx={{width: '100%', ml: '20px'}}>
                        <StyledPhotoInput filled={selectedFile} htmlFor="file" className='labelFile'>{photoLabel}
                            <input accept="image/png, image/gif, image/jpeg, image/jpg" type="file" name="file" id="file" className="inputFile" onChange={handleFileInput} />
                        </StyledPhotoInput>
                        <Button disabled={!selectedFile} type='outlined' icon={{end: true, base: 'fa-light', type: 'fa-up-from-bracket'}} sx={{height: 'fit-content'}} onClick={() => handleUploadImage(selectedFile, photoLabel, inputImgURI)}>Upload</Button>
                    </StyledContainer>
                </StyledContainer>
                <StyledHeader>Profile Information</StyledHeader>
                <Box component='form'>
                    <Input error={!formValidation.firstName} helperText={!formValidation.firstName && helperText.data.firsName} label='first name' id='firstName' value={form.firstName} onChange={(e) => handleAccountForm(e)} />
                    <Input error={!formValidation.lastName} helperText={ !formValidation.lastName && helperText.data.lastName} label='last name' id='lastName' value={form.lastName}  onChange={(e) => handleAccountForm(e)} />
                    <Input error={!formValidation.jobTitle} helperText={ !formValidation.jobTitle && helperText.data.jobTitle} label='job title' id='jobTitle' value={form.jobTitle} onChange={(e) => handleAccountForm(e)} />
                    <Input disabled={!user.isRootUser} error={!formValidation.companyName} helperText={ !formValidation.companyName && helperText.data.companyName} label='company name' id='companyName' value={form.companyName} onChange={(e) => handleAccountForm(e)} />
                    <Input error={!formValidation.industry} helperText={ !formValidation.industry && helperText.data.industry}  label='industry' name='industry' select value={form.industry}  onChange={(e) => handleAccountForm(e, 'select')} >
                        {data.industries.map((industry, key) => (
                            <MenuItem key={key} name='industry' value={industry}>{industry}</MenuItem>
                        ))}
                    </Input>
                    <Button disabled={disableSave.account} onClick={() => handleUpdateAccount()}>Update</Button>
                </Box>
            </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}  >
            <Text type='h1'sx={{textAlign: 'left'}}>Account</Text>
            <Card sx={{margin: 0, marginTop: '20px'}}>
                <StyledHeader>Email</StyledHeader>
                <Box>
                    <Text>{user && user.email}</Text>
                </Box>
                <Divider />
                <StyledHeader>Password</StyledHeader>
                <Box component='form'>
                    <Input passwordInput error={!formValidation.previousPassword} helperText={ !formValidation.previousPassword && helperText.data.previousPassword} label='Previous Password' id='previousPassword' value={form.previousPassword} onChange={(e) => handleAccountForm(e)}/>
                    <Input passwordInput error={!formValidation.password} helperText={ !formValidation.password && helperText.data.password} label='password' id='password' value={form.password} onChange={(e) => handleAccountForm(e)}/>
                    <Input passwordInput error={!formValidation.confirmPassword} helperText={ !formValidation.confirmPassword && helperText.data.confirmPassword} label='confirm password' id='confirmPassword' value={form.confirmPassword} onChange={(e) => handleAccountForm(e)}/>
                    <Button disabled={disableSave.password} onClick={() => handleUpdatePassword()}>Update</Button>
                </Box>
            </Card>
        </Grid>
    </Grid>
  )
}
