import React, { createContext, useContext, useReducer } from 'react';
import { digestReducer } from './reducer';
import { getDigestNewsletter, getWizardDigNews, postWizardDigest, postWizardNewsletter } from './middleware';
import { mapDigestNewsletter } from '../../../lib/mapDigestNewsletter';
import { useAccount } from '../account/context';
import { useApp } from '../../../AppContext';
import { getCurrentSubscription } from '../account/middleware';

const DigestContext = createContext();

export const DigestProvider = ({children}) => {
    const initialState = {
        digests: [],
        newsletters: [],
        digestPreferences : [],
        newsletterPreferences: [],
        prevDigestPreferences: [],
        prevNewsletterPreferences: [],
        changed: false
    }

    const [ state, dispatch ] = useReducer(digestReducer, initialState);
    const { handleGetCurrentSubscription } = useAccount();
    const { handleLoading, handleMessage, user } = useApp();

    const preferencesHaveChanged = () => {
        let haveChanged = 
            state.newsletterPreferences !== state.prevNewsletterPreferences || 
            state.digestPreferences !== state.prevDigestPreferences;
        
        return haveChanged;
    }

    const handleSetSubsDigNews = () => {
        getCurrentSubscription().then(data => {
            handleGetCurrentSubscription(data.data);
            getDigestNewsletter().then(res => {
                const {digests, newsletters} = mapDigestNewsletter(res.data, data.data);
                dispatch({type: 'GET_DIGESTS', payload: digests});
                dispatch({type: 'GET_NEWSLETTERS', payload: newsletters});
                dispatch({type: 'SET_CONTROLLED_LISTS', payload: {digestPreferences: data.data.digestPreferences, newsletterPreferences: data.data.newsletterPreferences}});
            })
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleWizardDigNews = () => {
        getWizardDigNews().then(data => {
            getDigestNewsletter().then(res => {
                const {digests, newsletters} = mapDigestNewsletter(res.data, data.data);
                dispatch({type: 'GET_DIGESTS', payload: digests});
                dispatch({type: 'GET_NEWSLETTERS', payload: newsletters});
                dispatch({type: 'SET_CONTROLLED_LISTS', payload: data.data});
                handleLoading(false);
            })
        }).catch(err => {
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleSetDigest = (e, type) => {
        dispatch({type: 'SET_INPUT_CHECKED', payload: {list: 'digests', input: e.target, type: type}});
        if (e.target.name.includes('Northern Miner')) {
            dispatch({type: 'SET_NEWS_PREFERENCE', payload: e.target});
        } else {
            dispatch({type: 'SET_DIGEST_PREFERENCE', payload: e.target});
        }
    }
    const handleSetNewsletter = (e, type) => {
        dispatch({type: 'SET_INPUT_CHECKED', payload: {list: 'newsletters', preferences: 'newsletterPreferences', input: e.target, type: type}});
        dispatch({type: 'SET_NEWS_PREFERENCE', payload: e.target});
    }
    const handlePostDigestWizard = (nextStep) => {
        const changed = preferencesHaveChanged();
        if ((!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing')) && !changed) {
            nextStep();
        } else {
            handleLoading(true);
            postWizardDigest(state.digestPreferences).then(data => {
                postWizardNewsletter(state.newsletterPreferences).then(res => {
                    dispatch({type: 'SET_PREV_CONTROLLED_LISTS', payload: {digestPreferences: state.digestPreferences, newsletterPreferences: state.newsletterPreferences}});
                    handleLoading(false, true);
                    handleMessage('success', 'Preferences saved! Moving to the next step.');
                    setTimeout(() => {
                        nextStep();
                    }, 500);
                }).catch(err => {
                    handleLoading(false, true);
                    handleMessage('error', err.response.headers.errormessage);
                })
            }).catch(err => {
                handleLoading(false);
                handleMessage('error', err.response.headers.errormessage);
            })
        }
    }
    const handlePostNewsletterWizard = (nextStep) => {
        const changed = preferencesHaveChanged();
        if ((!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing')) && !changed) {
            nextStep();
        } else {
            handleLoading(true);
            postWizardNewsletter(state.newsletterPreferences).then(res => {
                dispatch({type: 'SET_PREV_CONTROLLED_LISTS', payload: {digestPreferences: state.digestPreferences, newsletterPreferences: state.newsletterPreferences}});
                handleLoading(false, true);
                handleMessage('success', 'Preferences saved! Moving to the next step.');
                setTimeout(() => {
                    nextStep();
                }, 500);
            }).catch(err => {
                handleLoading(false, true);
                handleMessage('error', err.response.headers.errormessage);
            })
        }
    }
    const handlePostDigestSubs = () => {
        handleLoading(true);
        postWizardDigest(state.digestPreferences).then(() => {
            postWizardNewsletter(state.newsletterPreferences).then(() => {
                dispatch({type: 'SET_PREV_CONTROLLED_LISTS', payload: {digestPreferences: state.digestPreferences, newsletterPreferences: state.newsletterPreferences}});
                handleLoading(false, true);
                handleMessage('success', 'Preferences updated!');
            }).catch(err => {
                handleLoading(false, true);
                handleMessage('error', err.response.headers.errormessage);
            })
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handlePostNewsletterSubs = () => {
        handleLoading(true);
        postWizardNewsletter(state.newsletterPreferences).then(() => {
            handleLoading(false, true);
            handleMessage('success', 'Preferences updated!');
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }

    const providerValue = {
        ...state,
        handleSetDigest,
        handleSetNewsletter,
        handleWizardDigNews,
        handlePostDigestWizard,
        handlePostNewsletterWizard,
        handleSetSubsDigNews,
        handlePostDigestSubs,
        handlePostNewsletterSubs
    }

    return (
        <DigestContext.Provider value={providerValue}>
            <React.Fragment>
                {children}
            </React.Fragment>
        </DigestContext.Provider>
    )
}

export const useDigest = () => useContext(DigestContext);