import React, { useEffect, useState, useRef } from "react";
import {
  CardContent,
  List,
  ListItem,
  Grid,
  Icon,
  Box,
  MenuItem,
} from "@mui/material";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useAccount } from "../../components/features/account/context";
import Input from "../../components/UI/atoms/Input";
import StyledContainer from "../../components/UI/atoms/StyledContainer";
import Text from "../../components/UI/atoms/Text";
import Card from "../../components/UI/molecules/Card";
import Button from "../../components/UI/atoms/Button";
import Checkbox from "../../components/UI/atoms/Checkbox";
import { validateIndFilled } from "../../lib/validateInputsFilled";
import data from "../../data/industries.json";
import Reaptcha from "reaptcha";
import helperText from "../../data/validationMessage.json";
import { emptyObject } from "../../lib/emptyObject";
import { formatPlans } from "../../lib/formatPlans";
import Divider from "../../components/UI/atoms/Divider";

export default function CreateAccount() {
  const {
    plans,
    frequency,
    handleSetMembership,
    handleAccountForm,
    form,
    formValidation,
    handleCreateAccount,
    handleVerifyCaptcha,
    handleError,
    handlePlanFrequency,
  } = useAccount();
  const { type } = useParams();
  const [disableSave, setDisableSave] = useState(true);
  const [token, setToken] = useState("");
  const [selected, setSelected] = useState({});
  const [price, setPrice] = useState("");
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Plans descriptions
  const plansDetails = {
    NewsMarkets: [
      'Unlimited Access to Every Story on The Northern Miner and MINING.COM',
      'Access to Joint Venture Articles and Sponsored Posts',
      'Access to MINING.COM Newswire Syndicated Stories',
      'Access to The Northern Miner Weekly and MINING.COM Daily News Digest',
      'Access to Free TNM Maps',
      'Access to Specialized News Digests Across All Platforms',
      'Access to MINING.COM Markets',
      'MINING.COM Buyers Guide Enhanced Features'
    ],
    NewsData: [
      'Premium Access to TNM Marco Polo',
      'Unlimited Access to Every Story on The Northern Miner and MINING.COM',
      'Access to Joint Venture Articles and Sponsored Posts',
      'Access to MINING.COM Newswire Syndicated Stories',
      'Access to The Northern Miner Weekly and MINING.COM Daily News Digest',
      'Access to Free TNM Maps',
      'Access to Specialized News Digests Across All Platforms',
      'Access to MINING.COM Markets',
      'MINING.COM Buyers Guide Enhanced Features',
      'Buyers Guide Featured Listing Discount'
    ],
    Free: [
      '2 Free News Stories on The Northern Miner and MINING.COM Each Week',
      'Access to Joint Venture Articles and Sponsored Posts',
      'Access to MINING.COM Newswire Syndicated Stories',
      'Access to The Northern Miner Weekly and MINING.COM Daily News Digest',
      'Access to Free TNM Maps'
    ],
    Print: [
      'Access to The Northern Miner Print and Digital Editions',
    ]
  }

  const getPrice = (select, freq) => {
    let price;
    if (freq.trial) {
      price = "trial";
    } else if (select.pricing) {
      price = select.pricing[freq.type];
    } else {
      price = select.name.split(" ")[0];
    }
    setPrice(price);
  };

  const divFlex = {
    display: "flex",
  };

  const divItem = {
    flex: 1,
    marginTop: "20px",
  };

  useEffect(() => {
    if (plans.length && emptyObject(selected)) {
      const planInfo = type.split("-");
      const tag = planInfo[0];
      handleSetMembership(tag);
      let freq = { type: planInfo[1] || "free" };
      if (planInfo[2]) {
        freq.trial = true;
      }

      handlePlanFrequency(freq);
      let plan;
      if (freq.type === "free") {
        plan = plans.find((plan) => plan.subscriptionTag === tag);
      } else {
        plan = plans.find(
          (plan) =>
            plan.subscriptionTag === tag && plan.renewalType === freq.type
        );
      }
      getPrice(plan, freq);
      setSelected(plan);
    }
    // eslint-disable-next-line
  }, [plans, selected]);

  useEffect(() => {
    setDisableSave(validateIndFilled(form, formValidation));
  }, [form, token]);

  const handleSubmit = () => {
    if (disableSave) {
      handleError();
    } else {
      const promo = searchParams.get("promo");
      handleCreateAccount(token, selected, promo, type);
    }
  };

  const handleVerify = () => {
    captchaRef.current.getResponse().then((res) => {
      setToken(res);
      handleVerifyCaptcha();
    });
  };
  const handleExpire = () => {
    setTimeout(() => captchaRef.current.reset(), 0);
  };

  return (
    <StyledContainer
      sx={{ maxWidth: "1200px", margin: "auto" }}
      className="create-account"
    >
      {!plans.length ? (
        <Text>Loading...</Text>
      ) : (
        <div style={divFlex}>
          <div style={divItem}>
            <Card>
              <Text type="h1">Create your account</Text>
              <Text>
                <span style={{ color: "#d32f2f", fontWeight: "600" }}>* </span>
                Required fields
              </Text>
              <Box component="form" sx={{ marginTop: "20px" }}>
                <Input
                  label="email"
                  required
                  error={!formValidation.email}
                  helperText={!formValidation.email && helperText.data.email}
                  type="email"
                  id="email"
                  value={form.email}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="password"
                  required
                  error={!formValidation.password}
                  helperText={
                    !formValidation.password && helperText.data.password
                  }
                  passwordInput
                  id="password"
                  value={form.password}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="confirm password"
                  required
                  error={!formValidation.confirmPassword}
                  helperText={
                    !formValidation.confirmPassword &&
                    helperText.data.confirmPassword
                  }
                  passwordInput
                  id="confirmPassword"
                  value={form.confirmPassword}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="first name"
                  required
                  error={!formValidation.firstName}
                  helperText={
                    !formValidation.firstName && helperText.data.firsName
                  }
                  id="firstName"
                  value={form.firstName}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="last name"
                  required
                  error={!formValidation.lastName}
                  helperText={
                    !formValidation.lastName && helperText.data.lastName
                  }
                  id="lastName"
                  value={form.lastName}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="job title"
                  required
                  error={!formValidation.jobTitle}
                  helperText={
                    !formValidation.jobTitle && helperText.data.jobTitle
                  }
                  id="jobTitle"
                  value={form.jobTitle}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="company name"
                  required
                  error={!formValidation.companyName}
                  helperText={
                    !formValidation.companyName && helperText.data.company
                  }
                  id="companyName"
                  value={form.companyName}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="phone no"
                  required
                  error={!formValidation.phoneNo}
                  helperText={
                    !formValidation.phoneNo && helperText.data.phoneNo
                  }
                  id="phoneNo"
                  value={form.phoneNo}
                  onChange={(e) => handleAccountForm(e)}
                />
                <Input
                  label="industry"
                  required
                  error={!formValidation.industry}
                  id="industry"
                  helperText={
                    !formValidation.industry && helperText.data.industry
                  }
                  name="industry"
                  select
                  value={form.industry}
                  onChange={(e) => handleAccountForm(e, "select")}
                >
                  {data.industries.map((industry, key) => (
                    <MenuItem key={key} name="industry" value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </Input>
                <Text sx={{ fontSize: "0.875rem" }}>
                  Consent{" "}
                  <span style={{ color: "#d32f2f", fontWeight: "600" }}>*</span>
                </Text>
                <Checkbox
                  helperText={
                    !formValidation.CASLoptin && helperText.data.CASLoptin
                  }
                  id="CASLoptin"
                  checked={form.CASLoptin}
                  onChange={(e) => handleAccountForm(e, "checkbox")}
                  label={
                    <Text sx={{ fontSize: "0.5rem" }}>
                          By submitting this form I consent to The Northern Miner Group's Policies & Terms and agree to receive commercial electronic messages from The Northern Miner Group and affiliated divisions. You may withdraw your consent at any time.
                    </Text>
                  }
                />
                <Reaptcha
                  sitekey={process.env.REACT_APP_MEMBERSHIP_CAPTCHA_SITE_KEY}
                  className="captcha"
                  onVerify={handleVerify}
                  onExpire={() => handleExpire()}
                  ref={captchaRef}
                />
                <Button
                  onClick={() => handleSubmit()}
                  className={disableSave ? "submit-disable" : ""}
                >
                  Create your account
                </Button>
              </Box>
            </Card>
            <StyledContainer
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 20px 36px",
              }}
            >
              <Button
                className="step-nav"
                onClick={() => navigate("/membership-plan")}
                icon={{ start: true, base: "fa-solid", type: "fa-angle-left" }}
              >
                <Text>Choose Plan</Text>
              </Button>
              <Button
                className="step-nav"
                icon={{ end: true, base: "fa-solid", type: "fa-angle-right" }}
                disabled
              >
                <Text>{type === "basic" ? "Confirmation" : "Payment"}</Text>
              </Button>
            </StyledContainer>
          </div>
          <div style={divItem}>
            <Card>
              <Text type="h1" sx={{ textAlign: "center", marginTop: "20px" }}>
                Selected plan
              </Text>
              <Card
                type="description"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text
                  sx={{
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  {selected.readableName}
                </Text>
                <Text
                  sx={{
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  {price}
                </Text>
              </Card>
              <Grid
                container
                sx={{
                  marginTop: "1px",
                  width: "100%",
                  ml: 0,
                  alignContent: "center",
                }}
              >
                {plans
                  .filter((plan) => formatPlans(plan, frequency.type))
                  .map((plan) => (
                    <Grid key={plan.subscriptionTag}>
                      {plan.productName === selected.productName && (
                        <div
                          style={{
                            borderRadius: 0,
                            //maxWidth: "400px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <StyledContainer>
                            <CardContent sx={{ padding: "20px" }}>
                              <StyledContainer sx={{ marginTop: "5px" }}>
                                <List
                                  sx={{ padding: "5px 0", marginLeft: "5px" }}
                                >
                                  <div>
                                    {plan.productName === 'NewsMarket' &&
                                      <>
                                        {plansDetails.NewsMarkets.map((item, index) => (
                                          <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                            <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60' }} baseClassName="fa-regular" className="fa-circle-check" />
                                            <Text>{item}</Text>
                                          </ListItem>
                                        ))}
                                      </>}
                                    {plan.productName === 'NewsData' &&
                                      <>
                                        {plansDetails.NewsData.map((item, index) => (
                                          <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                            <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60' }} baseClassName="fa-regular" className="fa-circle-check" />
                                            <Text>{item}</Text>
                                          </ListItem>
                                        ))}
                                      </>}
                                    {plan.productName === 'Free' &&
                                      <>
                                        {plansDetails.Free.map((item, index) => (
                                          <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                            <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60' }} baseClassName="fa-regular" className="fa-circle-check" />
                                            <Text>{item}</Text>
                                          </ListItem>
                                        ))}
                                      </>}
                                  </div>
                                </List>
                              </StyledContainer>
                            </CardContent>
                          </StyledContainer>
                        </div>
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Card>
          </div>
        </div>
      )}
    </StyledContainer>
  );
}
