import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledStep = styled('div')(() => ({
    display: 'flex',
    marginRight: '5px'
}))

export default function Text({type, children, sx, step, className}) {
  if (type === 'step') {
    return (
        <StyledStep>
            <Typography sx={{fontWeight: '700', fontSize: '0.875rem', textTransform: 'uppercase', lineHeight: '17px', marginRight: '5px'}}>
                Step {step.number}
            </Typography>
            <Typography sx={{fontWeight: '400', fontSize: '0.875rem', textTransform: 'uppercase', lineHeight: '17px'}}>
                {step.text}
            </Typography>
        </StyledStep>
    )
  }
  if (type === 'h1') {
    return (
        <Typography sx={{fontWeight: '700', fontSize: '1.125rem', lineHeight: '1.125rem', textTransform: 'uppercase', ...sx}}>
            {children}
        </Typography>
    )
  }
  if (type === 'h3') {
    return (
        <Typography sx={{fontWeight: '700', fontSize: '0.875rem', lineHeight: '17px'}}>
            {children}
        </Typography>
    )
  }
  if (type === 'body') {
    return (
        <Typography sx={{fontWeight: '400', fontSize: '1rem', lineHeight: '19.5px', display: 'flex', alignItems: 'baseline'}}>
            {children}
        </Typography>
    )
  }
  if (type === 'label') {
    return (
        <Typography sx={{fontWeight: '400', fontSize: '0.875rem', lineHeight: '17px'}}>
            {children}
        </Typography>
    )
  }
  else {
    return (
        <Typography className={className} sx={{...sx}}>
            {children}
        </Typography>
    )
  }
}
