import React, { createContext, useContext, useReducer } from 'react';
import { useNavigate }  from 'react-router-dom';
import Cookies from 'universal-cookie';
import { appReducer } from './AppReducer';
import { postLogout } from './services/authMiddleware';
import { serviceAuth } from './services/getUserAuth';

const AppContext = createContext();

const cookies = new Cookies();

export const AppProvider = ({children}) => {
    const initialState = {
        isLoading: true,
        isLoadingAuth: true,
        isError: false,
        message: {status: null, content: ''},
        user: {}
    }

    const [ state, dispatch ] = useReducer(appReducer, initialState);
    const navigate = useNavigate();

    const handleLoading = (loading, prevState = state.isLoading) => {
        if (state.isLoading !== loading || prevState !== loading) {
            dispatch({type: "SET_LOADING", payload: loading});
        }
    }
    const handleCloseMessage = () => {
        dispatch({type: "SET_CLOSE_MESSAGE", payload: null});
    }
    const handleMessage = (status, message) => {
        dispatch({type: "SET_MESSAGE", payload: {status: status, content: message}});
        setTimeout(() => {
            handleCloseMessage();
        }, 2000);
    }
    const handleUserAuth = (auth) => {
        dispatch({type: 'SET_AUTH', payload: auth});
    }

    const handleUser = () => {
        serviceAuth().then((data) => {
            const user = data.data;
            if(user == null && user.status === 204)
            {
                handleMessage('error', 'Please login again.');
                setTimeout(() => {
                    cookies.set('token', undefined);
                    cookies.set('valid', undefined);
                    window.location.href = '/login?reset';
                }, 500);
            }
            dispatch({type: 'SET_AUTH', payload: user});
            dispatch({type: 'SET_LOADING_AUTH', payload: false});
        }).catch(err => {
            handleMessage('error', 'Please login again.');
            setTimeout(() => {
                cookies.set('token', undefined);
                cookies.set('valid', undefined);
                window.location.href = '/login?reset';
            }, 500);
        })
    }
    const handleClearUser = () => {
        dispatch({type: 'SET_AUTH', payload: {}});
    }
    const handleLogout = (url) => {
        dispatch({type: 'SET_LOADING_AUTH', payload: true});
        const returnUrl = url || process.env.REACT_APP_MEMBERSHIP_URL + 'login';
        postLogout(returnUrl).then((data) => {
            dispatch({type: 'SET_AUTH', payload: {}});
            dispatch({type: 'SET_LOADING_AUTH', payload: false});
            cookies.set('token', undefined);
            cookies.set('valid', undefined);
            window.location.href = data.data;
        })
    }
    const handleUpdateUserData = (key, data) => {
        dispatch({type: 'SET_USER_UPDATE', payload: {key, data}})
    }
    const handleStopLoading = loading => {
        dispatch({type: 'SET_LOADING_AUTH', payload: false});
    }

    const providerValue = {
        ...state,
        handleLoading,
        handleMessage,
        handleUserAuth,
        handleUser,
        handleLogout,
        handleUpdateUserData,
        handleClearUser,
        handleStopLoading
    }

    return (
        <AppContext.Provider value={providerValue}>
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);