import React, { useEffect, useState, useRef } from "react";
import {
  CardContent,
  List,
  ListItem,
  Grid,
  Icon,
  Box,
  MenuItem,
  Stack,
  Switch,
  styled,
} from "@mui/material";

import { Snackbar, Alert  , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import { useParams, useNavigate} from "react-router-dom";
import { useAccount } from "../../components/features/account/context";
import Input from "../../components/UI/atoms/Input";
import StyledContainer from "../../components/UI/atoms/StyledContainer";
import Text from "../../components/UI/atoms/Text";
import Card from "../../components/UI/molecules/Card";
import Button from "../../components/UI/atoms/Button";
import { validateInputsFilled } from "../../lib/validateInputsFilled";
import { emptyObject } from "../../lib/emptyObject";
import { formatPlans } from "../../lib/formatPlans";
import Divider from "../../components/UI/atoms/Divider";
import { formatPhoneInput } from '../../lib/formatPhoneInput';
import { useApp } from '../../AppContext';

//Temporary Import

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: '40px',
  height: '22px',
  padding: '2px',
  '& .MuiSwitch-switchBase': {
    padding: '1px'
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    border: `1px solid ${theme.palette.primary.main}`
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.primary.main
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FFF',
      border: `1px solid ${theme.palette.primary.main}`
    },
    '& + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.primary.main
    },
  }
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: '0.875rem',
  padding: '2px 8px'
}))

const ShippingPreference = () => {
  const { user } = useApp();
  const {
    plans,
    frequency,
    handleSetMembership,
    handlePlanFrequency,
    states,
    handleGetStates,
    countries,
    handleGetCountries,
    handleAddressForm,
    digital,
    shippingFee,
    handleShippingPreferenceCache,
    handleNewCheckoutSession,
    addressForm,
    addressFormValidation
  } = useAccount();
  

  const { type } = useParams();
  const [trial, setTrial] = useState(false);
  const [ originalForm ] = useState(addressForm);
  const [selected, setSelected] = useState({});
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');



  // getting shipping costs

  const divFlex = {
    display: "flex",
  };

  const divItem = {
    flex: 1,
    marginTop: "20px",
  };

  
  const handlePostAddressForPrint = (value) => {
    if(addressForm.address_Country == "" || addressForm.address_State == "" ||
        addressForm.address_City == "" || addressForm.address_Line_1 == "" ||
        addressForm.address_Zip == "" || addressForm.copies == "" ||
        addressForm.fullName == "" || addressForm.phoneNo == "" ){
            setOpenErrorSnackbar(true);
    }
    else{
    const dynamicMessage = `Are you sure you want to confirm Print Edition selection for a Shipping Fee:`;
    setMessage(dynamicMessage);
    setOpenDialog(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  const handleCloseDialog = (confirmed) => {
    setOpenDialog(false);
    if (confirmed) {
      handleShippingPreferenceCache();
      setOpenSnackbar(true);
    }
  };
    

  // Plans descriptions
  const plansDetails = {
    NewsMarkets: [
      'Unlimited Access to Every Story on The Northern Miner and MINING.COM',
      'Access to Joint Venture Articles and Sponsored Posts',
      'Access to MINING.COM Newswire Syndicated Stories',
      'Access to The Northern Miner Weekly and MINING.COM Daily News Digest',
      'Access to Free TNM Maps',
      'Access to Specialized News Digests Across All Platforms',
      'Access to MINING.COM Markets',
      'MINING.COM Buyers Guide Enhanced Features'
    ],
    NewsData: [
      'Premium Access to TNM Marco Polo',
      'Unlimited Access to Every Story on The Northern Miner and MINING.COM',
      'Access to Joint Venture Articles and Sponsored Posts',
      'Access to MINING.COM Newswire Syndicated Stories',
      'Access to The Northern Miner Weekly and MINING.COM Daily News Digest',
      'Access to Free TNM Maps',
      'Access to Specialized News Digests Across All Platforms',
      'Access to MINING.COM Markets',
      'MINING.COM Buyers Guide Enhanced Features',
      'Buyers Guide Featured Listing Discount'
    ],
    Free: [
      '2 Free News Stories on The Northern Miner and MINING.COM Each Week',
      'Access to Joint Venture Articles and Sponsored Posts',
      'Access to MINING.COM Newswire Syndicated Stories',
      'Access to The Northern Miner Weekly and MINING.COM Daily News Digest',
      'Access to Free TNM Maps'
    ],
    Print: [
      'Access to The Northern Miner Print and Digital Editions',
    ],
    Digital: [
      'Access to The Northern Miner Digital Editions',
    ]
  }

  const getPrice = (select, freq) => {
    let price;
    if (freq.trial) {
      //  price = select.pricing[freq.type] + ' Trial';
      setTrial(true);
    }
    // } else
     if (select.pricing) {
      price = select.pricing[freq.type];
    } else {
      price = select.name.split(" ")[0].replace('$', '');
    }
    setPrice(price);
  };

  useEffect(() => {
    if (plans.length && emptyObject(selected)) {
      const planInfo = type.split("-");
      const tag = planInfo[0];
      handleSetMembership(tag);
      let freq = { type: planInfo[1] || "free" };
      if (planInfo[2]) {
        freq.trial = true;
      }

      handlePlanFrequency(freq);
      let plan;
      if (freq.type === "free") {
        plan = plans.find((plan) => plan.subscriptionTag === tag);
      } else {
        plan = plans.find(
          (plan) =>
            plan.subscriptionTag === tag && plan.renewalType === freq.type
        );
      }
      getPrice(plan, freq);
      setSelected(plan);
    }
    // eslint-disable-next-line
  }, [plans, selected]);

  // Getting the countries
  useEffect(() => {
    if (checked) {
      handleGetCountries();
    }
  }, [checked])


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <StyledContainer
      sx={{ maxWidth: "1200px", margin: "auto" }}
      className="create-account"
    >
      {!plans.length ? (
        <>
          <Text>Loading...</Text>
        </>
      ) : (
        <div style={divFlex}>
          <div style={divItem}>
            <Card>
              <Text type="h1" sx={{ textAlign: "center", marginTop: "20px" }}>
                Selected plan
              </Text>
              <Card
                type="description"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text
                  sx={{
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  {selected.readableName}
                </Text>
                <Text
                  sx={{
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  
                  {shippingFee !== null && checked && (
                      <span style={{ color: "green", marginLeft: "10px" }}>
                        {/* {price + shippingFee} */}
                        {` Total:  $${Number(price) + Number(shippingFee)}`}

                      </span>
                    )} 
                    {shippingFee !== null && !checked && (
                      <span style={{ color: "green", marginLeft: "10px" }}>
                        {/* {price + shippingFee} */}
                        {` Total:  $${Number(price)}`}

                      </span>
                    )}

                    {shippingFee == null && !trial && (
                      <span style={{ color: "green", marginLeft: "10px" }}>
                      
                        ${price} 
                      </span>
                    )}
                    {shippingFee == null && trial && (
                      <span style={{ color: "green", marginLeft: "10px" }}>
                      
                        ${price} {'Trial'}
                      </span>
                    )}
                </Text>
              </Card>
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Text sx={{ fontWeight: 600, color: !checked ? '#413F3F' : '#918F8F' }}>Digital Edition</Text>
                <StyledSwitch disabled={trial} checked={checked} onChange={handleChange} />
                <Text sx={{ fontWeight: 600, color: checked ? '#413F3F' : '#918F8F' }}>Print + Digital Edition</Text>
              </Stack>
              {plans
                .filter((plan) => formatPlans(plan, frequency.type))
                .map((plan) => (
                  <Grid key={plan.subscriptionTag}>
                    {plan.productName === selected.productName && (
                      <div style={{
                        borderRadius: 0,
                        //maxWidth: "400px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      >
                        <CardContent sx={{ padding: "20px" }}>
                          <StyledContainer sx={{ marginTop: "5px" }}>
                            <List
                              sx={{ padding: "5px 0", marginLeft: "5px" }}
                            >
                              <div>
                                {plan.productName === 'NewsMarket' &&
                                  <>
                                    {plansDetails.NewsMarkets.map((item, index) => (
                                      <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                        <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60' }} baseClassName="fa-regular" className="fa-circle-check" />
                                        <Text>{item}</Text>
                                      </ListItem>
                                    ))}
                                  </>}
                                {plan.productName === 'NewsData' &&
                                  <>
                                    {plansDetails.NewsData.map((item, index) => (
                                      <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                        <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60' }} baseClassName="fa-regular" className="fa-circle-check" />
                                        <Text>{item}</Text>
                                      </ListItem>
                                    ))}
                                  </>}
                                {plan.productName === 'Free' &&
                                  <>
                                    {plansDetails.Free.map((item, index) => (
                                      <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                        <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60' }} baseClassName="fa-regular" className="fa-circle-check" />
                                        <Text>{item}</Text>
                                      </ListItem>
                                    ))}
                                  </>}
                                {checked &&
                                  <>
                                    {plansDetails.Print.map((item, index) => (
                                      <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                        <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60', fontWeight: 'bold' }} baseClassName="fa-regular" className="fa-circle-check" />
                                        <Text sx={{  color: 'green' }}>{item}
                                        {shippingFee !== null && (
                                            <span style={{ color: "green", marginLeft: "10px", fontWeight: 'bold' }}>
                                              Shipping Fee: ${shippingFee}
                                            </span>
                                          )}
                                          {shippingFee == null && (
                                            <span style={{ color: "green", marginLeft: "10px", fontWeight: 'bold' }}>
                                              Shipping Fee Included:${0}
                                            </span>
                                          )}
                                        </Text>
                                      </ListItem>
                                    ))}
                                  </>}
                                  {!checked &&
                                  <>
                                    {plansDetails.Digital.map((item, index) => (
                                      <ListItem key={index} sx={{ padding: 0, alignItems: 'baseline' }}>
                                        <Icon sx={{ fontSize: '14px', marginRight: '10px', color: '#AB9F60', fontWeight: 'bold' }} baseClassName="fa-regular" className="fa-circle-check" />
                                        <Text sx={{ color: 'green' }}>{item}</Text>
                                      </ListItem>
                                    ))}
                                  </>}
                              </div>
                            </List>
                          </StyledContainer>
                        </CardContent>
                      </div>
                    )}
                  </Grid>
                ))
              }
            </Card>
            <StyledContainer
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 20px 36px",
              }}
            >
              <Button
                className="step-nav"
                onClick={() => navigate("/membership-plan")}
                icon={{ start: true, base: "fa-solid", type: "fa-angle-left" }}
              >
                <Text>Choose Plan</Text>
              </Button>
              <Button
                className="step-nav"
                icon={{ end: true, base: "fa-solid", type: "fa-angle-right" }}
                disabled={checked}
                onClick={() => handleNewCheckoutSession({priceId: null, productId: null})}
              >
                <Text>{type === "basic" ? "Confirmation" : "Payment"}</Text>
              </Button>
            </StyledContainer>
            <Card>
            <Text  sx={{ fontSize: '.8rem', color:  'black' }}>
            For PRINT ONLY subscriptions, please complete the registration process for “Print + Digital Edition” and contact
            </Text>
            <Text  sx={{ fontSize: '.8rem', color:  'black' }}>
            customer support at support@northernminer.com or by phone 1-888-502-3456 to request a change to PRINT ONLY
            </Text></Card>
          </div>
          {
            checked && (
              <div style={divItem}>
                <Card>
                  <Box component='form'>
                    <Text type="h1" sx={{ marginTop: "20px", marginBottom: "20px" }}>
                      Shipping Information
                    </Text>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Text>Full Name <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.fullName} label='Full Name' id='fullName' value={addressForm.fullName} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                      <Grid item xs={12}>
                        <Text>Company (Optional)</Text>
                        <Input label='Company' id='companyName' value={addressForm.companyName} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                      <Grid item xs={12}>
                        <Text>Address <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.address_Line_1} label='Address Line 1' id='address_Line_1' value={addressForm.address_Line_1} onChange={((e) => handleAddressForm(e))} />
                        <Input label='Address Line 2' id='address_Line_2' value={addressForm.address_Line_2} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                      <Grid item xs={12}>
                        <Text>City <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.address_City} label='City' id='address_City' value={addressForm.address_City} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Text>Country <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.address_Country} label='Country' name='address_Country' select value={addressForm.address_Country} onChange={((e) => handleGetStates(e))}>
                          <StyledMenuItem disabled value='' sx={{ display: 'none' }}>Country</StyledMenuItem>
                          {countries.length ?
                            countries.map((country, index) => (
                              <StyledMenuItem name='country' key={index} value={country.value}>{country.text}</StyledMenuItem>
                            ))
                            : (
                              <StyledMenuItem>Options...</StyledMenuItem>
                            )}
                        </Input>
                        {/* <Input label='Country' id='address_Country' value={addressForm.address_Country} onChange={((e) => handleAddressForm(e))}/> */}
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Text>Province/State/Region<span style={{ color: '#d32f2f', fontWeight: '600' }}>*</span></Text>
                        <Input error={!addressFormValidation.address_State} label='Province' name='address_State' select value={addressForm.address_State} onChange={((e) => handleAddressForm(e, 'select'))}>
                          <StyledMenuItem disabled value='' sx={{ display: 'none' }}>State</StyledMenuItem>
                          {states.length ?
                            states.map(state => (
                              <StyledMenuItem name='address_State' key={state.value} value={state.value}>{state.text}</StyledMenuItem>
                            ))
                            : (
                              <StyledMenuItem>Options...</StyledMenuItem>
                            )}
                        </Input>
                        {/* <Input label='Province' id='address_State' value={addressForm.address_State} onChange={((e) => handleAddressForm(e))}/> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Text>ZIP/Postal Code <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.address_Zip} sx={{ '&.MuiFormControl-root': { mb: 0 } }} label='ZIP Code' id='address_Zip' value={addressForm.address_Zip} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                      <Grid item xs={12}>
                        <Text>Phone Number <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.phoneNo} sx={{ '&.MuiFormControl-root': { mb: 0 } }} label='Phone Number' id='phoneNo' value={formatPhoneInput(addressForm.phoneNo)} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                      <Grid item xs={12}>
                        <Text>Copies <span style={{ color: '#d32f2f', fontWeight: '600' }}>* </span></Text>
                        <Input error={!addressFormValidation.copies} sx={{ '&.MuiFormControl-root': { mb: 0 } }} label='Copies' id='copies' value={addressForm.copies} onChange={((e) => handleAddressForm(e))} />
                      </Grid>
                    </Grid>
                    <Divider />
                    <div>
                    <Button onClick={() => handlePostAddressForPrint()} disabled={ !digital }>PAYMENT (Shipping Included)</Button>
                    <Dialog
                                open={openDialog}
                                onClose={() => handleCloseDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <p>{message} <b style={{color:'red'}}>{shippingFee == null ? 0: shippingFee}{'CAD'} </b> {' ?'}</p> 
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={() => handleCloseDialog(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => handleCloseDialog(true)} color="primary" autoFocus>
                                    Confirm
                                </Button>
                                </DialogActions>
                            </Dialog>

                            {/* <Snackbar
                                open={openSnackbar}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                Selection confirmed!
                                </Alert>
                            </Snackbar> */}
                            <Snackbar
                                open={openErrorSnackbar}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                                PLease enter all the required address fields
                                </Alert>
                            </Snackbar>
                    </div>
                  </Box>
                </Card>

              </div>
            )
          }

        </div>
      )}
    </StyledContainer>
  )
}

export default ShippingPreference