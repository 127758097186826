import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { dateDifference, isDateAhead } from '../../../lib/dateDifference'
import { displayFlags } from '../../../lib/displayFlags'
import { useAccount } from '../../features/account/context'
import { getFailedPayment } from '../../features/account/middleware'
import Button from '../atoms/Button'
import InfoMessage from '../atoms/InfoMessage'
import StyledContainer from '../atoms/StyledContainer'
import Text from '../atoms/Text'

const cookies = new Cookies();

export default function SubscriptionMessage({currentSubscription, user}) {
    const [planInfo, setPlanInfo] = useState(null);
    const [failedPayment, setFailedPayment] = useState(null);

    const { plans, handleNewCheckoutSession } = useAccount();

    useEffect(() => {
        
        if (user.lastPaymentFailed) {
            getFailedPayment().then(data => {
                const userPlan = plans.find(plan => plan.productId === data.data.productId);
                setFailedPayment(data.data);
                setPlanInfo(userPlan);
            })
        }
    }, []);

    const handleRetryPayment = () => {
        cookies.set('prev', window.location.href);
        handleNewCheckoutSession(failedPayment);
    }

  return (
    <React.Fragment>
        {displayFlags(currentSubscription, 'trialMessage') && 
            <InfoMessage>
                <Text sx={{fontWeight: 700, fontSize: '1.5rem', textTransform: 'uppercase', lineHeight: '29px'}}>Free Trial Edition</Text>
                <Text sx={{fontWeight: 700, fontSize: '0.875rem', textTransform: 'uppercase', color: '#2D70DA'}}>{dateDifference(currentSubscription.subscription.trialEndDate)-1} days remaining</Text>
                <Text sx={{my: '10px'}}>We hope you're enjoying your free TRIAL EDITION TNM Membership benefits.</Text>
                <Text>After your free trial ends, this plan will continue automatically..</Text>
            </InfoMessage>
        }
        {displayFlags(currentSubscription, 'toCancelMessage') && 
            <InfoMessage>
                <React.Fragment>
                    <Text sx={{fontWeight: 700, fontSize: '1.5rem', textTransform: 'uppercase', lineHeight: '29px'}}>Your Subscription is Ending</Text>
                    <Text sx={{fontWeight: 700, fontSize: '0.875rem', textTransform: 'uppercase', color: '#2D70DA'}}>{dateDifference(currentSubscription.subscription.renewalDate)-1} days remaining</Text>
                    <Text sx={{my: '10px'}}>Your subscription has been cancelled and you will no longer have access after the expiration date.</Text>
                    <Text>To continue using the benefits of your TNM Membership, please renew your subscription.</Text>
                    <Text> *Please reach out to customer service if you would like to stop receiving print before you expiration date. </Text>
                   
                </React.Fragment>
            </InfoMessage>
        }
        {displayFlags(currentSubscription, 'cancelledMessage') && 
            <InfoMessage>
                <React.Fragment>
                    <Text sx={{fontWeight: 700, fontSize: '1.5rem', textTransform: 'uppercase', lineHeight: '29px'}}>Your Subscription has ended</Text>
                    {user.isMigrated || currentSubscription.subscription.manuallyInserted ?
                        <Text sx={{my: '10px'}}>Your subscription was cancelled on {new Date (currentSubscription.subscription.subscriptionEndDate).toLocaleDateString()}.</Text>
                    :
                        <Text sx={{my: '10px'}}>Your subscription was cancelled on {new Date (currentSubscription.subscription.renewalDate).toLocaleDateString()}.</Text>
                    }
                    <Text>To use all the benefits from TNM Membership, upgrade your subscription below.</Text>
                </React.Fragment>
            </InfoMessage>
        }
        {displayFlags(currentSubscription, 'faliedPayment', user) && 
            <InfoMessage>
                <Text sx={{fontWeight: 700, fontSize: '1.5rem', textTransform: 'uppercase', lineHeight: '29px'}}>Failed Payment</Text>
                <Text sx={{my: '10px'}}>Your payment attempt to subscribe to the <span style={{fontWeight: 600, textTransform: 'uppercase'}}>TNM {planInfo.readableName}</span> plan failed.</Text>
                <StyledContainer>
                    <Text sx={{display: 'inline'}}>You have been assigned our Basic plan. If you wish to continue with the previous plan, please</Text>
                    <Button className='text-message' onClick={() => {handleRetryPayment()}}>retry your payment</Button>
                </StyledContainer>
            </InfoMessage>
        }
    </React.Fragment>
  )
}
