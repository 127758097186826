import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useApp } from '../../AppContext';

export default function Logout() {
    const [ searchParams ] = useSearchParams();
    const { handleLogout } = useApp();

    useEffect(() => {
        const logoutRedirect = searchParams.get('src');
        handleLogout(logoutRedirect);
    }, [])

  return (
    <div></div>
  )
}
