
export const groupMembershipTier = (prices, seat) => {
    const newTier = prices.find(tier => (tier.upperLimit >= seat || (tier.lowerLimit === 51 && seat >= 51)));
    return newTier;
}
export const groupMembershipPriceTotal = (prices, seat) => {
    let totalPrice = 0;

    // total final cost
    for (let seatCount = 1; seatCount <= seat; seatCount ++) {
        if (seatCount <= 51) {
            const currentTier = groupMembershipTier(prices, seatCount);
            totalPrice += currentTier.price;
        } else {
            totalPrice += prices[4].price;
        }
    }

    return totalPrice;
}
export const groupMembershipPriceAdditional = (prices, seat, currentSeats) => {
    let addtionalPrice = 0;

    // total additional cost
    for (let seatCount = currentSeats; seatCount <= seat; seatCount ++) {
        if (seatCount === seat) {
            break;
        } else if (seatCount <= 51) {
            const currentTier = groupMembershipTier(prices, seatCount);
            addtionalPrice += currentTier.price;
        } else {
            addtionalPrice += prices[4].price;
        }
    }

    return addtionalPrice;
}