import React, { useState, useEffect } from 'react';
import { Icon } from '@mui/material';
import StyledContainer from '../UI/atoms/StyledContainer'
import Card from '../UI/molecules/Card';
import Text from '../UI/atoms/Text';
import Link from '../UI/atoms/Link';
import { Outlet } from 'react-router-dom';
import { useApp } from '../../AppContext';
import { useAccount } from '../features/account/context';

export default function MainNavigation() {
    const { user } = useApp();
    const { portalSession } = useAccount();

    const links = [
        { icon: 'fa-user', text: 'Account', path: '/account' },
        { icon: 'fa-arrows-rotate', text: 'Subscriptions', path: '/subscriptions' },
        { icon: 'fa-wallet', text: 'Billing', onClick: portalSession },
        { icon: 'fa-users', text: 'Group', path: '/group-membership' },
    ]
    
    const [ navLinks, setNavLinks ] = useState(links);

    const notEligibleForBilling = user => {
        return !user.currentSubscriptionId || user.currentSubscriptionId.includes('existing') || user.currentSubscriptionId.includes('manual');
    }

    useEffect(() => {
        if (notEligibleForBilling(user) || user.mappedProductName === 'Free') {
            setNavLinks(navLinks.filter(link => link.text !== 'Billing'));
        }
        if (!user.currentSubscriptionId || !user.isRootUser || user.isComplimentaryUser || user.mappedProductName === 'Free') {
            setNavLinks(navLinks.filter(link => link.text !== 'Group' && link.text !== 'Billing'));
        }
    }, [user]);


    return (
        <StyledContainer sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'column', md: 'row'}}}>
            <StyledContainer sx={{display: {xs: 'none', sm: 'none', md: 'block'}, width: '100%', maxWidth: '360px'}}>
                <Card type='description paddless' sx={{margin: 0, marginRight: '20px', display: 'flex', flexDirection: 'column'}}>
                    {navLinks.map((link, index) => {
                        if (link.path)
                            return (
                                <Link type='card-nav' link={link.path} key={index} external={link.external} openTab={link.openTab}>
                                    <Text sx={{lineHeight: '20px', textTransform: 'uppercase'}}>{link.text}</Text>
                                    <Icon baseClassName='fa-light' className={link.icon} sx={{fontSize: '1rem', width: '20px'}} />
                                </Link>
                            )
                        else 
                            return (
                                <div key={index} style={{width: '100%',cursor: 'pointer'}} onClick={() => link.onClick()}>
                                    <Text className='card-nav'>
                                        {link.text}
                                        <Icon baseClassName='fa-light' className={link.icon} sx={{ fontSize: '1rem', width: '20px' }} />
                                    </Text>
                                </div>
                            )
                    })}
                </Card>
            </StyledContainer>
            <StyledContainer sx={{flexGrow: 1}}>
                <Outlet />
            </StyledContainer>
        </StyledContainer>
    )
}
