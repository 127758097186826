import React, { useEffect, useState } from 'react';
import { Icon, Menu as MenuMUI, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '../atoms/Button';
import Link from '../atoms/Link';
import Text from '../atoms/Text';
import { getPhotoPresignedUrl } from '../../features/account/middleware';
import { useAccount } from '../../features/account/context';

const StyledMenuItem = ({children, onClick}) => {
    return (
      <MenuItem
      onClick={() => onClick()}
      sx={{
        padding: '0',
        borderBottom: '1px solid #ECEBEB',
        ':last-of-type': {
          borderBottom: 'none'
        },
        display: 'flex',
        justifyContent: 'space-between',
        width: {
            xs: 'calc(100vw - 32px)',
            sm: '320px'
        },
        ':hover': {
            backgroundColor: '#ecebeb'
        }
      }}
      >
        {children}
      </MenuItem>
    ) 
}

const StyledMenu = styled(MenuMUI)(({theme}) => ({
    '&.grid ul': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, auto)',
        '& .MuiMenuItem-root': {
            borderBottom: 'none',
            width: '100%',
            '& .MuiLink-root': {
                flexDirection: 'column-reverse',
                fontSize: '0.75rem',
                textTransform: 'initial',
                maxWidth: '95px',
                whiteSpace: 'pre-wrap',
                textAlign: 'center',
                height: '100%',
                justifyContent: 'flex-end',
                '& .MuiIcon-root': {
                    fontSize: '2rem',
                    marginBottom: '8px'
                }
            }
        }
    }
}))

export default function Menu({options, menuIcon, type, user}) {
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const { imgURI } = useAccount();

    const handleOpen = e => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    
    return (
        <div>
            {imgURI && user ?
                <Button
                    className='user-icon'
                    placement={`${open ? 'open-menu' : ''} switcher nav`}
                    onClick={handleOpen}
                >
                    <img src={imgURI} height='100%' />
                </Button>
            :
                <Button
                    type='icon'
                    placement={`${open ? 'open-menu' : ''} switcher nav`}
                    onClick={handleOpen}
                    baseClassName={menuIcon.base}
                    className={menuIcon.className}
                    color={menuIcon.color}
                    sx={{ ...menuIcon.sx}}
                />
            }
            <StyledMenu
                id='switcher-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={type}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: { padding: 0 }
                }}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 3px 5px 0px #00000026',
                        marginTop: type === 'user-menu' ? '1px' : '6px',
                        borderRadius: '0px 0px 4px 4px',
                    }
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {options.map((option, index) => {
                    return (
                        <StyledMenuItem key={index} onClick={() => handleClose()}>
                            {option.link ? (
                                <Link onClick={() => {handleClose();}} external={option.external} link={option.link} openTab={option.external} type={`menu`}>
                                    {option.text}
                                    <Icon baseClassName={option.icon.base} className={option.icon.className} sx={{ ...option.sx, fontSize: '1rem' }} />
                                </Link>
                            ) : (
                                <div style={{width: '100%'}} onClick={() => option.onClick()}>
                                    <Text sx={{fontWeight: '400', textTransform: 'uppercase', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px'}}>
                                        {option.text}
                                        <Icon baseClassName={option.icon.base} className={option.icon.className} sx={{ ...option.sx, fontSize: '1rem' }} />
                                    </Text>
                                </div>
                            )}
                        </StyledMenuItem>
                    )
                })}
            </StyledMenu>
        </div>
    )
}
