import features from '../../data/membershipPlans.json';
export const formatPlans = (plan, frequency) => {
    return plan.renewalType === frequency || plan.renewalType === ''
}
export const addLabelPlans = plans => {
    const freePlan = plans.filter(plan => plan.subscriptionTag === 'TNM_BASIC');
    const markets = plans.filter(plan => plan.subscriptionTag === 'TNM_NEWS_MARKET');
    const data = plans.filter(plan => plan.subscriptionTag === 'TNM_NEWS_DATA');
    const arranged = [...freePlan, ...markets, ...data];

    return arranged.map(plan => {
        if (plan.subscriptionTag === 'TNM_BASIC') {
            plan.productName = 'Free';
            plan.readableName = 'Basic News';
        } else if (plan.subscriptionTag === 'TNM_NEWS_MARKET') {
            plan.productName = 'NewsMarket';
            plan.readableName = 'News + Markets';
        } else {
            plan.productName = 'NewsData';
            plan.readableName = 'News + Data';
        }
        plan.features = {
            marketing: features.marketing[plan.subscriptionTag],
            information: features.information[plan.subscriptionTag]
        }
        return plan;
    })
}