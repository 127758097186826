export const appReducer = (state, action) => {
    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_CLOSE_MESSAGE":
            return {
                ...state,
                message: {...state.message, content: ''},
            }
        case "SET_MESSAGE":
            return {
                ...state,
                message: action.payload,
            }
        case "SET_AUTH": 
            return {
                ...state,
                user: {
                    ...action.payload
                },
            }
        case "SET_LOADING_AUTH":
            return {
                ...state,
                isLoadingAuth: action.payload
            }
        case "SET_USER_UPDATE": {
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.payload.key]: action.payload.data
                }
            }
        }
        default:
            return state;
    }
}