import React from 'react';
import { Link as MUILink, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(MUILink)(({theme}) => ({
  textDecoration: 'none',
  display: 'inline-block',
  fontFamily: "'Montserrat', sans-serif",
  '&:hover': {
    textDecoration: 'none'
  },
  '&.contact': {
    fontSize: '0.875rem !important'
  },
  '&.hdr': {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#FFFFFF',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '8px',
    margin: '0 4px',
    cursor: 'pointer',
    lineHeight: 1,
    borderRadius: '3px',
    '&:hover': {
      color: '#FFFFFF',
      textDecoration: 'none',
      backgroundColor: '#5B5959',
    },
    '&.current-page': {
      color: '#FFFFFF',
      textDecoration: 'none',
      backgroundColor: '#5B5959'
    }
  },
  '&.menu': {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#413F3F',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '6px',
    cursor: 'pointer',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      color: '#413F3F',
      textDecoration: 'none',
      backgroundColor: '#ecebeb',
    },
    '&.current-page': {
      color: '#413F3F',
      textDecoration: 'none',
      backgroundColor: '#ecebeb'
    }
  },
  '&.icon': {
    color: '#808183',
    cursor: 'pointer',
    padding: 0,
    '& .MuiIcon-root': {
      fontSize: '1.4rem'
    },
    '&:hover': {
      color: '#231F20',
      backgroundColor: 'transparent'
    }
  },
  '&.footer': {
    padding: 0,
    color: '#fff',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#fff'
    }
  },
  '&.img': {
    backgroundColor: 'transparent',
    '&.hd': {
      padding: '0',
      "& img": {
        display: 'block'
      }
    }
  },
  '&.icon-wrapper': {
    color: '#FFF',
    backgroundColor: '#201E1E',
    borderRadius:" 50%",
    width: '30px',
    height: '30px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    '&:last-of-type': {
      marginRight: 0
    },
    '& .MuiIcon-root': {
      fontSize: '1rem',
      width: 'fit-content'
    }
  },
  '&.card-nav': {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#413F3F',
    width: '100%',
    padding: '14px',
    borderBottom: '1px solid #D9D9D9',
    '&.active, &:hover': {
      backgroundColor: '#D9D9D9'
    }
  },
  '&.button-link': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontWeight: '400',
    lineHeight: '17px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '0.875rem',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
    width: '100%',
    padding: '13px 15px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none'
    },
    '&.disabled, &.disabled:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.4',
      color: '#FFF',
      pointerEvents: 'none',
    },
  },
  '&.header': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontWeight: '400',
    lineHeight: '17px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '0.875rem',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
    width: '100%',
    padding: '6px 15px',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none'
    },
  },
  '&.outlined': {
    borderColor: '#413F3F',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: '#413F3F',
    fontWeight: '400',
    width: '100%',
    padding: '11px 15px',
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '3px',
    textTransform: 'uppercase',
    '& .MuiIcon-root': {
      fontSize: '1rem'
    },
    '&:hover': {
        borderColor: '#272727',
    },
  },
  '&.text': {
    fontSize: '0.875rem',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#736324'
    }
  }
}))

export default function Link({children, link, type, openTab, external, sx, disabled = ''}) {

  if (external) {
    return (
      <StyledLink sx={{...sx}} href={link}  className={type + disabled} target={openTab ? '_blank' : '' } rel="noreferrer noopener">{children}</StyledLink>
    )
  }
  else {
    return (
      <StyledLink sx={{...sx}} className={type} component={NavLink}  to={link}>{children}</StyledLink>
    )
  }
}
