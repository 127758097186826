import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppProvider } from './AppContext';
import { AccountProvider } from './components/features/account/context';
import { DigestProvider } from './components/features/digests/context';
import Layout from './components/layout/Layout';
import SelectPlan from './pages/GetStarted/SelectPlan';
import CreateAccount from './pages/GetStarted/CreateAccount';
import ConfirmAccount from './pages/GetStarted/ConfirmAccount';
import Digests from './pages/GetStarted/Digests';
import Steps from './components/layout/Steps';
import Newsletters from './pages/GetStarted/Newsletters';
import Data from './pages/GetStarted/Data';
import FinishRegistration from './pages/GetStarted/FinishRegistration';
import Account from './pages/Account';
import MainNavigation from './components/layout/MainNavigation';
import Subscriptions from './pages/Subscriptions';
import AuthWrapper from './components/wrappers/AuthWrapper';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/ForgotPassword';
import NewPassword from './pages/Auth/NewPassword';
import Group from './pages/Group';
import Payment from './pages/GetStarted/Payment';
import Home from './pages/Home';
import VerifyEmail from './pages/Activation/VerifyEmail';
import VerifyNewUser from './pages/Activation/VerifyNewUser';
import VerifyAccount from './pages/Activation/VerifyAccount';
import SelectSubscription from './pages/GetStarted/SelectSubscription';
import Logout from './pages/Auth/Logout';
import SignedOutWrapper from './components/wrappers/SignedOutWrapper';
import ShippingPreference from './pages/GetStarted/ShippingPreference';

function App() {
  return (
    <Router>
      <AppProvider>
        <AccountProvider>
          <DigestProvider>
            <Layout>
              <Routes>
                <Route path='/activation/verifyemail' element={<VerifyEmail />} />
                <Route element={<SignedOutWrapper />}>
                  <Route path='/login' element={<Login />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/activation'>
                    <Route path='/activation/forgotpassword' element={<NewPassword />} />
                    <Route path='/activation/newuser' element={<VerifyNewUser />} />
                    <Route path='/activation/newaccount' element={<VerifyAccount />} />
                  </Route>
                  <Route path='/membership-plan' element={<SelectPlan />} />
                  <Route path='/create-account'>
                    <Route path=':type' element={<CreateAccount />} />
                  </Route>
                 
                </Route>
                <Route element={<AuthWrapper />}>
                  <Route path='/confirm-account' element={<ConfirmAccount />} />
                  <Route path='/upgrade-subscription' element={<SelectSubscription />} />
                  <Route path='/payment-complete' element={<Payment />} /> 
                  <Route path='/shipping-preference'>
                    <Route path=':type' element={<ShippingPreference />} />
                  </Route>
                  <Route element={<Steps />}>
                    <Route path='/digests' element={<Digests />} />
                    <Route path='/newsletters' element={<Newsletters />} />
                    <Route path='/data' element={<Data />} />
                  </Route>
                  <Route path='/finish' element={<FinishRegistration />} />
                  <Route element={<MainNavigation />}>
                    <Route path='/account' element={<Account />} />
                    <Route path='/subscriptions' element={<Subscriptions />} />
                    <Route path='/group-membership' element={<Group />} />
                  </Route>
                  <Route path='/' element={<Home />} />
                  <Route path='*' element={<Account />} />
                </Route>
                <Route path='/logout' element={<Logout />} />
                <Route path='/' element={<Login />} />
                <Route path='*' element={<Login />} />
              </Routes>
            </Layout>
          </DigestProvider>
        </AccountProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
