import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Icon, IconButton, InputAdornment } from '@mui/material';

const StyledTextField = styled(TextField)(({theme}) => ({
    '&.MuiFormControl-root': {
        marginBottom: '16px',
    },
    '& .MuiInputBase-root': {
        borderRadius: '3px',
        border: '1px solid #B5B4B4',
        backgroundColor: '#ECEBEB',
        width: '100%',
        '&.Mui-focused': {
            backgroundColor: '#D9D9D9',
            borderColor: '#918F8F'
        },
        '&:hover': {
            backgroundColor: '#D9D9D9 !important'
        },
        '&.Mui-error': {
            borderColor: '#d32f2f'
        }
    },
    '& .MuiInputBase-input': {
        padding: '7px 10px',
        lineHeight: '10px',
        fontSize: '0.875rem',
    },
    '& .MuiSelect-filled': {
        fontSize: '0.875rem',
        lineHeight: '17px',
    },
    '& label': {
        transform: 'none',
        color: '#413F3F',
        fontSize: '0.875rem',
        top: '9px',
        left: '12px',
        textTransform: 'capitalize',
        display: 'block',
        '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'none',
            display: 'none'
        },
        '& .MuiInputLabel-asterisk': {
            color: '#d32f2f',
            fontWeight: '600'
        }
    }
}))

export default function Input(props) {
    const { passwordInput, ...others } = props;
    const [ showPassword, setShowPassword ] = React.useState(false);

    const handleShowPassword = () => {
        setShowPassword(show => !show);
    }
    const handleMouseDownPassword = e => {
        e.preventDefault();
    }
    if (passwordInput) {
        return (
          <StyledTextField
          {...others}
            variant='filled'
            InputProps={{endAdornment:
                <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {showPassword ?
                            <Icon sx={{fontSize: '1rem', width: '20px'}} baseClassName='fa-solid' className='fa-eye' />
                        :
                            <Icon sx={{fontSize: '1rem', width: '20px'}} baseClassName='fa-solid' className='fa-eye-slash' />
                        }
                    </IconButton>
                </InputAdornment>
            }}
            type={showPassword ? 'text' : 'password'}
            fullWidth />
        )
    } else {
        return (
          <StyledTextField variant='filled' {...others} fullWidth />
        )
    }
}