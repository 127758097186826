import React, { useEffect, useState } from 'react'
import { Dialog, Grid, List, ListItem } from '@mui/material'
import StyledContainer from '../components/UI/atoms/StyledContainer'
import Text from '../components/UI/atoms/Text'
import Card from '../components/UI/molecules/Card'
import AddressForm from '../components/UI/organisms/AddressForm'
import Digest from '../components/UI/organisms/Digest'
import Newsletter from '../components/UI/organisms/Newsletter'
import { useDigest } from '../components/features/digests/context'
import Divider from '../components/UI/atoms/Divider'
import { useAccount } from '../components/features/account/context'
import { useApp } from '../AppContext'
import { postCancelSubscription, postReviveSubscription } from '../components/features/account/middleware'
import DialogMessage from '../components/UI/molecules/DialogMessage'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import SubscriptionActions from '../components/UI/molecules/SubscriptionActions'
import SubscriptionMessage from '../components/UI/molecules/SubscriptionMessage'
import { isDateAhead } from '../lib/dateDifference'

const cookies = new Cookies();

export default function Subscriptions() {
    const { handleSetSubsDigNews } = useDigest();
    const { planInfo, currentSubscription, handleClearCurrentSubs } = useAccount();
    const { user, handleLoading, handleMessage } = useApp();
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const navigate = useNavigate();
    const [trial, setTrial] = useState(false);

    const handleClickOpen = (type) => {
        setOpen(true);
        setDialogType(type);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        if (currentSubscription === '') {
            handleSetSubsDigNews();
        }
        if(currentSubscription !== "" && currentSubscription.subscription !== null && 
            currentSubscription.subscription.trialEndDate !== null &&
            new Date(currentSubscription.subscription.trialEndDate) > new Date())
            {
             setTrial(true);
            }
        // eslint-disable-next-line
    }, [currentSubscription]);
    
    const handleCancel = () => {
        handleClose();
        handleLoading(true);
        postCancelSubscription().then(() => {
            handleLoading(false, true);
            handleMessage('success', 'Subscription has been cancelled');
        }).then(() => {
            handleClearCurrentSubs();
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }

    const handleRenew = () => {
        handleClose();
        handleLoading(true);
        postReviveSubscription('').then(() => {
            handleLoading(false, true);
            handleMessage('success', 'Subscription renewed succesfully!');
        }).then(() => {
            handleClearCurrentSubs();
        }).catch(err => {
            handleLoading(false, true);
            handleMessage('error', err.response.headers.errormessage);
        })
    }
    const handleUpgradeToPaid = () => {
        cookies.set('prev', window.location.href);
        navigate('/upgrade-subscription', {from: '/subscriptions'});
    }

  return (
    <StyledContainer sx={{maxWidth: '1000px'}}>
        <SubscriptionMessage currentSubscription={currentSubscription} user={user} />
        <Text type='h1' sx={{ textAlign: 'left'}}>Subscriptions</Text>
        <Grid container spacing={2} sx={{marginTop: 0}}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card className='card-info'>
                    {!currentSubscription ? 
                        <Text>Loading...</Text>
                    :
                        <React.Fragment>
                            <Text className='subheader'>Current Subscription</Text>
                            <Text className='header'>{planInfo.readableName}</Text>
                            <Divider />
                            {(currentSubscription.subscription && isDateAhead(currentSubscription.subscription.renewalDate)) &&
                                <Text className='renewal-date' sx={{mb: '20px'}}>Renewal date <span className='date'>{new Date(currentSubscription.subscription.renewalDate).toLocaleDateString()}</span></Text>
                            }
                            <Text className='header-h2'>Features</Text>
                            <List sx={{padding: 0, marginLeft: '5px'}}>
                                {planInfo.features.information.map((feature, i) => (
                                    <ListItem key={`feature-${i}`} sx={{padding: '0', paddingLeft: '16px', '&::before': {content: '"."', fontSize: '29px', position: 'absolute', left: '0', top: '-17px'}}}>
                                        <Text>{feature}</Text>
                                    </ListItem>
                                ))}
                            </List>
                            <SubscriptionActions user={user} currentSubscription={currentSubscription} handleClickOpen={handleClickOpen} />
                        </React.Fragment>
                    }
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                {user.mappedProductName !== 'Free' && trial == false && (
                    <AddressForm header disablePrint={user.isComplimentaryUser || !user.isRootUser} 
                    isCheckbox={user.isComplimentaryUser || !user.currentSubscriptionId || user.currentSubscriptionId.includes('existing') 
                    || user.currentSubscriptionId.includes('manual')} singleOption={user.subscriptionType} />
                )}
            </Grid>
            <Grid item xs={12} >
                <Text type='h1' sx={{mb: '20px'}}>Digests</Text>
                <Digest saved />
                <Text type='h1' sx={{mb: '20px'}}>Newsletters</Text>
                <Newsletter saved />
            </Grid>
        </Grid>
        {currentSubscription && (
            <Dialog onClose={handleClose} open={open}>
                <DialogMessage
                    dialogType={dialogType}
                    handleClose={() =>handleClose()}
                    handleCancel={() => handleCancel()}
                    handleRenew={() => handleRenew()}
                    data={currentSubscription}
                    handleUpgrade={() => handleUpgradeToPaid()}
                />
            </Dialog>
        )}
    </StyledContainer>
  )
}
