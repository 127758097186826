export const tierName = tier => {
    if (tier.upperLimit === 1) {
        return 'Single User';
    } else if (tier.upperLimit === -1) {
        return `${tier.lowerLimit}+ Licenses`;
    } else {
        return `${tier.lowerLimit}-${tier.upperLimit} Licenses`;
    }
}
export const tierDiscount = (prices, tier) => {
    if (tier.upperLimit === 1) {
        return 'Regular Price';
    } else {
        const regularPrice = prices[0].price;
        const percentage = Math.round((tier.price * 100) / regularPrice);
        const disc = 100 - percentage;
        return `${disc}% off`;
    }
}