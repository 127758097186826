import React, { useState, useEffect } from 'react'
import { Dialog, Grid, List, ListItem as MuiListItem } from '@mui/material'
import StyledContainer from '../components/UI/atoms/StyledContainer'
import Text from '../components/UI/atoms/Text'
import Card from '../components/UI/molecules/Card'
import Button from '../components/UI/atoms/Button'
import { useApp } from '../AppContext'
import ListItem from '../components/UI/atoms/ListItem'
import { useAccount } from '../components/features/account/context'
import DialogMessage from '../components/UI/molecules/DialogMessage'
import { formatPlanFee } from '../lib/formatPlanFee'

const StyledFlexContainer = ({children}) => {
    return (
        <StyledContainer sx={{display: 'flex', my: '20px', alignItems: 'baseline'}}>{children}</StyledContainer>
    )
}

export default function Group() {
    const { user } = useApp();
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const [userSelected, setUserSelected] = useState(null);
    const { handleDeleteUser, handleGetGroup, group, handleAddLicenses, handleAddUser, handleCancelGroup } = useAccount();

    useEffect(() => {
        if (!group) {
            handleGetGroup();
        }
    }, [group]);

    const handleClickOpen = type => {
        setOpen(true);
        setDialogType(type);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const canCancel = true;
    const canEditGroup = !user.currentSubscriptionId.includes('manual');

    const handleRemove = memberId => {
        setOpen(true);
        setDialogType('remove');
        setUserSelected(memberId);
    }
    const handleRemoveUser = () => {
        handleDeleteUser(userSelected, handleClose);
    }
    const handleAdd = amount => {
        handleAddLicenses(amount, handleClose);
    }
    const handleAssign = (form) => {
        handleAddUser(form, handleClose);
    }
    const handleCancel = () => {
        handleCancelGroup(handleClose);
    }
    
  return (
    <StyledContainer className='group-membership'  sx={{maxWidth: '1000px'}}>
        <Text type='h1' sx={{testAlign: 'left'}}>Group Membership</Text>
        {(!group) ?
            <Text>Loading</Text>
        : group.subscription.userCancelled && group.subscription.stripeCancelled ?
            <Card>
                <Text>Your subscription has been cancelled.</Text>
                <Text>You no longer have access to managing your group.</Text>
            </Card>
        : group.subscription.userCancelled ?
            <Card>
                <Text>Your subscription has been cancelled.</Text>
                <Text>To get access to your group management, first renew your membership in the Subscriptions page.</Text>
            </Card>
        :
            <React.Fragment>
                <Grid container spacing={2} sx={{mt: 0}}>
                    <Grid item xs={12} sm={12} md={6} sx={{pt: '20px !important'}}>
                        <Card sx={{m: 0}}>
                            <Text className='memb-legend uppercase'>Current Subscription</Text>
                            <Text sx={{fontWeight: 700, fontSize: '1.5rem', lineHeight: '25px', textTransform: 'uppercase'}}>{group.selectedProduct.productName}</Text>
                            <StyledContainer className='wrapper'>
                                <Text className='memb-label' sx={{mt: '20px'}}> Renewal Date</Text>
                                <Text className='memb-info'>{new Date(group.subscription.renewalDate || group.subscription.subscriptionEndDate).toLocaleDateString()}</Text>
                            </StyledContainer>
                            <StyledFlexContainer >
                                <Text className='memb-label'>{group.firm.seatAlloted} licenses</Text>
                                {group.firm.seatAlloted > group.userList.length &&
                                    <Text className='memb-legend'>{group.firm.seatAlloted - group.userList.length} remaining</Text>
                                }
                            </StyledFlexContainer>
                            <Text className='memb-info'>{formatPlanFee(group.selectedProduct.renewalType, group.subscription.renewalCost)}</Text>
                            {canEditGroup &&
                                <Grid container spacing={2} sx={{mt: '4px'}}>
                                    <Grid item xs={12} sm={6} md={12} lg={6}>
                                        <Button disabled={(!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing'))} onClick={() => {handleClickOpen('license')}}>Add Licences</Button>
                                    </Grid>
                                    {canCancel && 
                                        <Grid item xs={12} sm={6} md={12} lg={6}>
                                            <Button disabled={!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing')} onClick={() => handleClickOpen('cancel')} type='text'>Cancel Membership</Button>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12}  sx={{pt: '20px !important'}}>
                        <StyledContainer sx={{mb: '20px', display: 'flex'}}>
                            <Text type='h1' sx={{testAlign: 'left'}}>Group Users</Text>
                            {group.firm.seatAlloted > group.userList.length ?
                                <Text className='subtitle-text'>{group.firm.seatAlloted - group.userList.length}/{group.firm.seatAlloted} Licenses remaining</Text>
                            :
                                <Text className='subtitle-text'>{group.firm.seatAlloted} Licenses</Text>
                            }
                        </StyledContainer>
                        <Card sx={{m:0, p: 0}}>
                            <List sx={{p: 0}}>
                                <ListItem isAdmin data={group.userList.find(user => user.isRootUser)} key={0} />
                                {group.firm.seatAlloted > group.userList.length && (
                                    <MuiListItem className='group-member'>
                                        <Button disabled={!user.currentSubscriptionId || user.currentSubscriptionId.includes('existing')} icon={{end: true, base: 'fa-light', type: 'fa-square-plus'}} className='assign-user-btn' onClick={() => handleClickOpen('assign')}>
                                            <StyledContainer sx={{display: 'flex'}}>
                                                <Text className='label'>Assign User</Text>
                                                <Text className='seats'>{group.firm.seatAlloted - group.userList.length}/{group.firm.seatAlloted} Licences remaining</Text>
                                            </StyledContainer>
                                        </Button>
                                    </MuiListItem>
                                )}
                                {group.userList.filter(user => !user.isRootUser).map((member, key) => (
                                    <ListItem key={key} data={member} handleRemove={handleRemove} />
                                ))}
                            </List>
                        </Card>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} open={open}>
                    <DialogMessage
                        dialogType={dialogType}
                        data={group}
                        group
                        removeMember={handleRemoveUser}
                        handleClose={handleClose}
                        handleCancel={handleCancel}
                        handleAssign={handleAssign}
                        handleAdd={handleAdd}
                        handleDialogType={setDialogType}
                    />
                </Dialog>
            </React.Fragment>
        }
    </StyledContainer>
  )
}
