import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useApp } from '../../AppContext';
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import Text from '../../components/UI/atoms/Text';
import Card from '../../components/UI/molecules/Card';
import { verifyEmail } from '../../services/authMiddleware';

export default function VerifyEmail() {
    const [ searchParams ] = useSearchParams();
    const [ valid, setValid ] = useState(null);
    const { handleLoading, handleMessage } = useApp();

    useEffect(() => {
        const code = searchParams.get('code');
        verifyEmail(code).then(data => {
            setValid(true);
            handleLoading(false);
            setTimeout(() => {
                window.location.href = '/login';
            }, 500);
        }).catch(err => {
            setValid(false);
            handleLoading(false);
            handleMessage('error', err.response.headers.errormessage);
        })
    }, []);

    if (valid) {
        return (
            <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
                <Card sx={{maxWidth: '400px'}}>
                    <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Email Address Verified!</Text>
                    <Text>Your email has been verified successfully.</Text>
                </Card>
            </StyledContainer>
        )
    }
    else if (valid === false){
        return (
            <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
                <Card sx={{maxWidth: '400px'}}>
                    <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Verification failed</Text>
                </Card>
            </StyledContainer>
        )
    }
    else {
        return (
            <div></div>
        )
    }
}
