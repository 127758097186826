import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Icon, MenuItem, styled, Snackbar, Alert 
, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import Input from '../atoms/Input'
import Text from '../atoms/Text'
import Card from '../molecules/Card'
import PrintDigitalForm from '../molecules/PrintDigitalForm'
import StyledContainer from '../atoms/StyledContainer'
import Divider from '../atoms/Divider'
import { useAccount } from '../../features/account/context'
import Button from '../atoms/Button'
import { useEffect } from 'react'
import { validateInputsFilled } from '../../../lib/validateInputsFilled'
import { useApp } from '../../../AppContext'
import { displayPrint } from '../../../lib/displayPrint'
import { formatPhoneInput } from '../../../lib/formatPhoneInput'


const StyledMenuItem = styled(MenuItem)(() => ({
    fontSize: '0.875rem',
    padding: '2px 8px'
}))

export default function AddressForm({ header, disablePrint, isCheckbox, singleOption }) {
    const { user } = useApp();
    const { addressForm, handleGetStates,  countries, states, addressFormValidation, handleAddressForm,
         digital, shippingFee, handleShippingPreference } = useAccount();
    const [ originalForm ] = useState(addressForm);
    const [ expanded, setExpanded ] = useState(digital);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');


  const handlePostAddressForPrint = () => {
    if(addressForm.address_Country == "" || addressForm.address_State == "" ||
        addressForm.address_City == "" || addressForm.address_Line_1 == "" ||
        addressForm.address_Zip == "" || addressForm.copies == "" ||
        addressForm.fullName == "" || addressForm.phoneNo == "" ){
            setOpenErrorSnackbar(true);
    }
    else
    {
    const dynamicMessage = `Are you sure you want to confirm Print Edition selection for a Shipping Fee:`;
    setMessage(dynamicMessage);
    setOpenDialog(true);
    }
  };

  const handlePostAddressForDigital = (value) => {
    // Your existing logic to determine the message
    const dynamicMessage = `Are you sure you want to confirm the DIGITAL edition selection: ${value ? 'Yes' : 'No'}?`;
    setMessage(dynamicMessage);
    setOpenDialog(true);
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  const handleCloseDialog = (confirmed) => {
    setOpenDialog(false);
    if (confirmed) {
        handleShippingPreference();
      setOpenSnackbar(true);
    }
  };

    // close accordion when membership is digital
    useEffect(() => {
        setExpanded(!digital);
    }, [digital]);

    const handleChange = () => {
        setExpanded(!expanded);
    }

    return (
    <Card type='paddless card-address' sx={{m: 0, mb: header ? 0 : '20px'}}>
        {header && (
            <StyledContainer sx={{padding: '20px'}}>
                <Text sx={{color: '#B5B4B4', fontSize: '0.875rem', lineHeight: '17px', textTransform: 'uppercase'}}>The Northen Miner</Text>
                <Text sx={{fontWeight: 700, fontSize: '1.5rem', lineHeight: '25px', textTransform: 'uppercase'}}>{(displayPrint(user) && !digital) ? 'Print + ' : ''} Digital</Text>
                <Divider />
                <PrintDigitalForm disablePrint={disablePrint} isCheckbox={isCheckbox} singleOption={singleOption} />
            </StyledContainer>
        )}
        {(displayPrint(user) && !digital) ?
            <Accordion expanded={expanded} onChange={() => handleChange()} disabled={digital} sx={{margin: '0px !important'}}>
                <AccordionSummary
                    id={'shipping'}
                    aria-controls={'shipping-content'}
                    expandIcon={
                        <Icon baseClassName='fa-solid' className='fa-caret-down'
                        sx={{color: '#413F3F', fontSize: '16px'}} />
                    }
                    sx={{
                        minHeight: 'min-content',
                        px: '10px',
                        '&.Mui-expanded': {
                            minHeight: 'fit-content',
                            borderBottom: '1px solid #ECEBEB',
                            borderTop: '1px solid #ECEBEB'
                        },
                        '& .Mui-expanded, & .MuiAccordionSummary-content': {
                            my: '10px !important',
                        }
                    }}
                >
                        <Text sx={{fontWeight: 700, fontSize: '0.875rem', lineHeight: '17px', textTransform: 'uppercase'}}>Shipping Address </Text>
                      
                </AccordionSummary>
                <AccordionDetails sx={{padding: '20px'}}>
                    <Box component='form'>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Text>Full Name <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.fullName} label='Full Name' id='fullName' value={addressForm.fullName} onChange={((e) => handleAddressForm(e))} />
                            </Grid>
                            <Grid item xs={12}>
                                <Text>Company (Optional)</Text>
                                <Input label='Company' id='companyName' value={addressForm.companyName} onChange={((e) => handleAddressForm(e))} />
                            </Grid>
                            <Grid item xs={12}>
                                <Text>Address <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.address_Line_1} label='Address Line 1' id='address_Line_1' value={addressForm.address_Line_1} onChange={((e) => handleAddressForm(e))} />
                                <Input label='Address Line 2' id='address_Line_2' value={addressForm.address_Line_2} onChange={((e) => handleAddressForm(e))} />
                            </Grid>
                            <Grid item xs={12}>
                                <Text>City <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.address_City} label='City' id='address_City'  value={addressForm.address_City} onChange={((e) => handleAddressForm(e))}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Text>Country <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.address_Country} label='Country' name='address_Country' select value={addressForm.address_Country} onChange={((e) => handleGetStates(e))}>
                                    <StyledMenuItem disabled value='' sx={{display: 'none'}}>Country</StyledMenuItem>
                                    {countries.length ?
                                        countries.map((country, index) =>  (
                                            <StyledMenuItem name='country' key={index} value={country.value}>{country.text}</StyledMenuItem>
                                        ))
                                    : (
                                        <StyledMenuItem>Options...</StyledMenuItem>
                                    )}
                                </Input>
                                {/* <Input label='Country' id='address_Country' value={addressForm.address_Country} onChange={((e) => handleAddressForm(e))}/> */}
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Text>Province/State/Region<span style={{color: '#d32f2f', fontWeight: '600'}}>*</span></Text>
                                <Input error={!addressFormValidation.address_State} label='Province' name='address_State' select value={addressForm.address_State} onChange={((e) => handleAddressForm(e, 'select'))}>
                                <StyledMenuItem disabled value='' sx={{display: 'none'}}>State</StyledMenuItem>
                                    {states.length ?
                                        states.map(state => (
                                            <StyledMenuItem name='address_State' key={state.value} value={state.value}>{state.text}</StyledMenuItem>
                                        ))
                                    : (
                                        <StyledMenuItem>Options...</StyledMenuItem>
                                    )}
                                </Input>
                                {/* <Input label='Province' id='address_State' value={addressForm.address_State} onChange={((e) => handleAddressForm(e))}/> */}
                            </Grid>
                            <Grid item xs={12}>
                                <Text>ZIP/Postal Code <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.address_Zip} sx={{'&.MuiFormControl-root': {mb: 0}}} disabled={digital} label='ZIP Code' id='address_Zip'  value={addressForm.address_Zip} onChange={((e) => handleAddressForm(e))}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Text>Phone Number <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.phoneNo} sx={{'&.MuiFormControl-root': {mb: 0}}} label='Phone Number' id='phoneNo'  value={formatPhoneInput(addressForm.phoneNo)} onChange={((e) => handleAddressForm(e))}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Text>Copies <span style={{color: '#d32f2f', fontWeight: '600'}}>* </span></Text>
                                <Input error={!addressFormValidation.copies} sx={{'&.MuiFormControl-root': {mb: 0}}} label='Copies' id='copies'  value={addressForm.copies} onChange={((e) => handleAddressForm(e))}/>
                            </Grid>
                            <Grid item xs ={12}>
                            <Text sx={{fontWeight: 700, marginTop:'1rem', fontSize: '0.875rem', lineHeight: '17px'}}>{shippingFee !== null && (
                                // <span style={{ color: 'green', marginLeft: '10px' }}>
                                <span style={{color: 'green', fontWeight: '800'}}>
                                 Shipping Fee: ${shippingFee}
                                 </span>
                            )}
                           </Text>
                            </Grid>
                        </Grid>
                        <Divider />
                        <div>
                        <Button onClick={() => handlePostAddressForPrint(true)} >Update Print Edition</Button>
                        <Dialog
                                open={openDialog}
                                onClose={() => handleCloseDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <p>{message} <b style={{color:'red'}}>{shippingFee == null ? 0: shippingFee}{'CAD'} </b> {' ?'}</p> 
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={() => handleCloseDialog(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => handleCloseDialog(true)} color="primary" autoFocus>
                                    Confirm
                                </Button>
                                </DialogActions>
                            </Dialog>

                          
                            <Snackbar
                                open={openErrorSnackbar}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                                PLease enter all the required address fields
                                </Alert>
                            </Snackbar>
                        </div>
                    </Box>
                </AccordionDetails>
            </Accordion>
        :
            <React.Fragment></React.Fragment>
        }
        {digital ?
         <div>
         <Button onClick={() => handlePostAddressForDigital(true)} >Update Digital Edition</Button> 
                <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleCloseDialog(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleCloseDialog(true)} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>

          
         </div>:
            <React.Fragment></React.Fragment>}
         
    </Card>
  )
}
