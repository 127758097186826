import { DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import Button from '../atoms/Button'
import Input from '../atoms/Input'
import StyledContainer from '../atoms/StyledContainer'
import Text from '../atoms/Text'
import tiers from '../../../data/groupfees.json';
import helperText from '../../../data/validationMessage.json'
import { groupMembershipPriceAdditional, groupMembershipPriceTotal, groupMembershipTier } from '../../../lib/groupMembershipPrice'
import { validateInputsFilled } from '../../../lib/validateInputsFilled'
import { dateDifference } from '../../../lib/dateDifference'
import { tierDiscount, tierName } from '../../../lib/formatGroupPrices'

export default function DialogMessage(props) {

    const [ seats, setSeats ] = React.useState(props.group ? props.data.firm.seatAlloted : null);
    const [ tier, setTier ] = React.useState(props.group ? groupMembershipTier(props.data.groupPrices.pricing, seats) : null);
    const [ prices, setPrices ] = React.useState(props.group ? {
        additional: groupMembershipPriceAdditional(props.data.groupPrices.pricing, seats, props.data.firm.seatAlloted),
        total: groupMembershipPriceTotal(props.data.groupPrices.pricing, seats),
        perSeat: groupMembershipPriceTotal(props.data.groupPrices.pricing, seats) / seats
    } : null);

    const handleChange = (type, e) => {
        let tempSeat;
        if (type === 'input') {
            if (e.target.value.match("^[0-9]+$")) {
                tempSeat = e.target.value;
            }
        } else if (type === 'plus') {
            tempSeat = seats + 1;
        } else {
            tempSeat = seats - 1;
        }
        setSeats(tempSeat);
        setTier(groupMembershipTier(props.data.groupPrices.pricing, tempSeat));
        const totalPrice = groupMembershipPriceTotal(props.data.groupPrices.pricing, tempSeat);
        const perSeat = totalPrice / tempSeat;
        const additionalPrice = groupMembershipPriceAdditional(props.data.groupPrices.pricing, tempSeat, props.data.firm.seatAlloted)
        setPrices({total: totalPrice, perSeat: perSeat, additional: additionalPrice})
    }

    const numberWithCommas = (x) => {
        var parts = x.toFixed(2).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    // assign form
    const [ form, setForm ] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
    })
    const [ formValidation, setFormValidation ] = React.useState({
        firstName:  true,
        lastName: true,
        email: true,
    })
    const [ disableSave, setDisableSave ] = React.useState(true);


    const handleValidateInput = (value, input) => {
        if (input === 'email') {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return value.match(validRegex) ? true : false;
        }
        else {
            return value !== '';
        }
    }
    const handleInput = e => {
        setForm({...form, [e.target.id]: e.target.value});
        const validate = handleValidateInput(e.target.value, e.target.id);
        setFormValidation({...formValidation, [e.target.id]: validate});
        
    }

    React.useEffect(() => {
        setDisableSave(validateInputsFilled(form, formValidation));
    }, [form]);

    switch (props.dialogType) {
        case 'cancel':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px'}}>
                        <Text sx={{textAlign: 'center'}} type='h1'>Cancel Membership</Text>
                    </DialogTitle>
                    <DialogContent>
                        {(dateDifference(props.data.subscription.trialStartDate, 'refundPeriod') && !props.data.subscription.trialEndDate) ?
                            <React.Fragment>
                                <Text sx={{textAlign: 'center'}}>TNM Memberhip offers a 30-day free full refund policy. By cancelling your subscription, you will be issued a refund and won't be able to renew your subscription.</Text>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <Text sx={{textAlign: 'center'}}>Your subscription will be canceled at the end of the billing cycle. Until then, you can keep enjoying the benefits of your TNM Membership.</Text>
                                <Text sx={{my: '20px', textAlign: 'center'}}>You can reactivate your TNM Membership any time before the end of the cycle to keep enjoying your benefits.</Text>
                                <Text sx={{textAlign: 'center'}} className='renewal-date'>Renewal Date <span className='date'>{new Date(props.data.subscription.renewalDate).toLocaleDateString()}</span></Text>
                            </React.Fragment>
                        }
                    </DialogContent>
                    <DialogActions sx={{padding: '20px'}}>
                        <Button onClick={() => props.handleCancel()}>Confirm</Button>
                        <Button type='text' onClick={() => props.handleClose()}>Cancel</Button>
                    </DialogActions>
                </React.Fragment>
            )
        case 'renew':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px'}}>
                        <Text type='h1' sx={{textAlign: 'center'}}>Renew Subscription</Text>
                    </DialogTitle>
                    <DialogContent>
                        <Text>Are you sure you want to renew your subscription?</Text>
                    </DialogContent>
                    <DialogActions sx={{padding: '20px'}}>
                        <Button onClick={() => props.handleRenew()}>Confirm</Button>
                        <Button type='text' onClick={() => props.handleClose()}>Cancel</Button>
                    </DialogActions>
                </React.Fragment>
            )
        case 'license':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px', paddingBottom: 0}}>
                        <Text className='memb-legend uppercase'>Add/Remove licenses</Text>
                        <Text type='h1'>{props.data.selectedProduct.productName}</Text>
                    </DialogTitle>
                    <DialogContent className='license'>
                        <StyledContainer className='wrapper'>
                            <Text className='memb-label'>Current seats</Text>
                            {props.data.firm.seatAlloted >= props.data.userList.length ?
                                <Text className='memb-legend' sx={{ml: '10px'}}>{props.data.firm.seatAlloted - props.data.userList.length} remaining</Text>
                                :
                                <Text className='memb-info' sx={{ml: '10px'}}>{props.data.firm.seatAlloted} total</Text>
                            }
                        </StyledContainer>
                        <StyledContainer className='wrapper between'>
                            <Text className='memb-label'>Billing Period</Text>
                            <Text className='memb-info'>{props.data.selectedProduct.renewalType}</Text>
                        </StyledContainer>
                        <StyledContainer className='wrapper between'>
                            <Text className='memb-label'>Licenses</Text>
                            <StyledContainer sx={{display: 'flex'}}>
                                <Input value={seats} onChange={(e) => handleChange('input', e)} />
                                <Button className='edit-seat plus' onClick={() => handleChange('plus')}>+</Button>
                                <Button disabled={props.data.userList.length >= seats} className='edit-seat minus' onClick={() => handleChange('minus')}>-</Button>
                            </StyledContainer>
                        </StyledContainer>
                        <div>
                            <StyledContainer className='wrapper between child'>
                                <Text className='memb-label'>Group Rate</Text>
                                <Text>{tierName(tier)}</Text>
                                <Text>{tierDiscount(props.data.groupPrices.pricing, tier)}</Text>
                            </StyledContainer>
                            <Button type='text' className='text-license' onClick={() => props.handleDialogType('breakdown')}>View Pricing Breakdown</Button>
                        </div>
                        <StyledContainer className='wrapper between'>
                            <Text className='label'>Your Price Per Seat</Text>
                            <Text>${numberWithCommas(prices.perSeat)}</Text>
                        </StyledContainer>
                        <StyledContainer className='wrapper between'>
                            <Text className='label'>Total Additional Cost</Text>
                            <Text>${numberWithCommas(prices.additional)}</Text>
                        </StyledContainer>
                        {/* <StyledContainer className='wrapper between'>
                            <Text className='label'>Total Final Cost</Text>
                            <Text>${numberWithCommas(prices.total)}</Text>
                        </StyledContainer> */}
                    </DialogContent>
                    <DialogActions sx={{padding: '20px'}}>
                        <Button sx={{whiteSpace: 'nowrap'}} onClick={() => props.handleAdd(seats)}>Update License(s)</Button>
                        <Button type='text' onClick={() => props.handleClose()}>Cancel</Button>
                    </DialogActions>
                </React.Fragment>
            )
        case 'breakdown':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px', paddingBottom: 0}}>
                        <Text className='memb-legend uppercase'>Pricing Breakdown</Text>
                        <Text type='h1'>{props.data.selectedProduct.productName}</Text>
                    </DialogTitle>
                    <DialogContent className='license'>
                        <Table aria-label='price breakdown table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Text className='memb-label'>Tier</Text></TableCell>
                                    <TableCell>
                                        <Text className='memb-label'>Rate <span className='sublabel'>Per License</span></Text>
                                    </TableCell>
                                    <TableCell><Text className='memb-label'>Discount</Text></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell><Text sx={{textAlign: 'center'}}>{props.data.selectedProduct.renewalType}</Text></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.groupPrices.pricing.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell><Text>{tierName(row)}</Text></TableCell>
                                        <TableCell><Text>${numberWithCommas(row.price)}</Text></TableCell>
                                        <TableCell><Text>{tierDiscount(props.data.groupPrices.pricing, row)}</Text></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions sx={{justifyContent: 'flex-start'}}>
                        <Button type='text' className='text-license' onClick={() => props.handleDialogType('license')}>Back to Add Licence(s)</Button>
                    </DialogActions>
                </React.Fragment>
            )
        case 'remove':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px'}}>
                        <Text type='h1' sx={{textAlign: 'center'}}>Remove Member</Text>
                    </DialogTitle>
                    <DialogContent>
                        <Text sx={{textAlign: 'center'}}>Are you sure you want to remove this member from your group subscription?</Text>
                    </DialogContent>
                    <DialogActions sx={{padding: '20px'}}>
                        <Button onClick={() => props.removeMember()}>Confirm</Button>
                        <Button type='text' onClick={() => props.handleClose()}>Cancel</Button>
                    </DialogActions>
                </React.Fragment>
            )
        case 'assign':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px', paddingBottom: 0}}>
                        <Text className='memb-legend uppercase'>Assign User</Text>
                        <Text type='h1'>{props.data.productName}</Text>
                    </DialogTitle>
                    <DialogContent className='assign'>
                        <Input id='firstName' value={form.firstName} onChange={e => handleInput(e)} error={!formValidation.firstName} helperText={!formValidation.firstName && helperText.data.firstName} label='First Name' />
                        <Input id='lastName' value={form.lastName} onChange={e => handleInput(e)} error={!formValidation.lastName} helperText={ !formValidation.lastName && helperText.data.lastName} label='Last Name' />
                        <Input id='email' value={form.email} onChange={e => handleInput(e)} error={!formValidation.email} helperText={!formValidation.email && helperText.data.email} label='Email' />
                    </DialogContent>
                    <DialogActions sx={{padding: '20px'}}>
                        <Button disabled={disableSave} onClick={() => props.handleAssign(form)}>Assign User</Button>
                        <Button type='text' onClick={() => props.handleClose()}>Cancel</Button>
                    </DialogActions>
                </React.Fragment>
            )
        case 'upgrade':
            return (
                <React.Fragment>
                    <DialogTitle sx={{padding: '20px'}}>
                        <Text type='h1' sx={{textAlign: 'center'}}>Upgrade Subcription</Text>
                    </DialogTitle>
                    <DialogContent>
                        <Text sx={{textAlign: 'center'}}>Are you sure you want to upgrade your subscription?</Text>
                        <Text sx={{textAlign: 'center', mt: '10px'}}>You will be redirected to select your new subscription plan.</Text>
                    </DialogContent>
                    <DialogActions sx={{padding: '20px'}}>
                        <Button onClick={() => props.handleUpgrade()}>Upgrade</Button>
                        <Button type='text' onClick={() => props.handleClose()}>Cancel</Button>
                    </DialogActions>
                </React.Fragment>
            )
        default:
            return (
                <React.Fragment></React.Fragment>
            )
    }
}
