import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getLogin } from '../../services/getLogin';
import StyledContainer from '../../components/UI/atoms/StyledContainer'
import Text from '../../components/UI/atoms/Text'
import Card from '../../components/UI/molecules/Card'
import { useApp } from '../../AppContext';
import Button from '../../components/UI/atoms/Button';
import Input from '../../components/UI/atoms/Input';
import Link from '../../components/UI/atoms/Link';
import { validateInputsFilled } from '../../lib/validateInputsFilled';
import { emptyObject } from '../../lib/emptyObject';
import { getCachedToken, getRedisKey, verifyToken } from '../../services/authMiddleware';
import ResetPasswordModal from '../../components/UI/molecules/ResetPasswordModal';
import useToken from '../../components/features/token/useToken';
import { createNewSessionCookies } from '../../lib/validCookie';

export default function Login() {
    const { handleLoading, handleUser, user, handleMessage } = useApp();
    const [ form, setForm ] = useState({username: '', password: ''});
    const [ formValidate, setFormValidate ] = useState({username: true, password: true});
    const [ disableSave, setDisableSave ] = useState(true);
    const [ loggedIn, setLoggedIn ] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const from = searchParams.get('src');
    const { doesTokenExist, token } = useToken();
    
    useEffect(() => {
        // check query src
        if (from) { // if present, verify token
            if (doesTokenExist){ // if token exists
                verifyToken(token).then(data => {
                    if (data.data) { // if valid, request new rediskey
                        getRedisKey().then(res => {
                            const conector = from.indexOf("?") > -1 ? '&' : '?';
                            window.location.href = 'https://' + from + `${conector}key=` + res.data;
                        }).catch(() => {
                            handleLoading(false);
                        })
                    } else { // if not valid, load login form
                        handleLoading(false);
                    }
                }).catch(() => {
                    handleLoading(false);
                })
            } else {
                handleLoading(false);
            }
        } else { // if not present, load login form
            handleLoading(false);
        }
    }, []);

    useEffect(() => {
        setDisableSave(validateInputsFilled(form, formValidate));
    }, [form]);

    useEffect(() => {
        if (!emptyObject(user) && loggedIn) {
            let from;
            if (location.state?.from) {
                from = location.state.from.pathname;
            }
            else if (user.finishedWizard) {
                from = '/account';
            } 
            else {
                 from = '/digests';
              //  from = '/account';
            }
            window.location.href = from;
        }
    }, [user, loggedIn]);
    
    const handleInput = e => {
        const id = e.target.id;
        const value = e.target.value;
        let valid;
        if (id === 'username') {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            valid = value.match(validRegex) ? true : false;
        } else {
            valid =  value !== '';
        }
        setForm({...form, [id]: value});
        setFormValidate({...formValidate, [id]: valid});
    }
    
    // Reset password modal
    const [ open, setOpen ] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };
    
    const handleLogin = e => {
        console.log(e);
        e.preventDefault();
        handleLoading(true);
        
        if (from) {
            form.isRedirectRequired = true;
        }
        const loginForm = {...form};
        loginForm.username = loginForm.username.trim();

        getLogin(loginForm).then(data => {
            if (from) {
                getCachedToken(data.data).then(res => {
                    createNewSessionCookies(res.data);
                }).then(() => {
                    const conector = from.indexOf("?") > -1 ? '&' : '?';
                    window.location.href = 'https://' + from + `${conector}key=` + data.data;
                }).catch(err => {
                    handleLoading(false, true);
                    handleMessage('error', err.response.headers.errormessage);
                })
            } else {
                createNewSessionCookies(data.data);
                handleUser();
                setLoggedIn(true);
            }
        }).catch(err => {
            handleLoading(false, true);
            if (err.response.headers.errormessage.includes("new platform")) {
                setOpen(true);
            } else {
                handleMessage('error', 'The username or password you have entered is incorrect.');
            }
        })
    }
    
    return (
        <StyledContainer sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, right: 0}}>
            <Card sx={{maxWidth: '400px'}}>
                <form onSubmit={handleLogin}>
                    <Text type='h1' sx={{textAlign: 'center', mb: '20px'}}>Member Login</Text>
                    <Input error={!formValidate.username} helperText={!formValidate.username && 'Enter a valid email'} label='email' type='email' id='username' value={form.username} onChange={(e) => handleInput(e)}/>
                    <Input passwordInput label='password' id='password' value={form.password} onChange={(e) => handleInput(e)}/>    
                    <StyledContainer sx={{display: 'flex', justifyContent: 'space-between', mb: '20px'}}>
                        <Link link='/forgot-password' type='text'>Forgot Password?</Link>
                        <Link link='/membership-plan' type='text'>Create Account</Link>
                    </StyledContainer>
                    <Button htmlType="submit" onClick={(e) => handleLogin(e)} disabled={disableSave}>Login</Button>
                </form>
            </Card>
            <ResetPasswordModal open={open} handleClose={handleClose} />
        </StyledContainer>
    )
}
